import { Input, Select, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../apis";
import { GlobalContext } from "../../../common-context";
import InfoVideo from "../../../components/header-icons";
import { Wrapper } from "./style";
import CredentialsError from "../../credential-page";

const RequestSupport = ({ menusList, pageTitle }) => {
  const contextValue = useContext(GlobalContext);

  const [locationData, setLocationData] = useState({});
  const [featureType, setFeatureType] = useState([]);
  const [otherData, setOtherData] = useState({
    feature_type_id: null,
    feature: null,
    description: null,
    ["feature_attachments[]"]: [
      {
        name: null, // Initially, name is null until a file is selected
        index: 0, // Index is the next position in the array
        file: null, // Initially, file is null until a file is selected
      },
    ],
  });

  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=10`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };
  const SuggestedFeatureAdd = async () => {
    message.destroy();
    message.loading("Loading...", 0);

    try {
      const files = otherData?.["feature_attachments[]"]?.filter(
        (d) => d?.name
      );
      if (files?.length === 0) {
        message.destroy();
        return message.warning("Please Select File");
      }
      const formdata = new FormData();
      formdata.append("feature_type_id", otherData?.feature_type_id);
      formdata.append("feature", otherData?.feature);
      formdata.append("description", otherData?.description);

      files?.forEach((e) => {
        formdata.append("feature_attachments[]", e?.file, e?.name);
      });
      const response = await MakeApiCall(
        `suggested-feature/add`,
        "post",
        formdata,
        true,
        { "Content-Type": "multipart/form-data" }
      );

      if (response?.status) {
        message.destroy();

        setOtherData({
          feature_type_id: null,
          feature: null,
          description: null,
          ["feature_attachments[]"]: [],
        });
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };
  const getFeatureType = async () => {
    const response = await MakeApiCall(`feature-type`, "get", null, true);

    if (response?.status) {
      setFeatureType(
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          label: value,
          value: key,
        }))
      );
    } else {
      message.warning(response?.message);
    }
  };

  const addItem = () => {
    setOtherData((prevState) => {
      // Check if the number of current attachments is less than 5
      if (prevState["feature_attachments[]"].length < 5) {
        return {
          ...prevState,
          ["feature_attachments[]"]: [
            ...prevState["feature_attachments[]"],
            {
              name: null, // Initially, name is null until a file is selected
              index: prevState["feature_attachments[]"].length, // Index is the next position in the array
              file: null, // Initially, file is null until a file is selected
            },
          ],
        };
      } else {
        message.destroy();
        message.warning("You can only add up to 5 attachments.");
        return prevState; // Return the previous state unchanged
      }
    });
  };

  useEffect(() => {
    getFeatureType();
    getLocationData();
    return () => {};
  }, []);

  const removeItemAtIndex = (index, lengthC) => {
    if (lengthC === 1) {
      addItem();
      return;
    }
    setOtherData((prevState) => ({
      ...prevState,
      ["feature_attachments[]"]: prevState?.[["feature_attachments[]"]]?.filter(
        (_, i) => i !== index
      ),
    }));
  };

  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "request-support")?.is_restricted === 1
  ) {
    return <CredentialsError type="request-support" />;
  }
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid position-relative"
    >
      <div className="card mt-3">
        <div className="card-header">
          <div className="card-title">
            {pageTitle}{" "}
            <InfoVideo
              data={locationData}
              title="Support heading"
              className={"ms-3"}
            />
          </div>
        </div>
        <div className="card-body">
          <div>
            <input
              type="hidden"
              name="c_is_active"
              defaultValue="235374154fc962233f900cad73291288"
            />
            <div className="hide form-group col-md-5 col-sm-12  mb-4 ">
              <label htmlFor="Seller Name">Seller Name </label>

              <Input
                disabled
                value={contextValue?.data?.user_?.seller_name}
                placeholder="Seller Name"
                size="large"
              />
            </div>
            <div className="form-group col-md-5 col-sm-12 mb-4">
              <label htmlFor="Seller Name">User Email</label>

              <Input
                value={contextValue?.data?.user_?.email}
                placeholder="Seller User Email"
                size="large"
                disabled
              />
            </div>
            <div className="form-group col-md-5 col-sm-12   mb-4 ">
              <label htmlFor="Nature of Your Request">
                Nature of Your Request{" "}
              </label>
              <div className=" ">
                <Select
                  onChange={(e) =>
                    setOtherData({
                      ...otherData,
                      feature_type_id: e,
                    })
                  }
                  value={otherData?.feature_type_id}
                  options={featureType}
                  className="w-100"
                  size="large"
                />
              </div>
            </div>
            <div className="form-group col-md-5 col-sm-12  mb-4 ">
              <label htmlFor="Feature">Subject/Desired Feature Name </label>
              <div className=" ">
                <Input
                  placeholder="Subject/Desired Feature Name"
                  size="large"
                  onChange={(e) =>
                    setOtherData({
                      ...otherData,
                      feature: e.target.value,
                    })
                  }
                  value={otherData?.feature}
                />
              </div>
            </div>
            <div className="form-group col-md-5 col-sm-12   mb-4  ">
              <label htmlFor="Description">Description </label>
              <Input.TextArea
                onChange={(e) =>
                  setOtherData({
                    ...otherData,
                    description: e.target.value,
                  })
                }
                value={otherData?.description}
                placeholder="Description"
                size="large"
              />
            </div>
            <input type="hidden" id="counter" defaultValue={1} />
            <div className="form-group col-md-5 col-sm-12">
              <label htmlFor="Feature">Attachments </label>
              <br />

              {otherData?.[`feature_attachments[]`].map((item, index, acc) => (
                <div className="d-flex mb-2" key={index}>
                  <input
                    type="file"
                    id={`file_${index}`}
                    onChange={(e) => {
                      const file = e.target.files[0]; // Get the file from the input

                      if (file) {
                        // Check if the file size is greater than 5MB
                        if (file.size > 5242880) {
                          // 5MB in bytes

                          message.destroy();
                          message.warning("File size should not exceed 5MB.");
                        } else {
                          setOtherData((prevData) => ({
                            ...prevData,
                            "feature_attachments[]": prevData[
                              "feature_attachments[]"
                            ].map((item, idx) => {
                              if (idx === index) {
                                return {
                                  ...item,
                                  name: file.name,
                                  file: file,
                                };
                              }
                              return item;
                            }),
                          }));
                          addItem();
                        }
                      }
                    }}
                    name={`file_${index}`}
                    style={{ paddingBottom: 10, width: "82px" }}
                  />
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "50%",
                      overflow: "hidden",
                      marginLeft: "10px",
                    }}
                    id="attach_multiple"
                  >
                    {item?.name || "Not Selected File"}
                  </div>{" "}
                  {acc?.length !== 0 && (
                    <svg
                      onClick={() => removeItemAtIndex(index, acc?.length)}
                      width={24}
                      height={24}
                      fill="red"
                      className="ms-3"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18.565 6.682a.882.882 0 1 0-1.247-1.247L12 10.753 6.682 5.435a.882.882 0 0 0-1.247 1.247L10.753 12l-5.318 5.318a.882.882 0 0 0 1.247 1.247L12 13.247l5.318 5.318a.882.882 0 0 0 1.247-1.247L13.247 12l5.318-5.318Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </div>
              ))}

              {/* {otherData?.[`feature_attachments[]`]?.length > 0 && (
                <a
                  className="btn btn-search btn-info btn-sm mb-5"
                  style={{ color: "white" }}
                  onClick={addItem}
                >
                  <i className="fas fa-plus-circle" />
                  &nbsp;Add another file
                </a>
              )} */}
            </div>
            <div className="form-group  col-md-5 col-sm-12">
              <input type="hidden" name="user_id" defaultValue={2} />
              <input
                type="submit"
                disabled={
                  !otherData?.description ||
                  !otherData?.feature ||
                  !otherData?.feature_type_id
                }
                onClick={() => {
                  SuggestedFeatureAdd();
                }}
                className="btn btn-search text-white mr-4 my-4"
                defaultValue="Submit"
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default RequestSupport;
