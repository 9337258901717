import styled from "styled-components";

export const Wrapper = styled.div`
  .btn-secondary_ {
    color: #4b5675 !important;
    border-color: #071437 !important;
    background-color: #dbdfe9 !important;
    &:hover {
      background-color: #3c71b8 !important;
      color: #fff !important;
      i {
        color: #fff;
      }
    }
  }
`;
