import Icons from "../../../components/icons";
import { GetLinks } from "../../../config";

// export default [
//   {
//     key: "1",
//     icon: <i className="ki-outline ki-archive fs-1 " />,
//     label: "Data Sources",
//     children: [
//       {
//         key: "/data-sources/sp-campaigns",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sp-campaigns", "SP Campaigns"),
//       },
//       {
//         key: "/data-sources/sp-targeting",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sp-targeting", "SP Targeting"),
//       },
//       {
//         key: "/data-sources/sp-purchases",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sp-purchases", "SP Purchases"),
//       },

//       {
//         key: "/data-sources/sb-purchases",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sb-purchases", "SB Purchases"),
//       },
//       {
//         key: "/data-sources/sb-campaigns",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sb-campaigns", "SB Campaigns"),
//       },

//       {
//         key: "/data-sources/sbv-campaigns",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sbv-campaigns", "SBV Campaigns"),
//       },
//       {
//         key: "/data-sources/sd-campaigns",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sd-campaigns", "SD Campaigns"),
//       },
//       {
//         key: "/data-sources/items",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/items", "Items"),
//       },
//       {
//         key: "/data-sources/asin-performance",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/asin-performance", "ASIN Performance"),
//       },
//       {
//         key: "/data-sources/search-terms",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/search-terms", "Search Terms"),
//       },
//       {
//         key: "/data-sources/branded-terms",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/branded-terms", "Branded Terms"),
//       },
//       {
//         key: "/data-sources/external-traffic",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/external-traffic", "External Traffic"),
//       },
//       {
//         key: "/data-sources/financial",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/financial", "Financial"),
//       },
//       {
//         key: "/data-sources/orders",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/orders", "Orders"),
//       },
//       {
//         key: "/data-sources/sqp",
//         icon: (
//           <span className="menu-bullet">
//             <span className="bullet bullet-dot" />
//           </span>
//         ),
//         label: GetLinks("/data-sources/sqp", "SQP"),
//       },
//     ],
//   },
// ];
export default [
  {
    key: "/data-sources",
    icon: <i className="ki-outline ki-archive fs-1 " />,
    label: GetLinks("/data-sources", "Data Sources"),
  },
];
