import { Skeleton, Tooltip } from "antd";
import { numberformat } from "../../../../../config";
import ReactApexChart from "react-apexcharts";
import { useDrag, useDrop } from "react-dnd";
import React from "react";
import { CFTooltip } from "../../../../../components/tooltip/tooltip";

function CardView(props) {
  const {
    i,
    colorsName,
    borderColor,
    getBorderColor,
    overviewData,
    d,
    setSelectedCard,
    selectedCard,
    backgroundColor,
    overviewLoading,
    signConvert,
    setMetricGraphLoading,
    changeTextColors,
    getOp,
    getFooter,
    getArrowColor,
    footerLeftTextColors,
    key,
    id,
    index,
    moveCard,
    type,
  } = props;

  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: "CARD",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "CARD",
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      key={id}
      style={{
        cursor: "pointer",
        opacity: isDragging ? 0.5 : 1,
      }}
      ref={ref}
      className={`col-md-3 col-lg-4 col-xxl-3 col-xl-4 card-view-argo ${
        colorsName?.[
          borderColor?.[getBorderColor(overviewData?.[d?.root]?.ema_trend)]
        ]
      }`}
      onClick={() => {
        setMetricGraphLoading(true);
        setTimeout(() => {
          setMetricGraphLoading(false);
        }, 1500);
        setSelectedCard(d);
      }}
    >
      <div
        className="card-metric"
        style={{
          boxShadow:
            d?.root === selectedCard?.root
              ? "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px"
              : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          background:
            backgroundColor?.[
              getBorderColor(overviewData?.[d?.root]?.ema_trend)
            ],

          border: `2px ${
            borderColor?.[getBorderColor(overviewData?.[d?.root]?.ema_trend)]
          } solid`,
        }}
      >
        <div className="m-card-body">
          <div
            className="left-view"
            style={{ width: "calc(100vw - 70px)", overflow: "hidden" }}
          >
            <div
              style={{
                maxWidth: "100%",
              }}
              className="m-card-header"
            >
              <div
                className="m-card-title"
                style={{
                  textDecoration:
                    selectedCard?.root === d?.root
                      ? `underline ${
                          borderColor?.[
                            getBorderColor(overviewData?.[d?.root]?.ema_trend)
                          ]
                        } 3px`
                      : "none",
                }}
              >
                {overviewLoading ? (
                  <Skeleton.Button active size="small" />
                ) : (
                  <Tooltip
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    placement="right"
                    title={overviewData?.[d?.root]?.metric_name}
                    rule
                  >
                    {overviewData?.[d?.root]?.metric_name}
                  </Tooltip>
                )}
              </div>
            </div>

            <div className="m-card-value-h" style={{ marginTop: "8px" }}>
              {overviewLoading ? (
                <Skeleton.Button active size="small" />
              ) : (
                signConvert(
                  `${d?.prefix || ""}${numberformat(
                    parseFloat(
                      overviewData?.[d?.root]?.metric_total || 0
                    ).toFixed(2)
                  )}${d?.suffix || ""}`
                )
              )}
            </div>
            <div className="m-card-details" style={{ marginTop: "5px" }}>
              <div className="m-card-details-text">
                {overviewLoading ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <>
                    {signConvert(
                      `${d?.prefix || ""}${numberformat(
                        parseFloat(
                          overviewData?.[d?.root]?.metric_average || 0
                        ).toFixed(2)
                      )}${d?.suffix || ""}`
                    )}
                    &nbsp; (7{type}-Avg)
                  </>
                )}
              </div>
              <div className="m-card-details-text">
                {overviewLoading ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <>52-Week Range</>
                )}
              </div>
              <div className="m-card-details-text">
                {overviewLoading ? (
                  <Skeleton.Input active size="small" />
                ) : (
                  <>
                    {overviewData?.[d?.root]?.min_value == 0
                      ? `${d?.prefix || ""}0${d?.suffix || ""}`
                      : signConvert(
                          `${d?.prefix || ""}${numberformat(
                            parseFloat(
                              overviewData?.[d?.root]?.min_value || 0
                            ).toFixed(2)
                          )}${d?.suffix || ""}`
                        )}{" "}
                    to{" "}
                    {overviewData?.[d?.root]?.max_value == 0
                      ? `${d?.prefix || ""}0${d?.suffix || ""}`
                      : signConvert(
                          `${d?.prefix || ""}${numberformat(
                            parseFloat(
                              overviewData?.[d?.root]?.max_value || 0
                            ).toFixed(2)
                          )}${d?.suffix || ""}`
                        )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="right-view w-100px">
            {overviewLoading ? (
              <Skeleton.Button active size="small" />
            ) : (
              <div
                className="m-card-ema_trend"
                style={{
                  border: `1px ${
                    borderColor?.[
                      getBorderColor(overviewData?.[d?.root]?.ema_trend)
                    ]
                  } solid`,
                }}
              >
                <div
                  style={{
                    color:
                      changeTextColors?.[
                        borderColor?.[
                          getBorderColor(overviewData?.[d?.root]?.ema_trend)
                        ]
                      ],
                    fontSize: 14,
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  {numberformat(
                    parseFloat(overviewData?.[d?.root]?.up_counter) ||
                      parseFloat(overviewData?.[d?.root]?.down_counter) ||
                      parseFloat(overviewData?.[d?.root]?.stagnant_counter)
                  )}
                </div>
              </div>
            )}

            <div className="d-grid" style={{ zoom: "90%" }}>
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  // gap: 3,
                  display: "flex",
                }}
              >
                {overviewLoading ? (
                  <Skeleton.Button className="mt-1" active size="small" />
                ) : (
                  <div
                    className="d-grid align-items-center"
                    style={{ justifyItems: "stretch" }}
                  >
                    <div
                      style={{
                        // borderRight: "1px solid #a1a1a1",
                        // marginRight: "10px",
                        // paddingRight: "10px",
                        textAlign: "right",
                      }}
                      className="m-card-value-h"
                    >
                      {numberformat(
                        overviewData?.[d?.root]?.variance_value || 0
                      )}%
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      <div
                        style={{
                          width: 16,
                          height: 16,
                          position: "relative",
                          top: "2px",
                          transform: `rotate(${
                            parseFloat(
                              numberformat(
                                overviewData?.[d?.root]?.variance_pect || 0
                              )
                            ) < 0
                              ? "180deg"
                              : "0deg"
                          })`,
                        }}
                      >
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Component">
                            <path
                              id="Rectangle"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.70633 11C3.28708 11 3.05399 10.515 3.31589 10.1877L7.60891 4.82138C7.80908 4.57117 8.18962 4.57117 8.38978 4.82138L12.6828 10.1877C12.9447 10.515 12.7116 11 12.2924 11L3.70633 11Z"
                              fill={
                                parseFloat(
                                  numberformat(
                                    overviewData?.[d?.root]?.variance_pect || 0
                                  )
                                ) < 0
                                  ? "#E31715"
                                  : "#00B55D"
                              }
                            />
                          </g>
                        </svg>
                      </div>

                      <div
                        style={{
                          color:
                            parseFloat(
                              numberformat(
                                overviewData?.[d?.root]?.variance_pect || 0
                              )
                            ) < 0
                              ? "#E31715"
                              : "#00B55D",
                          fontSize: 14,
                          fontWeight: "600",
                          wordWrap: "break-word",
                        }}
                      >
                        {numberformat(
                          Math.abs(
                            parseFloat(
                              overviewData?.[d?.root]?.variance_pect || 0
                            ).toFixed(2)
                          )
                        )}
                        %
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {overviewLoading ? (
              <Skeleton.Avatar active className="mt-2" />
            ) : (
              <div
                style={{
                  width: "20%",
                  height: "70px",
                  position: "absolute",
                  right: 5,
                  bottom: 45,
                  // top: "-27px",
                }}
              >
                <ReactApexChart
                  options={{
                    ...getOp(overviewData?.[d?.root]?.ema_trend),
                    markers: {
                      size: 0,
                      colors: "#FFF",

                      hover: {
                        size: 3,
                      },
                    },
                  }}
                  type="area"
                  height={60}
                  series={[
                    {
                      name: d?.label,
                      data: overviewData?.[d?.root]?.metric_chart_data?.map(
                        (d) => d?.metric_value
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            width: "100%",
            padding: "6px 12px 6px 12px",

            background:
              borderColor?.[getFooter(overviewData?.[d?.root]?.movement)],
            borderRadius: "0px 0px 8px 8px",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            transform: "scale(1.015)",
            position: "absolute",
            bottom: "-2px",
          }}
        >
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 4,
              display: "flex",
            }}
          >
            {overviewLoading ? (
              <Skeleton.Button size="small" active />
            ) : (
              <>
                <div
                  style={{
                    width: 20,
                    height: 20,
                    position: "relative",
                    transform: getArrowColor(overviewData?.[d?.root]?.movement),
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M18.3337 5.83334L11.7765 12.3905C11.4465 12.7205 11.2814 12.8856 11.0912 12.9474C10.9238 13.0018 10.7435 13.0018 10.5761 12.9474C10.3859 12.8856 10.2209 12.7205 9.89085 12.3905L7.6098 10.1095C7.27979 9.77947 7.11478 9.61447 6.92451 9.55264C6.75714 9.49826 6.57685 9.49826 6.40948 9.55264C6.2192 9.61447 6.0542 9.77947 5.72418 10.1095L1.66699 14.1667M18.3337 5.83334H12.5003M18.3337 5.83334V11.6667"
                      stroke={
                        footerLeftTextColors?.[
                          borderColor?.[
                            getFooter(overviewData?.[d?.root]?.movement)
                          ]
                        ]
                      }
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    color:
                      footerLeftTextColors?.[
                        borderColor?.[
                          getFooter(overviewData?.[d?.root]?.movement)
                        ]
                      ],
                    fontSize: 14,
                    textTransform: "capitalize",
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  {(overviewData?.[d?.root]?.movement || "-")?.toLowerCase()}
                </div>
              </>
            )}
          </div>

          {overviewLoading ? (
            <Skeleton.Button size="small" active />
          ) : (
            <>
              <div
                style={{
                  height: 24,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 2,
                  paddingBottom: 2,
                  background: "white",
                  borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "flex",
                }}
              >
                <div
                  style={{
                    color:
                      changeTextColors?.[
                        borderColor?.[
                          getFooter(overviewData?.[d?.root]?.movement)
                        ]
                      ],
                    fontSize: 12,
                    fontWeight: "600",
                    wordWrap: "break-word",
                  }}
                >
                  {numberformat(
                    parseFloat(overviewData?.[d?.root]?.falling_counter) ||
                      parseFloat(overviewData?.[d?.root]?.rising_counter) ||
                      parseFloat(overviewData?.[d?.root]?.flat_counter)
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default CardView;
