import { Button, Form, Input, Modal, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Icons from "../../../../../components/icons";

const AddCaralog = ({
  // visible,
  setVisible,
  setList,
  pageType,
  id,
  // selectedRow,
  catalogList,
  // locationList,
  catalogLocationList,

  catalogLoading,
  catalogLocationLoading,
  // locationLoading,
  getCatalogList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [selectedRow, setSelectedRow] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const addAccessMapping = async (data) => {
    const response = await MakeApiCall(
      `content-access-mapping${
        Object.keys(selectedRow)?.length === 0 ? "" : `/${selectedRow?.id}`
      }`,
      Object.keys(selectedRow)?.length === 0 ? "post" : "put",
      data,
      true
    );

    if (response?.status) {
      setList((prev) => {
        let found = false;
        const newList = prev.map((item) => {
          if (item.id === response.data.id) {
            found = true; // Mark as found and update the item
            return response.data;
          }
          return item; // Return the item unmodified if not matched
        });

        if (!found) {
          // If the item wasn't found in the list, add it
          return [...newList, response.data];
        }

        // Return the updated list if the item was found and updated
        return newList;
      });
      setLoading(false);
      form.resetFields();
      setVisible(false); // Close modal after form submission
    } else {
      setLoading(false);
      message.warning(
        Object.values(response?.data || {})?.[0] || response?.message
      );
    }
  };

  const getRecord = async () => {
    const response = await MakeApiCall(
      `content-access-mapping/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedRow(response?.data || {});

      setPageLoading(false);

      getCatalogList(response?.data?.content_location_catalog_id, true);
    } else {
      setPageLoading(false);
      setSelectedRow({});
    }
  };

  // useEffect(() => {
  //   getCatalogList(e)
  // }, [])

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    // setLoading(true);
    return () => {};
  }, []);

  // Function to handle form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        addAccessMapping(values);
      })
      .catch((info) => {});
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    // if (
    //   catalogList?.length === 0 ||
    //   catalogLocationList?.length === 0 ||
    //   Object.keys(selectedRow || {})?.length === 0
    // )
    //   return;
    form.setFieldsValue({
      ...selectedRow,
    });
  }, [catalogList, catalogLocationList]);

  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <span
              className="me-3 cursor-pointer"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icons type="home" />
            </span>
            /{" "}
            {Object.keys(selectedRow)?.length === 0
              ? "Content Access Catalog"
              : "Edit Content Access Catalog"}
          </div>
        </div>
        <div className="px-10">
          <Form form={form} layout="vertical">
            <Form.Item
              name="content_location_catalog_id"
              label="Content Location"
              rules={[
                {
                  required: true,
                  message: "Please select the Content Location!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={catalogLocationList?.map((d) => ({
                  label: ` ${d?.title || ""} - ${d?.menu_item || ""} - ${
                    d?.location || ""
                  }`,
                  value: d?.id,
                }))}
                onChange={(e) => {
                  form.setFieldsValue({
                    content_catalog_id: "",
                  });

                  getCatalogList(e);
                }}
                loading={catalogLocationLoading}
              />
            </Form.Item>
            <Form.Item
              name="content_catalog_id"
              label="Content Catalog"
              rules={[
                {
                  required: true,
                  message: "Please select the Content Catalog!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={catalogList?.map((d) => ({
                  label: (
                    <div>
                      {d?.title}
                      &nbsp;-&nbsp;{d?.type == 1 ? "Info" : "Video"}
                    </div>
                  ),
                  value: d?.id,
                }))}
                loading={catalogLoading}
              />
            </Form.Item>
          </Form>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-end">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className="ms-3"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddCaralog;
