import { Tabs } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import EventTypes from "./lib/event-types";
import UserLogs from "./lib/user-logs";
import { Wrapper } from "./style";

const { TabPane } = Tabs;

const SellerTracking = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const defaultActiveKey = queryParams.get("tabs") || "1";
  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <>
        <div className="card">
          <Tabs
            onChange={(e) => {
              history.push(`/logs/seller-activity?tabs=${e}`);
            }}
            type="line"
            defaultActiveKey={defaultActiveKey}
          >
            <TabPane tab={<span className="px-4">User Logs</span>} key="1">
              <UserLogs />
            </TabPane>
            <TabPane tab={<span className="px-4">Event Types</span>} key="2">
              <EventTypes />
            </TabPane>
          </Tabs>
        </div>
      </>
    </Wrapper>
  );
};

export default SellerTracking;
