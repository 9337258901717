import { ConfigProvider } from "antd";
import { useEffect } from "react";
import AppRoutes from "./app-routes";
import AppStyleWrapper from "./app-style";
import GlobalCommonContextProvider from "./common-context";

function App() {
  useEffect(() => {
    if (process.env?.NODE_ENV === "development") {
      document.designMode = "on";
    } else {
      document.designMode = "off";
    }
    return () => {};
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `'Rubik', sans-serif`,
          colorPrimary: "#065ad8",
          colorPrimaryHover: "#065ad8",
        },

        components: {
          Tabs: {
            inkBarColor: "rgb(0, 158, 251)",
            itemHoverColor: "rgb(0, 158, 251)",
            itemActiveColor: "rgb(0, 158, 251)",
            itemSelectedColor: "rgb(0, 158, 251)",
            colorPrimaryBorder: "rgb(0, 158, 251)",
          },
        },
      }}
    >
      <GlobalCommonContextProvider>
        <AppStyleWrapper />
        <AppRoutes />
      </GlobalCommonContextProvider>
    </ConfigProvider>
  );
}

export default App;
