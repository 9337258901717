import React from "react";
import { Wrapper } from "./style";

const Footer = () => {
  return (
    <Wrapper className="footer py-4 d-flex flex-lg-column" id="kt_footer">
      <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
        {/*begin::Copyright*/}
        <div className="text-dark order-2 order-md-1">
          <span className="fw-semibold me-.0">Copyright ©</span>
          <a
            href
            target="_blank"
            className="text-primary fw-bolder text-hover-primary"
          >
            ArgoMetrix {new Date().getFullYear()}.
          </a>
          <span className="fw-semibold me-1">All rights reserved.</span>
        </div>
        {/*end::Copyright*/}
        {/*begin::Menu*/}
        <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
          <li className="menu-item">
            <a href target="_blank" className="menu-link px-2">
              About
            </a>
          </li>
          <li className="menu-item">
            <a href target="_blank" className="menu-link px-2">
              Support
            </a>
          </li>
        </ul>
        {/*end::Menu*/}
      </div>
    </Wrapper>
  );
};

export default Footer;
