import { Drawer, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import Icons from "../icons";
import { CFTooltip } from "../tooltip/tooltip";
import { Icon } from "@iconify/react";

const PlayView = ({ visible, setVisible, videoData }) => {
  const [currantView, setCurrantView] = useState({});

  const [selectedVideo, setSelectedVideo] = useState({});
  const [playlist, setPlaylist] = useState([]);
  const [playListLoading, setPlayListLoading] = useState(true);
  const onClose = () => {
    setVisible(false);
  };

  const API_KEY = "AIzaSyBm4084k2hu3t8Co1gbEat0lv6Mqde2pkw"; // Place your API Key here

  function getYouTubeVideoId(url, type) {
    const urlParams = new URLSearchParams(new URL(url).search);

    return urlParams.get(type);
  }
  const fetchPlaylistVideos = async () => {
    setPlayListLoading(true);
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/playlistItems`,
        {
          params: {
            part: "snippet",
            playlistId: getYouTubeVideoId(videoData?.showcase_url, "list"),
            maxResults: 1000, // Adjust based on your needs
            key: API_KEY,
          },
        }
      );

      setPlaylist((prev) => {
        return [
          ...prev,
          ...response.data.items?.map((d) => {
            const id = d?.snippet?.resourceId.videoId;
            const url_ = d?.snippet?.thumbnails?.standard?.url;
            const channelTitle = d?.snippet?.channelTitle;
            const description = d?.snippet?.description;
            return {
              id,
              url_,
              channelTitle,
              description,
            };
          }),
        ];
      });
      setPlayListLoading(false);
    } catch (error) {
      setPlayListLoading(false);
      console.error("Failed to fetch playlist items:", error);
    }
  };

  const fetchVideoDetails = async () => {
    const apiKey = API_KEY;
    const videoId = videoData?.video_url?.split("v=")?.[1]?.split("&")?.[0];
    const url = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet,contentDetails,statistics,status`;

    try {
      const response = await axios.get(url);
      const videoData_ = response.data.items?.[0];
      const id = videoData_?.id;
      const url_ = videoData_?.snippet?.thumbnails?.standard?.url;
      const channelTitle =
        videoData?.info_heading || videoData_?.snippet?.channelTitle;
      const description = videoData_?.snippet?.description;

      setPlaylist([
        {
          id,
          url_,
          channelTitle,
          description,
        },
      ]);
      setCurrantView({
        id,
        url_,
        channelTitle,
        description,
      });
      fetchPlaylistVideos();
    } catch (error) {
      console.error("Error fetching video details:", error);
    }
  };

  useEffect(() => {
    fetchVideoDetails();
  }, []);

  useEffect(() => {}, []);
  const CustomCloseIcon = () => (
    <div
      style={{
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        background: "red",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "12px",
      }}
    >
      <Icons type="modal-cross-icon" />
    </div>
  );

  return (
    <Drawer
      title={
        <span className="fw-semibold fs-3">{currantView?.channelTitle}</span>
      }
      open={visible}
      onClose={onClose}
      footer={null}
      width={500}
      closeIcon={<CustomCloseIcon />}
    >
      <div className="row">
        <div className="col-12 mb-5">
          <div
            className="video-responsive"
            style={{
              borderRadius: "20px",
              overflow: "hidden",
              height: "280px",
              border: "1px solid #0b578a1c",
            }}
          >
            {currantView?.id && (
              <iframe
                width="100%"
                height="280px"
                src={`https://www.youtube.com/embed/${currantView?.id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            )}
          </div>
        </div>
        <div
          className="col-12"
          style={{
            border: "1px solid #E3E8EB",
            borderRadius: 8,
            height: "360px",
            overflow: "auto",
            padding: "10px 10px 10px 10px",
            background: "#F9F9F9",
          }}
        >
          {" "}
          <div className="video-list">
            {playlist?.map((d, i) => (
              <div
                key={i}
                onClick={() => {
                  setCurrantView(d);
                }}
                className={`video-item pb-5 px-5 position-relative ${
                  d?.id === currantView?.id ? "selected-videos" : ""
                }`}
              >
                <div className="position-relative">
                  <img
                    src={d?.url_}
                    alt="Thumbnail 1"
                    style={{
                      objectFit: "contain",
                      borderRadius: "7px",
                    }}
                    className="video-thumbnail"
                  />
                  <div
                    style={{
                      position: "absolute",
                      inset: 0,
                      margin: "auto",
                      backgroundImage:
                        "radial-gradient(circle at center center, rgb(255 255 255 / 55%), rgb(255 255 255 / 0%))",
                      borderRadius: 8,
                    }}
                  />
                  <span
                    style={{
                      position: "absolute",
                      inset: 0,
                      margin: "auto",
                      width: "fit-content",
                      height: "fit-content",
                    }}
                  >
                    <Icon
                      icon={`${
                        d?.id !== currantView?.id
                          ? "ep:video-play"
                          : "material-symbols:stop"
                      }`}
                      style={{ color: "#FFF", fontSize: "22px" }}
                    />
                  </span>
                </div>
                <div className="video-info">
                  <h3 className="video-title fw-medium text-dark">
                    <CFTooltip rule row={1} placement={"right"}>
                      {d?.channelTitle}
                    </CFTooltip>
                  </h3>
                  <div className="video-description actionIcon">
                    <CFTooltip rule row={2} placement={"right"}>
                      {d?.description}
                    </CFTooltip>
                  </div>
                </div>
              </div>
            ))}

            {/* Add more video items as needed */}
          </div>
        </div>
      </div>
    </Drawer>
  );
};
export default PlayView;
