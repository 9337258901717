import { Badge } from "antd";
import { GetLinks } from "../../../config";

export default [
  {
    key: "7",
    icon: (
      <i className="ki-duotone ki-setting-2 fs-1">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),

    label: (
      <>
        Settings <Badge className="ms-2" count={5} showZero color="#01c0c8" />
      </>
    ),
    children: [
      {
        key: "/settings/parameters",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-slider-horizontal fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </i>
          </span>
        ),
        label: GetLinks("/settings/parameters", "Parameters"),
      },
      {
        key: "/settings/parameter-groups",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-code fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
            </i>
          </span>
        ),
        label: GetLinks("/settings/parameter-groups", "Parameter Groups"),
      },
      {
        key: "/settings/coupon-codes",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-code fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
            </i>
          </span>
        ),
        label: GetLinks("/settings/coupon-codes", "Coupon Codes"),
      },
      {
        key: "/settings/ad-definitions",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-scroll fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </i>
          </span>
        ),
        label: GetLinks("/settings/ad-definitions", "Ad Definitions"),
      },
      // {
      //   key: "/settings/premium-content",
      //   icon: (
      //     <span className="menu-bullet">
      //       <i className="ki-duotone ki-award fs-1">
      //         <span className="path1" />
      //         <span className="path2" />
      //         <span className="path3" />
      //       </i>
      //     </span>
      //   ),
      //   label: GetLinks("/settings/premium-content", "Premium Content"),
      // },
      {
        key: "/settings/finance-mapping",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-data fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
            </i>
          </span>
        ),
        label: GetLinks("/settings/finance-mapping", "Finance Mapping"),
      },
    ],
  },
];
