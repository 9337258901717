import styled from "styled-components";

export const Wrapper = styled.div`
  .hideMenu {
    // left: -265px;
    width: 100px;
    transition: left 0.3s ease;
  }
  .ant-carousel {
    background: #3c71b859;
    border-radius: 6px;
    border: 1px solid #3c71b8;
  }
  .ant-carousel .slick-dots li button {
    background: #3c71b8;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: #3c71b8;
  }
  .drawer-overlay {
    display: none;
  }
  .custom-ui .ant-table-thead .ant-table-cell {
    background-color: lightyellow !important;
  }
  .expandView {
    transition: padding-left 0.3s ease;
    padding-left: 100px;
  }

  @media only screen and (max-width: 991.98px) {
    .drawer-overlay {
      display: block;
    }
    .aside {
      left: 0;
      transform: translateX(-100%);
    }

    .aside {
      display: flex !important;
      overflow: auto;
      z-index: 110;
      position: fixed;
      top: 0;
      bottom: 0;

      transition: transform 0.3s ease-in-out !important;
    }
    .search-toggle-mobile {
      display: none !important;
    }

    .hideMenu {
      display: flex !important;
      transform: none !important;
      box-shadow: var(--bs-drawer-box-shadow);
      transition: transform 0.3s ease-in-out !important;
    }
  }
  .admin-menu {
    .aside {
      background-color: #2e3f51 !important;
    }
  }
  .user-menu {
    .aside {
      background-color: #3b71b8 !important;
    }
  }
  // .ant-table-wrapper .ant-table-sticky-scroll,
  // .ant-table-wrapper .ant-table-sticky-scroll-bar {
  //   height: 14px !important;
  // }
`;
