import React, { useState, createContext } from "react";

export const GlobalContext = createContext();
const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    window_: window,
    user_: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {},
    credentials: localStorage.getItem("credentials")
      ? JSON.parse(localStorage.getItem("credentials"))
      : {},
    credentialsStatus: { sp: "checking", lws: "checking" },
    dropdownOpen: false,
  });

  return (
    <GlobalContext.Provider
      value={{
        data: commonGlobalVal,
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;
