import { Tabs } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import ContentManagement from "../content-management";
import PartnerResources from "../partner-resources";
import { Wrapper } from "./style";

const { TabPane } = Tabs;

const SellerResources = () => {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const defaultActiveKey = queryParams.get("tabs") || "1";
  const defaultSubActiveKey = queryParams.get("subTab") || "1";

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div className="row g-2">
        <div className="col-xxl-12">
          <Tabs
            onChange={(e) => {
              history.push(
                `/seller-resources?tabs=${e}&subTab=${
                  e === "1" ? "1" : defaultSubActiveKey
                }`
              );
            }}
            type="card"
            defaultActiveKey={defaultActiveKey}
          >
            <TabPane tab="Resources" key="1">
              <PartnerResources />
            </TabPane>
            <TabPane tab="Content Management" key="2">
              <ContentManagement />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </Wrapper>
  );
};

export default SellerResources;
