import React, { useContext, useEffect, useState } from "react";
import { Wrapper } from "./style";
import { MakeApiCall } from "../../../../apis";
import { Breadcrumb, Empty, Popover, Table, Tooltip, message } from "antd";
import Pagination from "../../../../components/pagination";
import moment from "moment";
import ArgoTooltip from "../../../../components/tooltip";
import { TableLoading } from "../../../../components/table-animation";
import "moment-timezone";
import { GlobalContext } from "../../../../common-context";
import CredentialsError from "../../../credential-page";
import InfoVideo from "../../../../components/header-icons";
import { RenderTable } from "../../../../config";

const SystemLog = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const contextValue = useContext(GlobalContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const getList = async () => {
    const response = await MakeApiCall(
      `system-event-logs?page=${page}&user_id=${contextValue?.data?.user_?.id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records);
      setPageSize(response?.data?.pagination?.page_size);
      setTotalPage(response?.data?.pagination?.totalCount);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    return () => {};
  }, [page]);

  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=15`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
  };

  const columns = [
    {
      title: "ID",
      align: "center",
      width: 100,

      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log Created (ET)",
      width: 250,
      align: "left",
      render: (d) => {
        return (
          <span>
            {moment(new Date(d.created_at * 1000))
              .tz("America/New_York")
              .format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      width: 220,
      align: "left",
      render: (d) => {
        return <span>{d.event_name || "-"}</span>;
      },
    },
    {
      title: "Event Type",
      width: 150,
      align: "left",
      render: (d) => {
        return <span>{d.event_type || "-"}</span>;
      },
    },
    {
      title: "Event Message",
      width: 180,
      align: "left",
      ellipsis: false,
      render: (d) => {
        return (
          <ArgoTooltip rule row={3} title={d.error_message}>
            {d.error_message}
          </ArgoTooltip>
        );
      },
    },
    {
      title: "Event Data",
      width: 150,
      align: "left",
      ellipsis: false,
      render: (d) => {
        if (!d?.error_data) {
          return "-";
        }

        return (
          <Popover
            placement="left"
            title=""
            getPopupContainer={() =>
              document.getElementById("kt_content_container").parentNode
            }
            content={
              <div
                style={{
                  width: "400px",
                  wordBreak: "break-word",
                  minHeight: "20px",
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              >
                {d?.error_data}
              </div>
            }
            trigger="hover"
          >
            <div className="actionIcon">{d.error_data}</div>
          </Popover>
        );
      },
    },
  ];

  const { lws, sp } = contextValue?.data?.credentialsStatus;

  if (lws !== 1 || sp !== 1) {
    return <CredentialsError type="system-log" />;
  }

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid pt-5"
    >
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header ">
                <h3 className="card-title ">
                  System Log
                  <InfoVideo
                    data={locationData}
                    title={"System Log"}
                    className={"ms-3"}
                  />
                </h3>
                <div className="card-toolbar"></div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : list?.length === 0 ? (
                  <Empty />
                ) : (
                  <div className="table-responsive">
                    <Table
                      columns={columns?.map((d) => ({
                        ...d,
                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      dataSource={list}
                      rowKey="id"
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      loading={loading}
                      pagination={false}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                    />
                  </div>
                )}

                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
};

export default SystemLog;
