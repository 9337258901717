import dayjs from "dayjs";
import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect } from "react";

const StackedBarLineChart = ({
  updated_data,
  colorList,
  selectedGraph,
  series,
}) => {
  const signFind = {
    AOV: "$",
    "Paid Sales": "$",
    "Organic Sales": "$",
    "External Sales": "$",
    "Sponsored Products": "$",
    "Sponsored Brand": "$",
    "Sponsored Brand Video": "$",
    "Sponsored Display": "$",
    "Sponsored Television": "$",

    "Paid Sales (Units)": "",
    "Organic Sales (Units)": "",
    "External Sales (Units)": "",
    "Sponsored Products (Units)": "",
    "Sponsored Brand (Units)": "",
    "Sponsored Brand Video (Units)": "",
    "Sponsored Display (Units)": "",
    "Sponsored Television (Units)": "",
  };
  const titleNames = {
    aov: {
      name: "AOV",
      stack: "",
      yAxis: 2,
      type: "line",
    },
    sales_paid_sales: {
      name: "Paid Sales",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sales_organic_sales: {
      name: "Organic Sales",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sales_external_sales: {
      name: "External Sales",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sp_sales: {
      name: "Sponsored Products",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sb_sales: {
      name: "Sponsored Brand",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sbv_sales: {
      name: "Sponsored Brand Video",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    sd_sales: {
      name: "Sponsored Display",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },
    st_sales: {
      name: "Sponsored Television",
      stack: "sales",
      yAxis: 0,
      type: "column",
    },

    units_paid_sales: {
      name: "Paid Sales (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },
    units_organic_sales: {
      name: "Organic Sales (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },
    units_external_sales: {
      name: "External Sales (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },
    sp_units: {
      name: "Sponsored Products (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },
    sb_units: {
      name: "Sponsored Brand (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },
    sbv_units: {
      name: "Sponsored Brand Video (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },
    sd_units: {
      name: "Sponsored Display (Units)",
      stack: "units",
      yAxis: 0,
      type: "column",
    },

    st_units: {
      name: "Sponsored Television (Units)",
      stack: "units",
      yAxis: 1,
      type: "column",
    },
  };

  useEffect(() => {
    if (Object?.keys(updated_data?.chartData || {})?.length === 0) return;

    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "430px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
        type: "column",
      },

      title: "",
      plotOptions: {
        column: {
          stacking: "normal",
        },
      },
      xAxis: {
        title: {
          text: "",
        },
        categories: updated_data?.chartDate,
      },
      yAxis: [
        {
          title: {
            text: "Sales",
            style: {
              fontWeight: "bold",

              fontSize: "14px",
            },
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              return this.stack;
            },
            style: {
              fontWeight: "bold",
              color:
                (Highcharts.defaultOptions.title.style &&
                  Highcharts.defaultOptions.title.style.color) ||
                "gray",
            },
          },
          labels: {
            formatter: function () {
              return "$" + this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "Units",
            style: {
              fontWeight: "bold",

              fontSize: "14px",
            },
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "AOV",
            style: {
              fontWeight: "bold",

              fontSize: "14px",
            },
          },
          opposite: true,
          labels: {
            formatter: function () {
              return "$" + this.value?.toLocaleString();
            },
          },
        },
      ],

      series: Object.entries(updated_data?.chartData || {})
        ?.filter(([key, _]) =>
          selectedGraph?.map((d) => d?.toLowerCase())?.includes(key)
        )
        ?.map(([key, value], i) => {
          const type = titleNames?.[key]?.stack === "sales";

          return {
            ...titleNames?.[key],
            data: type ? Object.values(value) : Object.values(value),
            color: series?.filter((d) => d?.name?.toLowerCase() === key)?.[0]
              ?.color,
          };
        }),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            '<span style="text-transform: capitalize">' +
            this.series.name +
            "</span>" +
            ": <b>" +
            signFind?.[this.series.name] +
            Math.round(this.y || 0)?.toLocaleString() +
            "</b><br/>"
          );
        },
      },
      legend: {
        enabled: false, // Hide legends
      },
    });
  }, [updated_data, selectedGraph]);

  return (
    <div
      id="line-chart"
      style={{ width: "100%", height: "420px" }}
      // ref={chartRef}
    ></div>
  );
};

export default StackedBarLineChart;
