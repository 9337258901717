import React, { useContext, useEffect, useState } from "react";
import { Wrapper } from "./style";
import ReactApexChart from "react-apexcharts";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Tabs } from "antd";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import Config from "./lib/config";
import { GlobalContext } from "../../../common-context";
import CredentialsError from "../../credential-page";
import InfoVideo from "../../../components/header-icons";

const Dashboard = (props) => {
  const { pageTitle, menusList } = props;

  const contextValue = useContext(GlobalContext);
  const { lws, sp } = contextValue?.data?.credentialsStatus;

  const a = "100";
  const l = "#F1F1F2";
  const green = "#50cd89";
  const yellow = "#ffc803";
  const red = "#f1416c";
  const optionsgreen = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2, colors: [green] },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { color: green, width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },
    colors: [green],
    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: { strokeColor: green, strokeWidth: 2 },
  };
  const optionsyellow = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2, colors: [yellow] },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { color: yellow, width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },
    colors: [yellow],
    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: { strokeColor: yellow, strokeWidth: 2 },
  };
  const optionsred = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2, colors: [red] },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { color: red, width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },
    colors: [red],
    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: { strokeColor: red, strokeWidth: 2 },
  };

  const [viewConfig, setViewConfig] = useState(false);

  useEffect(() => {
    if (lws === 0 || sp === 0) return;
    if (
      document.getElementById("chartdiv") &&
      !document.getElementById("chartdiv")?.innerHTML
    ) {
      am5.ready(function () {
        var root = am5.Root.new("chartdiv");

        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            layout: root.verticalLayout,
          })
        );

        var yRenderer = am5xy.AxisRendererY.new(root, {
          minGridDistance: 20,
          inversed: true,
        });

        yRenderer.labels.template.set("visible", false);

        yRenderer.grid.template.set("visible", false);

        var yAxis = chart.yAxes.push(
          am5xy.CategoryAxis.new(root, {
            renderer: yRenderer,
            categoryField: "category",
          })
        );

        var xRenderer = am5xy.AxisRendererX.new(root, {
          visible: false,
          minGridDistance: 30,
          inversed: true,
        });

        xRenderer.labels.template.set("visible", false);

        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: "category",
          })
        );

        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            // name: 'name',
            calculateAggregates: true,
            stroke: am5.color(0xffffff),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: "x",
            categoryYField: "y",
            valueField: "value",
          })
        );

        series.columns.template.setAll({
          tooltipText: "{value}",
          strokeOpacity: 1,
          strokeWidth: 3,
          cornerRadiusTL: 6,
          cornerRadiusTR: 6,
          cornerRadiusBL: 6,
          cornerRadiusBR: 6,
          inside: true,
          width: am5.percent(100),
          height: am5.percent(100),
          templateField: "columnSettings",
        });

        var circleTemplate = am5.Template.new({});

        series.set("heatRules", [
          {
            target: circleTemplate,
            min: 0,
            max: 200,
            dataField: "value",
            key: "radius",
          },
        ]);

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Circle.new(
              root,
              {
                // fill: am5.color(0x000000),
                fillOpacity: 0.5,
                strokeOpacity: 0,
              },
              circleTemplate
            ),
          });
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              //   fill: am5.color(0xffffff),
              populateText: true,
              centerX: am5.p50,
              centerY: am5.p50,
              FontFace: "Inter",
              fontSize: 15,
              text: "{name}",
            }),
          });
        });

        var colors = {
          darkred: am5.color("#9c3939"),
          avgred: am5.color("#d4a9a9"),
          lightred: am5.color("#e8d1d1"),
          lightenred: am5.color("#ffc7ce"),

          avgwarning: am5.color("#ffeb9c"),
          avgmidwarning: am5.color("#faf3c2"),

          avggreen: am5.color("#79bc79"),
          avgmidgreen: am5.color("#97cb97"),
          avglightgreen: am5.color("#cde6cd"),
          avgdarkgreen: am5.color("#68903b"),
        };

        var data = [
          {
            y: "1",
            x: "6",
            value: 150,
            name: "CR",
            columnSettings: {
              fill: colors.darkred,
            },
          },
          {
            y: "2",
            x: "6",
            value: 90,
            name: "USP",
            columnSettings: {
              fill: colors.avgred,
            },
          },
          {
            y: "3",
            x: "6",

            value: 70,
            name: "Keyword",
            columnSettings: {
              fill: colors.lightred,
            },
          },

          {
            y: "1",
            x: "5",

            value: 100,
            name: "CTR",
            columnSettings: {
              fill: colors.avgred,
            },
          },

          {
            y: "2",
            x: "5",
            value: 12,
            name: "Impression Share",
            columnSettings: {
              fill: colors.lightred,
            },
          },
          {
            y: "3",
            x: "5",
            value: 30,
            name: "Keyword Rank",
            columnSettings: {
              fill: colors.lightenred,
            },
          },
          {
            y: "1",
            x: "4",
            value: 24,
            name: "Click Share",
            columnSettings: {
              fill: colors.avgwarning,
            },
          },
          {
            y: "2",
            x: "4",

            value: 25,
            name: "Cart Add Rate",
            columnSettings: {
              fill: colors.avgmidwarning,
            },
          },
          {
            y: "3",
            x: "4",

            value: 15,
            name: "Purchase Rate",
            columnSettings: {
              fill: colors.avgwarning,
            },
          },
          {
            y: "1",
            x: "3",
            value: 25,
            name: "TACOS",
            columnSettings: {
              fill: colors.avgwarning,
            },
          },
          {
            y: "2",
            x: "3",

            value: 33,
            name: "ACOS",
            columnSettings: {
              fill: colors.avgmidwarning,
            },
          },
          {
            y: "3",
            x: "3",

            value: 14,
            name: "ROAS",
            columnSettings: {
              fill: colors.avgmidwarning,
            },
          },
          {
            y: "1",
            x: "2",

            value: 20,
            name: "Ad CTR",
            columnSettings: {
              fill: colors.avglightgreen,
            },
          },
          {
            y: "2",
            x: "2",

            value: 19,
            name: "Unit Sold",
            columnSettings: {
              fill: colors.avgmidgreen,
            },
          },
          {
            y: "3",
            x: "2",

            value: 25,
            name: "Sales",
            columnSettings: {
              fill: colors.avgdarkgreen,
            },
          },
          {
            y: "1",
            x: "1",

            value: 31,
            name: "AOV",
            columnSettings: {
              fill: colors.avggreen,
            },
          },
          {
            y: "2",
            x: "1",

            value: 24,
            name: "AIV",
            columnSettings: {
              fill: colors.avgmidgreen,
            },
          },
          {
            y: "3",
            x: "1",

            value: 25,
            name: "BB Rate",
            columnSettings: {
              fill: colors.avgmidgreen,
            },
          },
        ];

        series.data.setAll(data);

        yAxis.data.setAll([
          { category: "1" },
          { category: "2" },
          { category: "3" },
        ]);

        xAxis.data.setAll([
          { category: "1" },
          { category: "2" },
          { category: "3" },
          { category: "4" },
          { category: "5" },
          { category: "6" },
        ]);

        chart.appear(1000, 100);
        root._logo.dispose();
      });
    }
    return () => {};
  }, []);

  const items = [
    {
      key: "1",
      label: "Critical",
    },
    {
      key: "2",
      label: "Severe",
    },
    {
      key: "3",
      label: "Sensitive",
    },
    {
      key: "4",
      label: "Accelerator",
    },
  ];

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "dashboard")?.is_restricted === 1
  ) {
    return <CredentialsError type="dashboard" />;
  }
  return (
    <Wrapper>
      <div id="kt_content_container" className="container-fluid mt-2">
        <a
          onClick={() => setViewConfig(true)}
          className="btn fs-7 py-3 btn-primary fw-bold"
          style={{
            position: "fixed",
            right: "-46px",
            transform: "rotate(90deg)",
            top: "20%",
            zIndex: 9,
          }}
          id="kt_drawer_example_permanent_toggle"
        >
          Show Metrics
        </a>
        {/*begin::Row*/}
        <div className="row g-5 g-xl-5 mb-5">
          <div className="col-md-6 ">
            <div className="d-flex">
              <h1 className="d-flex flex-column text-dark fw-bold fs-3 mb-0">
                Daily Overview
              </h1>
              <InfoVideo className={"ms-3"} />
            </div>
            <div className="text-gray-400 mt-1">
              Previous Days Compnay wide Metrics &amp; Trends
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="d-md-flex justify-content-end align-items-center">
              <div className=" me-3">
                <div className="d-flex flex-wrap">
                  {/*begin::Item*/}
                  <div className="d-flex flex-row me-7 ">
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center mb-0 mb-sm-0 me-6">
                      {/*begin::Bullet*/}
                      <span
                        className="bullet bullet-dot me-2 h-10px w-10px"
                        style={{ backgroundColor: "#a7ebbe" }}
                      />
                      {/*end::Bullet*/}
                      {/*begin::Label*/}
                      <span className="fw-bold text-gray-800 fs-6">Growth</span>
                      {/*end::Label*/}
                    </div>
                    {/*ed::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center me-6">
                      {/*begin::Bullet*/}
                      <span
                        className="bullet bullet-dot me-2 h-10px w-10px"
                        style={{ backgroundColor: "#ffcb18" }}
                      />
                      {/*end::Bullet*/}
                      {/*begin::Label*/}
                      <span className="fw-bold text-gray-800 fs-6">
                        Stagnant
                      </span>
                      {/*end::Label*/}
                    </div>
                    {/*end::Item*/}
                    {/*begin::Item*/}
                    <div className="d-flex align-items-center">
                      {/*begin::Bullet*/}
                      <span
                        className="bullet bullet-dot me-2 h-10px w-10px"
                        style={{ backgroundColor: "#ffbebe" }}
                      />
                      {/*end::Bullet*/}
                      {/*begin::Label*/}
                      <span className="fw-bold text-gray-800 fs-6">
                        Decline
                      </span>
                      {/*end::Label*/}
                    </div>
                    {/*end::Item*/}
                  </div>
                  {/*ed::Item*/}
                </div>
              </div>
              <div className="min-w-150px mb-4 mb-md-0">
                {/*begin::Daterangepicker(defined in src/js/layout/app.js)*/}
                <div
                  data-kt-daterangepicker="true"
                  data-kt-daterangepicker-opens="left"
                  className="btn btn-sm bg-white d-flex align-items-center px-4"
                  data-kt-initialized={1}
                >
                  {/*begin::Display range*/}
                  <div className="text-gray-600 fw-bold">
                    25 Oct 2023 - 23 Nov 2023
                  </div>
                  {/*end::Display range*/}
                  <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                  </i>
                </div>
                {/*end::Daterangepicker*/}
              </div>
            </div>
          </div>
        </div>
        {/*end::Row*/}
        <div className="row g-5 g-xl-5 dashboard-cus-row">
          {/*begin::Col*/}
          <div className="col-xxl-8 mb-5 mb-xl-5 ">
            <div className="row g-5 g-xl-5 crt">
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                {/*begin::Card widget 8*/}
                <div
                  className="card overflow-hidden  mb-5 mb-xl-5"
                  style={{ background: "#c9f2d7" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          Traffic
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            220
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-success py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">92% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-up text-green fs-3" />{" "}
                          <b className="text-green">14</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_8_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsgreen}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#ffbebe" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-down text-danger fs-3" />
                      </div>
                      <div className="text-danger fw-bolder">Falling</div>
                      <div className="fw-bolder">2</div>
                    </div>
                  </div>
                </div>
                {/*end::Card widget 8*/}
              </div>
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                <div
                  className="card overflow-hidden  mb-5 mb-xl-5"
                  style={{ background: "#c9f2d7" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          Unit Sold
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            75
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-success py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">92% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-up text-green fs-3" />{" "}
                          <b className="text-green">14</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_9_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsgreen}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#a0e6b8" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-up text-green fs-3" />
                      </div>
                      <div className="text-green fw-bolder">Rising</div>
                      <div className="fw-bolder">2</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                <div
                  className="card overflow-hidden mb-5 mb-xl-5"
                  style={{ background: "#fbebbe" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          Sales
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            249.83
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-warning py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">92% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-up text-warning fs-3" />{" "}
                          <b className>1.6</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_10a_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsyellow}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#ffbebe" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-down text-danger fs-3" />
                      </div>
                      <div className="text-danger fw-bolder">Falling</div>
                      <div className="fw-bolder">3</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                <div
                  className="card overflow-hidden mb-5 mb-xl-5"
                  style={{ background: "#fbebbe" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          TACOS
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            9.80%
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-warning py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">18% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-up text-warning  fs-3" />{" "}
                          <b className>14</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_11a_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsyellow}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#a0e6b8" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-up text-green fs-3" />
                      </div>
                      <div className="text-green fw-bolder">Rising</div>
                      <div className="fw-bolder">2</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-xl-5">
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                {/*begin::Card widget 8*/}
                <div
                  className="card overflow-hidden  mb-5 mb-xl-5"
                  style={{ background: "#c9f2d7" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          BB Rate
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            95.00%
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-success py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">92% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-up text-green fs-3" />{" "}
                          <b className="text-green">55</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_12a_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsgreen}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#ffbebe" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-down text-danger fs-3" />
                      </div>
                      <div className="text-danger fw-bolder">Falling</div>
                      <div className="fw-bolder">2</div>
                    </div>
                  </div>
                </div>
                {/*end::Card widget 8*/}
              </div>
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                <div
                  className="card overflow-hidden  mb-5 mb-xl-5"
                  style={{ background: "#ffdfdf" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          CR
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            12.45%
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-danger py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">92% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-down text-danger fs-3" />{" "}
                          <b className="text-danger">14.71</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_13a_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsred}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#ffbebe" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-down text-danger fs-3" />
                      </div>
                      <div className="text-danger fw-bolder">Falling</div>
                      <div className="fw-bolder">2</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                <div
                  className="card overflow-hidden mb-5 mb-xl-5"
                  style={{ background: "#ffdfdf" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          USP
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            14.90%
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-danger py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">28% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-down text-danger fs-3" />{" "}
                          <b className="text-danger">8.61</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 12 - 18
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_14a_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsred}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#ffbebe" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-down text-danger fs-3" />
                      </div>
                      <div className="text-danger fw-bolder">Falling</div>
                      <div className="fw-bolder">2</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-3 mb-xxl-1">
                <div
                  className="card overflow-hidden mb-5 mb-xl-5"
                  style={{ background: "#ffdfdf" }}
                >
                  {/*begin::Card body*/}
                  <div className="card-body d-flex justify-content-between flex-column px-0 pb-0 pt-4">
                    <div className=" px-5 justify-content-between">
                      {/*begin::Statistics*/}
                      <div className="mb-0 d-flex align-items-center justify-content-between w-100">
                        <span className="fs-6 fw-bold text-gray-800 d-flex align-items-center">
                          ACOS
                        </span>
                        <div className="d-flex align-items-center mb-0 ">
                          {/* <span class="fs-4 fw-semibold text-gray-400 align-self-start me-1 lh-1">$</span> */}
                          <span className="fs-2 fw-bolder text-gray-800 me-2 lh-1">
                            12.90%{" "}
                          </span>
                        </div>
                        {/*end::Description*/}
                      </div>
                      <span className="badge badge-light-danger py-1 fs-base px-4 mt-3">
                        <div className="fs-7 fw-bolder">18% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-down text-danger fs-3" />{" "}
                          <b className="text-danger">4.61</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-3 text-gray-700">
                      52W - Range (3) 12 - 18
                    </div>
                    {/*begin::Chart*/}
                    <div
                      id="kt_card_widget_15a_chart"
                      className="min-h-50px mt-n7"
                      style={{ height: 100, minHeight: 115 }}
                    >
                      <ReactApexChart
                        options={optionsred}
                        type="area"
                        height={100}
                        series={[
                          {
                            name: "Sales",
                            data: [
                              1.5, 2.5, 2, 3, 2, 4, 2.5, 2, 2.5, 4, 2.5, 4.5,
                              2.5,
                            ],
                          },
                        ]}
                      />
                    </div>
                    {/*end::Chart*/}
                  </div>
                  {/*end::Card body*/}
                  <div
                    className="card-footer border-top-0 py-1"
                    style={{ background: "#a0e6b8" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex">
                        <i className="ki-outline ki-arrow-up text-green fs-3" />
                      </div>
                      <div className="text-green fw-bolder">Rising</div>
                      <div className="fw-bolder">1</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-xxl-4 mb-md-5 mb-xl-11">
            {/*begin::Maps widget 1*/}
            <div className="card card-flush h-md-100">
              {/*begin::Header*/}
              <div className="card-header py-0 pt-0 min-h-50px  align-items-center">
                {/*begin::Title*/}
                <h3 className="card-title fw-bolder">
                  Metric: CR <InfoVideo className={"ms-3"} />
                </h3>
                {/*end::Title*/}
                {/*begin::Toolbar*/}
                <div className="card-toolbar"></div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body px-0 ps-4  pb-4 pt-0">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="fw-bolder fs-5 text-danger px-5 d-flex ">
                    <span className="text-dark">12.45%</span>{" "}
                    <span className="d-flex align-items-center">
                      <i className="ki-outline ki-arrow-down fs-3 text-danger ms-2" />{" "}
                      14.71
                    </span>
                  </div>
                  <div className="pe-8 text-end">
                    <div>
                      <span className="badge badge-light-warning py-1 fs-base px-4 mt-0">
                        <div className="fs-7 fw-bolder">92% (7W - Avg)</div>
                        <div className="fs-7 d-flex align-items-center justify-content-end ">
                          <i className="ki-outline ki-arrow-up text-warning fs-3" />{" "}
                          <b className>14</b>
                        </div>
                      </span>
                    </div>
                    <div className="ps-4 fs-8 fw-bolder mt-2">
                      52W - Range (3) 94.2 - 97.0
                    </div>
                  </div>
                </div>
                <div
                  id="Revenu_Status"
                  className="w-100 h-250px"
                  style={{ minHeight: 265 }}
                >
                  <ReactApexChart
                    options={{
                      chart: {
                        height: 250,
                        type: "area",
                        toolbar: { show: !1 },
                      },
                      colors: ["#065ad8", "#000"],
                      dataLabels: { enabled: !1 },
                      stroke: {
                        show: !0,
                        curve: "straight",
                        width: [2, 2],
                        dashArray: [0, 4],
                        lineCap: "round",
                      },

                      labels: [
                        "10:00",
                        "11:00",
                        "12:00",
                        "13:00",
                        "14:00",
                        "15:00",
                        "16:00",
                        "17:00",
                        "18:00",
                        "19:00",
                        "20:00",
                      ],
                      yaxis: {
                        show: !1,
                        labels: {
                          formatter: function (_) {
                            return _ + "";
                          },
                          offsetX: 0,
                          offsetY: 0,
                        },
                      },
                      grid: {
                        strokeDashArray: 3,
                        xaxis: { lines: { show: !0 } },
                        yaxis: { lines: { show: !1 } },
                      },
                      legend: { show: !0 },
                      fill: {
                        type: "gradient",
                        gradient: {
                          type: "vertical",
                          shadeIntensity: 1,
                          inverseColors: !1,
                          opacityFrom: 0.5,
                          opacityTo: 0.4,
                          stops: [45, 100],
                        },
                      },
                    }}
                    type="area"
                    height={265}
                    series={[
                      {
                        name: "",
                        data: [
                          150, 160, 210, 160, 110, 140, 115, 150, 200, 150, 150,
                        ],
                      },
                    ]}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <div className="fw-bolder fs-5 text-danger">
                    <span className="text-dark">Mode:</span> Declining
                  </div>
                  <div className="fw-bolder fs-5 text-danger ms-10">
                    <span className="text-dark">Mode:</span> Falling
                  </div>
                </div>
              </div>
            </div>
            {/*end::Maps widget 1*/}
          </div>
          {/*end::Col*/}
        </div>
        <div className="row mb-4 g-5 g-xl-5">
          <div className="col-md-12">
            <h3 className="card-title fw-bold">Top jobs to be Done (JTBD)</h3>
            <InfoVideo className={"ms-3"} />
          </div>
        </div>
        <div className="row g-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xl-8">
            {/*begin::Table widget 8*/}
            <div className="card h-xl-100">
              {/*begin::Header*/}
              <div className="card-header position-relative py-0 border-bottom-2">
                {/*begin::Nav*/}
                <Tabs
                  defaultActiveKey="1"
                  size="large"
                  className="tabs-antd"
                  items={items}
                />
                {/*end::Nav*/}
                {/*begin::Toolbar*/}
                <div className="card-toolbar">
                  <div className="position-relative mr-4">
                    <i className="ki-outline ki-magnifier text-gray-800 fs-2 position-absolute top-50 translate-middle-y ms-4" />
                    <input
                      type="text"
                      data-kt-table-widget-4="search"
                      className="form-control  text-dark form-control-solid  w-200px fs-7 ps-12 all-search"
                      name="all_search"
                      placeholder="Search here..."
                    />
                  </div>
                  <button className="btn btn-light-primary btn-icon ms-3">
                    <i className="ki-outline ki-filter fs-2" />
                  </button>
                </div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body">
                {/*begin::Tab Content (ishlamayabdi)*/}
                <div className="tab-content mb-2">
                  {/*begin::Tap pane*/}
                  <div
                    className="tab-pane fade show active"
                    id="kt_table_widget_7_tab_content_1"
                    role="tabpanel"
                  >
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed table-row-gray-300 gy-4 gx-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-dark bg-light-primary">
                            <th className="min-w-65px ps-3">Item</th>
                            <th className="min-w-200px">Path</th>
                            <th className="min-w-125px">Current</th>
                            <th className="min-w-100px">Target %</th>
                            <th className="min-w-125px">Monthly Impact</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-700 fw-bold fs-7">
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Conversion
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>
                  </div>
                  {/*end::Tap pane*/}
                  {/*begin::Tap pane*/}
                  <div
                    className="tab-pane fade "
                    id="kt_table_widget_7_tab_content_2"
                    role="tabpanel"
                  >
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed table-row-gray-300 gy-4 gx-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-dark bg-light-primary">
                            <th className="min-w-65px ps-3">Item</th>
                            <th className="min-w-200px">Path</th>
                            <th className="min-w-125px">Current</th>
                            <th className="min-w-100px">Target %</th>
                            <th className="min-w-125px">Monthly Impact</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-700 fw-bold fs-7">
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Conversion
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>
                  </div>
                  {/*end::Tap pane*/}
                  {/*begin::Tap pane*/}
                  <div
                    className="tab-pane fade"
                    id="kt_table_widget_7_tab_content_3"
                    role="tabpanel"
                  >
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed table-row-gray-300 gy-4 gx-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-dark bg-light-primary">
                            <th className="min-w-65px ps-3">Item</th>
                            <th className="min-w-200px">Path</th>
                            <th className="min-w-125px">Current</th>
                            <th className="min-w-100px">Target %</th>
                            <th className="min-w-125px">Monthly Impact</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-700 fw-bold fs-7">
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Conversion
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>
                  </div>
                  {/*end::Tap pane*/}
                  {/*begin::Tap pane*/}
                  <div
                    className="tab-pane fade "
                    id="kt_table_widget_7_tab_content_4"
                    role="tabpanel"
                  >
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed table-row-gray-300 gy-4 gx-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-dark bg-light-primary">
                            <th className="min-w-65px ps-3">Item</th>
                            <th className="min-w-200px">Path</th>
                            <th className="min-w-125px">Current</th>
                            <th className="min-w-100px">Target %</th>
                            <th className="min-w-125px">Monthly Impact</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-700 fw-bold fs-7">
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Conversion
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                          <tr className>
                            <td>
                              {/*begin:: Avatar */}
                              <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                                <div className="symbol symbol-50px overflow-hidden me-3">
                                  <img src="/assets/media/17.png" alt />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::User details*/}
                                <div className="d-flex flex-column">
                                  <a
                                    href
                                    className="text-primary mb-1 fw-bolder text-one min-w-175px fs-7"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry.
                                  </a>
                                  <div>
                                    <span className="fs-8">
                                      <b>SKU:</b> BSDJW5466D
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                              {/*begin::User details*/}
                            </td>
                            {/* <td>ASJBSUI654S</td> */}
                            <td>
                              <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                                Falling Traffic
                              </button>
                            </td>
                            <td>Change Image</td>
                            <td>+3 %</td>
                            <td>$12,500</td>
                          </tr>
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>
                  </div>
                  {/*end::Tap pane*/}
                </div>
                {/*end::Tab Content*/}
                {/*begin::Action*/}
                <div className="text-center">
                  {/* <a href="#" class="btn btn-sm btn-light me-2"  data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">Add Lesson</a> */}
                  <a
                    href="#"
                    className="btn btn-sm btn-primary fw-bold"
                    data-bs-toggle="modal"
                    data-bs-target
                  >
                    View ALl JTBD
                  </a>
                </div>
                {/*end::Action*/}
              </div>
              {/*end: Card Body*/}
            </div>
            {/*end::Table widget 8*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-xl-4">
            <div className="card card-flush h-md-100">
              {/*begin::Body*/}
              <div className="card-body px-4  pb-4 pt-5">
                <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                  <div className="symbol symbol-50px overflow-hidden me-3">
                    <img src="/assets/media/17.png" alt />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::User details*/}
                  <div className="d-flex flex-column">
                    <a
                      href
                      className="text-primary mb-1 fw-bolder text-one min-w-175px fs-6"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </a>
                    <div>
                      <span className="fs-8">
                        <b>SKU:</b> BSDJW5466D
                      </span>{" "}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-4 align-items-center pe-10 ps-2">
                  <div>
                    <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                      Falling Conversion
                    </button>
                  </div>
                  <div className="text-end">
                    <div className="fw-bolder">Suggested Action</div>
                    <div>Change Image</div>
                  </div>
                </div>
                <div
                  id="Revenu_Status1"
                  className="w-100 h-250px"
                  style={{ minHeight: 265 }}
                >
                  <ReactApexChart
                    options={{
                      chart: {
                        height: 250,
                        type: "area",
                        toolbar: { show: !1 },
                      },
                      colors: ["#065ad8", "#000"],
                      dataLabels: { enabled: !1 },
                      stroke: {
                        show: !0,
                        curve: "straight",
                        width: [2, 2],
                        dashArray: [0, 4],
                        lineCap: "round",
                      },

                      labels: [
                        "10:00",
                        "11:00",
                        "12:00",
                        "13:00",
                        "14:00",
                        "15:00",
                        "16:00",
                        "17:00",
                        "18:00",
                        "19:00",
                        "20:00",
                      ],
                      yaxis: {
                        show: !1,
                        labels: {
                          formatter: function (_) {
                            return _ + "";
                          },
                          offsetX: 0,
                          offsetY: 0,
                        },
                      },
                      grid: {
                        strokeDashArray: 3,
                        xaxis: { lines: { show: !0 } },
                        yaxis: { lines: { show: !1 } },
                      },
                      legend: { show: !0 },
                      fill: {
                        type: "gradient",
                        gradient: {
                          type: "vertical",
                          shadeIntensity: 1,
                          inverseColors: !1,
                          opacityFrom: 0.5,
                          opacityTo: 0.4,
                          stops: [45, 100],
                        },
                      },
                    }}
                    type="area"
                    height={265}
                    series={[
                      {
                        name: "",
                        data: [
                          150, 160, 210, 160, 110, 140, 115, 150, 200, 150, 150,
                        ],
                      },
                    ]}
                  />
                </div>
                <div className="d-flex justify-content-center mt-8">
                  <button className="btn py-2 btn-dark fs-7 fw-bold">
                    View Event
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*end::Col*/}
        </div>
        <div className="row mb-4 mt-4 g-5 g-xl-5">
          <div className="col-md-12">
            <h3 className="card-title fw-bold d-flex">
              Pathfinder Total View <InfoVideo className={"ms-3"} />
            </h3>
            <span className="text-gray-400 mt-1">
              Previous Days Metrics &amp; Trends
            </span>
          </div>
        </div>
        <div className="row g-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xl-8">
            {/*begin::Table widget 8*/}
            <div className="card h-xl-100">
              {/*begin::Header*/}
              <div className="card-header   position-relative py-0 border-gray-300 align-items-center">
                <div>
                  <select
                    name
                    className="form-select form-select-solid fs-7"
                    id
                  >
                    <option>Company Wide</option>
                  </select>
                </div>
                {/*begin::Toolbar*/}
                <div className="card-toolbar"></div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pb-0 ps-0 px-2">
                <div id="chartdiv" className="h-100"></div>
              </div>
              {/*end: Card Body*/}
            </div>
            {/*end::Table widget 8*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className="col-xl-4">
            <div className="card card-flush h-md-100">
              <div className="card-header py-0 pt-0 min-h-50px  align-items-center">
                {/*begin::Title*/}
                <h3 className="card-title fw-bolder">Metric: CR</h3>
                {/*end::Title*/}
                {/*begin::Toolbar*/}
                <div className="card-toolbar">
                  <div className="fw-bolder fs-5 text-danger px-5 d-flex ">
                    <span className="text-dark">12.45%</span>{" "}
                    <span className="d-flex align-items-center">
                      <i className="ki-outline ki-arrow-down fs-3 text-danger ms-2" />{" "}
                      14.71
                    </span>
                  </div>
                </div>
                {/*end::Toolbar*/}
              </div>
              {/*begin::Body*/}
              <div className="card-body px-4  pb-4 pt-5">
                <div className="d-flex align-items-center bg-light border-start border-2 border-primary p-3 py-2">
                  <div className="symbol symbol-50px overflow-hidden me-3">
                    <img src="/assets/media/17.png" alt />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::User details*/}
                  <div className="d-flex flex-column">
                    <a
                      href
                      className="text-primary mb-1 fw-bolder text-one min-w-175px fs-6"
                    >
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </a>
                    <div>
                      <span className="fs-8">
                        <b>SKU:</b> BSDJW5466D
                      </span>{" "}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between my-4 align-items-center pe-10 ps-2">
                  <div>
                    <button className="btn py-2 btn-light-danger fs-7 fw-bold">
                      Falling Conversion
                    </button>
                  </div>
                  <div className="text-end">
                    <div className="fw-bolder">Suggested Action</div>
                    <div>Change Image</div>
                  </div>
                </div>
                <div
                  id="Revenu_Status2"
                  className="w-100 h-250px"
                  style={{ minHeight: 265 }}
                >
                  <ReactApexChart
                    options={{
                      chart: {
                        height: 250,
                        type: "area",
                        toolbar: { show: !1 },
                      },
                      colors: ["#065ad8", "#000"],
                      dataLabels: { enabled: !1 },
                      stroke: {
                        show: !0,
                        curve: "straight",
                        width: [2, 2],
                        dashArray: [0, 4],
                        lineCap: "round",
                      },

                      labels: [
                        "10:00",
                        "11:00",
                        "12:00",
                        "13:00",
                        "14:00",
                        "15:00",
                        "16:00",
                        "17:00",
                        "18:00",
                        "19:00",
                        "20:00",
                      ],
                      yaxis: {
                        show: !1,
                        labels: {
                          formatter: function (_) {
                            return _ + "";
                          },
                          offsetX: 0,
                          offsetY: 0,
                        },
                      },
                      grid: {
                        strokeDashArray: 3,
                        xaxis: { lines: { show: !0 } },
                        yaxis: { lines: { show: !1 } },
                      },
                      legend: { show: !0 },
                      fill: {
                        type: "gradient",
                        gradient: {
                          type: "vertical",
                          shadeIntensity: 1,
                          inverseColors: !1,
                          opacityFrom: 0.5,
                          opacityTo: 0.4,
                          stops: [45, 100],
                        },
                      },
                    }}
                    type="area"
                    height={265}
                    series={[
                      {
                        name: "",
                        data: [
                          150, 160, 210, 160, 110, 140, 115, 150, 200, 150, 150,
                        ],
                      },
                    ]}
                  />
                </div>
                <div className="d-flex justify-content-center mt-0">
                  <button className="btn py-2 btn-dark fs-7 fw-bold">
                    View Event
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}

        {/*end::Row*/}
      </div>
      <Config onClose={() => setViewConfig(false)} open={viewConfig} />
    </Wrapper>
  );
};

export default Dashboard;
