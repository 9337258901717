import React from "react";

const Add = ({ close }) => {
  return (
    <div id="kt_content_container" className="container-fluid">
      <div className="row g-2">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header  ">
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Add Version</h3>
              </div>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body pt-0 pb-0 ">
              <div className="row g-5">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Version number
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0 fs-7"
                      placeholder
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Module
                    </label>
                    <select
                      name
                      className="form-select form-select-solid mb-3 mb-lg-0 fs-7"
                      id
                    >
                      <option value>Admin Partner</option>
                      <option value>User</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Work Order Info
                    </label>
                    <textarea
                      name
                      className="form-control form-control-solid fs-7"
                      id
                      cols={30}
                      rows={10}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Description
                    </label>
                    <textarea
                      name
                      className="form-control form-control-solid fs-7"
                      id
                      cols={30}
                      rows={10}
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Release Note URL
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0 fs-7"
                      placeholder
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Start Date
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0 fs-7 kt_datepicker_1 flatpickr-input"
                      placeholder="Pick a date"
                      readOnly="readonly"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Intended Release Date
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0 fs-7 kt_datepicker_1 flatpickr-input"
                      placeholder="Pick a date"
                      readOnly="readonly"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor className="fw-bold fs-6 mb-1">
                      Actual Release Date
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0 fs-7 kt_datepicker_1 flatpickr-input"
                      placeholder="Pick a date"
                      readOnly="readonly"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  {/*begin::Form*/}
                  <form className="form" action="#" method="post">
                    {/*begin::Input group*/}
                    <div className="fv-row">
                      {/*begin::Dropzone*/}
                      <div className="dropzone" id="kt_dropzonejs_example_1">
                        {/*begin::Message*/}
                        <div className="dz-message needsclick">
                          <i className="ki-duotone ki-file-up fs-3x text-primary">
                            <span className="path1" />
                            <span className="path2" />
                          </i>
                          {/*begin::Info*/}
                          <div className="ms-4">
                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                              Drop files here or click to upload.
                            </h3>
                            <span className="fs-7 fw-semibold text-gray-500">
                              Upload up to 10 files
                            </span>
                          </div>
                          {/*end::Info*/}
                        </div>
                      </div>
                      {/*end::Dropzone*/}
                    </div>
                    {/*end::Input group*/}
                  </form>
                  {/*end::Form*/}
                </div>
                <div className="col-lg-12 text-end">
                  <button
                    type="button"
                    onClick={() => close()}
                    className="btn btn-light fs-7 px-10 me-2"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary fs-7 px-10">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
