import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Empty } from "antd";

am4core.useTheme(am4themes_animated);

const Sales360Pie = ({ data, idName, sign = true }) => {
  const replaceTitle = {
    "Sponsored Products": "Sp. Products",
    "Sponsored Brands": "Sp. Brands",
    "Sponsored Brands Video": "Sp. Brands Video",
    "Sponsored Display": "Sp. Display",
    "Sponsored Television": "Sp. Television",
    "Paid Clicks": "Paid Page Views",
    "Organic Clicks": "Organic Page Views",
    "External Clicks": "External Page Views",
  };

  const dataTitleChange = data?.map((d) => ({
    ...d,
    category: replaceTitle?.[d?.category] || d?.category,
  }));
  const chartRef = useRef(null);
  useLayoutEffect(() => {
    let chart = am4core.create(idName, am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0;

    chart.data = dataTitleChange
      ?.map((d) => ({ ...d, value: parseInt(d?.value) }))
      ?.filter((d) => parseInt(d?.value) !== 0);

    chart.innerRadius = am4core.percent(40);
    chart.depth = 15;

    chart.legend = new am4charts.Legend();

    chart.logo.disabled = true;
    chart.legend.disabled = false;
    chart.legend.position = "left";
    chart.legend.labels.template.fontWeight = "bold";
    chart.legend.valign = "top";
    chart.legend.layout = "vertical";
    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.depthValue = "value";
    series.dataFields.category = "category";
    series.slices.template.cornerRadius = 5;
    series.slices.template.tooltipText = sign
      ? "{category}: ${value}"
      : "{category}: {value}";

    series.labels.template.disabled = true;
    let colors = [
      "#f8285b",
      "#7239ea",
      "#15c653",
      "#F1FADA",
      "#9AD0C2",
      "#2D9596",
      "#265073",
    ];
    series.labels.template.radius = am4core.percent(-20);

    // Set slice colors
    series.colors.list =
      idName === "Paid_Sales"
        ? colors.map((color) => am4core.color(color))
        : colors.reverse().map((color) => am4core.color(color));
    chartRef.current = chart;
    let label = series.labels.template; // Get the label template of the series

    label.fontWeight = "bold";
    return () => {
      chart.dispose();
    };
  }, [idName, dataTitleChange]);

  return (
    <>
      {data?.filter((d) => d?.value !== 0)?.length === 0 && (
        <div>
          {" "}
          <Empty />{" "}
        </div>
      )}
      <div
        id={idName}
        style={{ width: "100%", height: "290px", padding: "0px 15px" }}
        ref={chartRef}
      ></div>
    </>
  );
};

export default Sales360Pie;
