import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const Sales360OrderGraph = ({ data, chartID }) => {
  const replaceTitle = {
    "Sponsored Products": "Sp. Products",
    "Sponsored Brands": "Sp. Brands",
    "Sponsored Brands Video": "Sp. Brands Video",
    "Sponsored Display": "Sp. Display",
    "Sponsored Television": "Sp. Television",
  };
  const dataTitleChange = data?.map((d) => ({
    ...d,
    category: replaceTitle?.[d?.category] || d?.category,
  }));
  useEffect(() => {
    // Initialize amCharts
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create(chartID, am4charts.PieChart);
    let colors = [
      "#f8285b",
      "#f7c002",
      "#7239ea",
      "#15c653",
      "#F1FADA",
      "#9AD0C2",
      "#2D9596",
      "#265073",
    ];
    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    chart.logo.disabled = true;
    chart.legend = new am4charts.Legend();
    chart.legend.disabled = false;
    chart.legend.position = "left";
    chart.legend.labels.template.fontWeight = "bold";
    chart.legend.valign = "top";
    chart.legend.layout = "vertical";
    // Set inner radius
    chart.innerRadius = am4core.percent(50);
    pieSeries.labels.template.radius = am4core.percent(-20);
    pieSeries.colors.list =
      chartID === "overview-of"
        ? colors.map((color) => am4core.color(color))
        : colors.reverse().map((color) => am4core.color(color));
    pieSeries.slices.template.tooltipText = "{category}: {value}";

    pieSeries.labels.template.disabled = true;

    let label_ = pieSeries.labels.template; // Get the label template of the series
    label_.fontWeight = "bold";
    // Add label
    let label = chart.seriesContainer.createChild(am4core.Label);

    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 20;
    label.fontWeight = "bold";
    label.text = `${dataTitleChange
      ?.map((d) => parseInt(d?.value))
      ?.reduce((acc, curr) => acc + curr, 0)} Units`;

    chart.data = dataTitleChange
      ?.map((d) => ({ ...d, value: parseInt(d?.value) }))
      ?.filter((d) => parseInt(d?.value) !== 0);

    return () => {
      // clearInterval(interval);
      chart.dispose();
    };
  }, [dataTitleChange]);

  return <div id={chartID} style={{ width: "100%", height: "290px" }}></div>;
};

export default Sales360OrderGraph;
