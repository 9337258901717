import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Modal,
  Popconfirm,
  Table,
  message,
} from "antd";
import React, { useContext, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { useEffect } from "react";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import moment from "moment";
import { GlobalContext } from "../../../../common-context";
import CredentialsError from "../../../credential-page";
import InfoVideo from "../../../../components/header-icons";
import { EditIcon, PropsFilter } from "../../../../config";

const SystemParametersUser = ({ menusList, pageTitle }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalLoading, setModalLoading] = useState(false);

  const [locationData, setLocationData] = useState({});
  const getLocationData = async (data) => {
    const response = await MakeApiCall(
      `menu-content-data?menu_item_id=8`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setLocationData(response?.data);
    } else {
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getLocationData();
    return () => {};
  }, []);

  const handleEdit = (id) => {
    setIsVisible(true);
    setSelectedRow(id);
  };

  const updateRow = async (data) => {
    const response = await MakeApiCall(
      `seller/system-parameter-log/${contextValue?.data?.user_?.seller_id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      setSelectedRow({});
      setLoading(true);
      getList({
        page: 1,
      });
      setModalLoading(false);
      setIsVisible(false);
      message.destroy();
    } else {
      message.destroy();
      setModalLoading(false);
      message.error(response?.message);
    }
  };
  const addRow = async (data) => {
    const response = await MakeApiCall(
      `system-configuration`,
      "post",
      data,
      true
    );

    if (response?.status) {
      setList([...list, response?.data]);
      setModalLoading(false);
      setIsVisible(false);
    } else {
      setModalLoading(false);
      message.error(response?.message);
    }
  };
  const contextValue = useContext(GlobalContext);

  const getList = async (data) => {
    const response = await MakeApiCall(
      `seller/system-parameter-log/${contextValue?.data?.user_?.seller_id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.map((d) => ({ ...d, children: d?.child })) || []);

      setLoading(false);
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    return () => {};
  }, []);

  const handleOk = (values) => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      updateRow({
        param_value_new: parseInt(values?.param_value_new || 0),
        system_parameter_id: selectedRow?.system_parameter_id,
      });
    } else {
      addRow(values);
      setModalLoading(true);
    }
  };

  const columns = [
    {
      title: "ID",
      align: "center",

      render: (_, __, i) => {
        return <span>{i + 1}</span>;
      },
    },
    {
      title: "Parameter",
      dataIndex: "param_name",
      ...PropsFilter("param_name"),
      key: "param_name",
    },
    {
      title: "Default Value",
      dataIndex: "param_value_old",
      ...PropsFilter("param_value_old"),
      key: "param_value_old",
      render: (value) => {
        return `${value}%`;
      },
    },
    {
      title: "New Value",
      dataIndex: "param_value_new",
      key: "param_value_new",
      ...PropsFilter("param_value_new"),
      render: (value) => `${value}%`,
    },

    {
      title: "Updated By",
      dataIndex: "seller_name",
      ...PropsFilter("seller_name"),
      key: "seller_name",
      render: (value) => <span>{value}</span>,
    },
    {
      title: "Action",
      align: "center",
      render: (id) => {
        if (!id?.children) {
          return "-";
        }
        return (
          <div className="d-flex align-items-center justify-content-center">
            {console.log(id?.children, "id")}
            <a onClick={() => handleEdit(id)}>{EditIcon}</a>
            <a
              style={{
                color: "#065ad8",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                message.destroy();
                message.loading("Loading...", 0);
                updateRow({
                  param_value_new: 3,
                  system_parameter_id: id?.system_parameter_id,
                });
              }}
            >
              <svg
                width={24}
                className="ms-2"
                height={24}
                fill="#065ad8"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.942 3.791c.3.3.3.785 0 1.084L6.184 6.633H14.3a6.9 6.9 0 1 1 0 13.8H8.167a.767.767 0 0 1 0-1.533H14.3a5.367 5.367 0 1 0 0-10.733H6.184l1.758 1.758a.767.767 0 0 1-1.084 1.084L3.79 7.942c-.3-.3-.3-.785 0-1.084L6.858 3.79c.3-.3.785-.3 1.084 0Z"
                  clipRule="evenodd"
                />
              </svg>
              <span style={{ position: "relative", top: 2 }}>
                Reset to Default
              </span>
            </a>
          </div>
        );
      },
    },
    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      ...PropsFilter("updated_at"),
      align: "center",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY hh:mm A");
      },
    },
  ];

  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "system-parameters")?.is_restricted === 1
  ) {
    return <CredentialsError type="system-parameters" />;
  }
  if (isVisible) {
    return (
      <div
        className="custom-ui px-5 mt-5"
        style={{
          // display: "flex",
          // justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div className="card">
          <div className="card-body">
            {console.log(selectedRow, "selectedRow")}
            <Form
              name="parameterValueForm"
              style={{ marginTop: "20px" }}
              initialValues={{ ...selectedRow }}
              onFinish={handleOk}
            >
              <Form.Item
                name="param_name"
                rules={[
                  { required: true, message: "Please input your parameter!" },
                ]}
              >
                <Input disabled placeholder="Parameter" />
              </Form.Item>
              <Form.Item
                name="param_value_new"
                rules={[
                  { required: true, message: "Please input your value!" },
                  {
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Input must be a number!",
                  },
                ]}
              >
                <Input placeholder="Value" />
              </Form.Item>
              <div className="row gap-2 mb-4">
                {/* <div className="col-12 " style={{ color: "#6c757d" }}>
                  Deployed At:{" "}
                  {moment(
                    new Date(parseInt(selectedRow.created_at) * 1000)
                  ).format("MM/DD/YYYY hh:mm A")}
                </div>
                <div className="col-12" style={{ color: "#6c757d" }}>
                  Published At:{" "}
                  {moment(
                    new Date(parseInt(selectedRow.created_at) * 1000)
                  ).format("MM/DD/YYYY hh:mm A")}
                </div> */}
                <div className="col-12" style={{ color: "#6c757d" }}>
                  Deployed By: {selectedRow?.updated_by}
                </div>
                <div className="col-12" style={{ color: "#6c757d" }}>
                  Republished At:{" "}
                  {moment(
                    new Date(parseInt(selectedRow.updated_at) * 1000)
                  ).format("MM/DD/YYYY hh:mm A")}
                </div>
              </div>
              <Form.Item>
                <Button loading={modalLoading} type="primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  type="dashed"
                  className="ms-3"
                  onClick={() => {
                    setIsVisible(false);
                  }}
                >
                  Close
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className="custom-ui px-5  pt-5 position-relative"
        style={{
          // display: "flex",
          // justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <h5
          class="primaryHeading2 d-flex justify-content-start mt-2 my-3"
          style={{ position: "absolute", top: "-28px", left: "296px" }}
        >
          <InfoVideo
            data={locationData}
            title="System Parameters"
            className={"ms-3"}
          />
        </h5> */}

        <div className="card">
          <div className="card-header px-5">
            <div className="card-title">
              {pageTitle}
              <InfoVideo
                data={locationData}
                title="System Parameters"
                className={"ms-3"}
              />
            </div>
          </div>
          <div className="card-body pt-0 px-5">
            {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div className="table-responsive">
                {console.log(list, "list")}
                <Table
                  dataSource={list}
                  scroll={{ x: "max-content" }}
                  columns={columns}
                  loading={loading}
                  pagination={false}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {isVisible && (
        <Modal
          title={Object.keys(selectedRow)?.length === 0 ? "Add" : "Edit"}
          open={isVisible}
          onCancel={handleCancel}
          footer={null}
        ></Modal>
      )} */}
    </>
  );
};

export default SystemParametersUser;
