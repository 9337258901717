import { Button, Form, Input, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import Icons from "../../../../../components/icons";

const { Option } = Select;
const AddCaralog = ({ id, pageType, submit, setVisible }) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [pageLoading, setPageLoading] = useState(true);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(1);

  const addCatelog = async (data) => {
    const response = await MakeApiCall(
      `content-catalog${
        Object.keys(selectedRow)?.length === 0 ? "" : `/${selectedRow?.id}`
      }`,
      Object.keys(selectedRow)?.length === 0 ? "post" : "put",
      data,
      true
    );

    if (response?.status) {
      submit();
      setLoading(false);
      form.resetFields();
      setVisible(false); // Close modal after form submission
    } else {
      setLoading(false);
      message.warning(
        Object.values(response?.data || {})?.[0] || response?.message
      );
    }
  };

  const getRecord = async () => {
    const response = await MakeApiCall(
      `content-catalog/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedRow(response?.data || {});
      setPageLoading(false);
      // setLoading(false);
    } else {
      setPageLoading(false);
      setSelectedRow({});
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (pageType === "edit") {
      setPageLoading(true);
      getRecord();
    } else {
      setPageLoading(false);
    }
    // setLoading(true);
    return () => {};
  }, []);

  // Function to handle form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        addCatelog(values);
      })
      .catch((info) => {});
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue({
      type: 1,
      ...selectedRow,
    });
    setType(selectedRow?.type || 1);
  }, [selectedRow]);
  // handleCancel

  return (
    <Spin tip="Loading..." spinning={pageLoading}>
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <span
              className="me-3 cursor-pointer"
              onClick={() => {
                setVisible(false);
              }}
            >
              <Icons type="home" />
            </span>
            /{" "}
            {Object.keys(selectedRow)?.length === 0
              ? "Content Catalog"
              : "Edit Content Catalog"}
          </div>
        </div>
        <div className="px-10 pt-2">
          <Form form={form} layout="vertical">
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please input the title!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="info_heading"
              label="Info Heading"
              rules={[
                { required: true, message: "Please input the info heading!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="type" label="Type">
              <Select
                style={{ width: "100%" }}
                onChange={(e) => setType(e)}
                options={[
                  {
                    label: "Info",
                    value: 1,
                  },
                  {
                    label: "Video",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
            {type === 1 && (
              <div className="row">
                <div className="col-xxl-6  col-lg-12">
                  <Form.Item name="info_text" label="Info Text">
                    <Input.TextArea
                      style={{
                        height: 190,
                      }}
                      showCount
                      maxLength={500}
                    />
                  </Form.Item>
                </div>
                <div className="col-xxl-6 col-lg-12">
                  <div className="row">
                    <div className="col-12">
                      <Form.Item name="font_style" label="Font Style">
                        <Select placeholder="Font Style">
                          {[
                            {
                              label: "Default Sans",
                              value: "Arial, sans-serif",
                            },
                            {
                              label: "Serif",
                              value: "Georgia, serif",
                            },
                            {
                              label: "Sans Serif",
                              value: "'Helvetica Neue', sans-serif",
                            },
                            {
                              label: "Monospace",
                              value: "'Courier New', monospace",
                            },
                            {
                              label: "Roboto",
                              value: "'Roboto', sans-serif",
                            },
                            {
                              label: "Open Sans",
                              value: "'Open Sans', sans-serif",
                            },
                            {
                              label: "Lato",
                              value: "'Lato', sans-serif",
                            },
                            {
                              label: "Slabo",
                              value: "'Slabo 27px', serif",
                            },
                            {
                              label: "Oswald",
                              value: "'Oswald', sans-serif",
                            },
                            {
                              label: "Source Sans Pro",
                              value: "'Source Sans Pro', sans-serif",
                            },
                            {
                              label: "Montserrat",
                              value: "'Montserrat', sans-serif",
                            },
                            {
                              label: "Raleway",
                              value: "'Raleway', sans-serif",
                            },
                            {
                              label: "Ubuntu",
                              value: "'Ubuntu', sans-serif",
                            },
                            {
                              label: "Merriweather",
                              value: "'Merriweather', serif",
                            },
                            {
                              label: "PT Serif",
                              value: "'PT Serif', serif",
                            },
                          ]?.map((d, i) => (
                            <Option value={d?.value} key={i}>
                              <span style={{ fontFamily: d?.value }}>
                                {d?.label}
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item name="font_size" label="Font Size">
                        <Select placeholder="Font Size">
                          {[
                            {
                              label: "Extra Small",
                              value: 8,
                            },
                            {
                              label: "Small",
                              value: 12,
                            },
                            {
                              label: "Medium",
                              value: 16,
                            },
                            {
                              label: "Large",
                              value: 20,
                            },
                            {
                              label: "Extra Large",
                              value: 24,
                            },
                            {
                              label: "XX Large",
                              value: 30,
                            },
                            {
                              label: "XXX Large",
                              value: 36,
                            },
                          ]?.map((d, i) => (
                            <Option value={d?.value} key={i}>
                              <span style={{ fontSize: d?.value }}>
                                {d?.label}
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-12">
                      <Form.Item name="font_weight" label="Font Weight">
                        <Select placeholder="Font Weight">
                          {[
                            {
                              name: "Thin",
                              value: "100",
                            },
                            {
                              name: "Extra Light",
                              value: "200",
                            },
                            {
                              name: "Light",
                              value: "300",
                            },
                            {
                              name: "Normal",
                              value: "400",
                            },
                            {
                              name: "Medium",
                              value: "500",
                            },
                            {
                              name: "Semi Bold",
                              value: "600",
                            },
                            {
                              name: "Bold",
                              value: "700",
                            },
                            {
                              name: "Extra Bold",
                              value: "800",
                            },
                            {
                              name: "Black",
                              value: "900",
                            },
                          ]?.map((d, i) => (
                            <Option value={d?.value} key={i}>
                              <span style={{ fontWeight: d?.value }}>
                                {d?.name}
                              </span>
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {type === 2 && (
              <>
                <Form.Item name="video_url" label="Video URL">
                  <Input />
                </Form.Item>

                <Form.Item name="showcase_url" label="Showcase URL">
                  <Input />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-end">
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>

          <Button
            key="submit"
            className="ms-3"
            type="primary"
            loading={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default AddCaralog;
