import { Badge } from "antd";
import { GetLinks } from "../../../config";

export default [
  {
    key: "6",
    icon: <i className="ki-outline ki-chart-simple-2 fs-1 " />,
    label: (
      <>
        Application Logs{" "}
        <Badge className="ms-2" count={3} showZero color="#01c0c8" />
      </>
    ),
    children: [
      {
        key: "/application-logs/central-log",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-questionnaire-tablet fs-1" />
          </span>
        ),
        label: GetLinks("/application-logs/central-log", "Central Log"),
      },
      {
        key: "/application-logs/data-scheduler",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-questionnaire-tablet fs-1" />
          </span>
        ),
        label: GetLinks("/application-logs/data-scheduler", "Data Scheduler"),
      },
      {
        key: "/application-logs/system-log",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-tablet-book fs-1" />
          </span>
        ),
        label: GetLinks("/application-logs/system-log", "System Log"),
      },
    ],
  },
];
