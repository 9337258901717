import { DatePicker, Empty, Input, Select, Table, message } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ErrorModal } from "../../../../components/upload-modals";
import { RenderTable } from "../../../../config";
dayjs.extend(quarterOfYear);
const SpTargeting = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: "report_date",
    sort_by: "desc",
  });

  const [filters, setFilters] = useState({
    campaign_name: null,
    campaign_status: null,
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Report Date",
      dataIndex: "report_date",
      key: "report_date",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "Campaign ID",
      dataIndex: "campaign_id",
      key: "campaign_id",
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      key: "impressions",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      key: "clicks",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Cost Per Click ($)",
      dataIndex: "cost_per_click",
      key: "cost_per_click",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Click Through Rate (%)",
      dataIndex: "click_through_rate",
      key: "click_through_rate",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Cost ($)",
      dataIndex: "cost",
      key: "cost",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Purchases 1 Day",
      dataIndex: "purchases1d",
      key: "purchases1d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases 7 Days",
      dataIndex: "purchases7d",
      key: "purchases7d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases 14 Days",
      dataIndex: "purchases14d",
      key: "purchases14d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases 30 Days",
      dataIndex: "purchases30d",
      key: "purchases30d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases Same SKU 1 Day",
      dataIndex: "purchases_same_sku1d",
      key: "purchases_same_sku1d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases Same SKU 7 Days",
      dataIndex: "purchases_same_sku7d",
      key: "purchases_same_sku7d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases Same SKU 14 Days",
      dataIndex: "purchases_same_sku14d",
      key: "purchases_same_sku14d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Purchases Same SKU 30 Days",
      dataIndex: "purchases_same_sku30d",
      key: "purchases_same_sku30d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Clicks 1 Day",
      dataIndex: "units_sold_clicks1d",
      key: "units_sold_clicks1d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Clicks 7 Days",
      dataIndex: "units_sold_clicks7d",
      key: "units_sold_clicks7d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Clicks 14 Days",
      dataIndex: "units_sold_clicks14d",
      key: "units_sold_clicks14d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Clicks 30 Days",
      dataIndex: "units_sold_clicks30d",
      key: "units_sold_clicks30d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Sales 1 Day ($)",
      dataIndex: "salesLast1D",
      key: "salesLast1D",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Sales 7 Days ($)",
      dataIndex: "sales7d",
      key: "sales7d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Sales 14 Days ($)",
      dataIndex: "sales14d",
      key: "sales14d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Sales 30 Days ($)",
      dataIndex: "sales30d",
      key: "sales30d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Attributed Sales Same SKU 1 Day ($)",
      dataIndex: "attributed_sales_same_sku1d",
      key: "attributed_sales_same_sku1d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Attributed Sales Same SKU 7 Days ($)",
      dataIndex: "attributed_sales_same_sku7d",
      key: "attributed_sales_same_sku7d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Attributed Sales Same SKU 14 Days ($)",
      dataIndex: "attributed_sales_same_sku14d",
      key: "attributed_sales_same_sku14d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Attributed Sales Same SKU 30 Days ($)",
      dataIndex: "attributed_sales_same_sku30d",
      key: "attributed_sales_same_sku30d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Units Sold Same SKU 1 Day",
      dataIndex: "units_sold_same_sku1d",
      key: "units_sold_same_sku1d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Same SKU 7 Days",
      dataIndex: "units_sold_same_sku7d",
      key: "units_sold_same_sku7d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Same SKU 14 Days",
      dataIndex: "units_sold_same_sku14d",
      key: "units_sold_same_sku14d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Units Sold Same SKU 30 Days",
      dataIndex: "units_sold_same_sku30d",
      key: "units_sold_same_sku30d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Kindle Edition Normalized Pages Read 14 Days",
      dataIndex: "kindle_edition_normalized_pages_read14d",
      key: "kindle_edition_normalized_pages_read14d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Kindle Edition Normalized Pages Royalties 14 Days",
      dataIndex: "kindle_edition_normalized_pages_royalties_14d",
      key: "kindle_edition_normalized_pages_royalties_14d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "Sales Other SKU 7 Days ($)",
      dataIndex: "sales_other_sku7d",
      key: "sales_other_sku7d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Units Sold Other SKU 7 Days",
      dataIndex: "units_sold_other_sku7d",
      key: "units_sold_other_sku7d",
      align: "right",
      render: (e) => {
        return Comma(e);
      },
    },
    {
      title: "ACOS Clicks 7 Days ($)",
      dataIndex: "acos_clicks7d",
      key: "acos_clicks7d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "ACOS Clicks 14 Days ($)",
      dataIndex: "acos_clicks14d",
      key: "acos_clicks14d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "ROAS Clicks 7 Days ($)",
      dataIndex: "roas_clicks7d",
      key: "roas_clicks7d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "ROAS Clicks 14 Days ($)",
      dataIndex: "roas_clicks14d",
      key: "roas_clicks14d",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Keyword ID",
      dataIndex: "keyword_id",
      key: "keyword_id",
    },
    {
      title: "Keyword",
      dataIndex: "keyword",
      key: "keyword",
    },
    {
      title: "Campaign Budget Currency Code",
      dataIndex: "campaign_budget_currency_code",
      key: "campaign_budget_currency_code",
    },
    {
      title: "Portfolio ID",
      dataIndex: "portfolio_id",
      key: "portfolio_id",
    },

    {
      title: "Campaign Status",
      dataIndex: "campaign_status",
      key: "campaign_status",
    },
    {
      title: "Campaign Budget Amount ($)",
      dataIndex: "campaign_budget_amount",
      key: "campaign_budget_amount",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Campaign Budget Type",
      dataIndex: "campaign_budget_type",
      key: "campaign_budget_type",
    },
    {
      title: "Keyword Bid ($)",
      dataIndex: "keyword_bid",
      key: "keyword_bid",
      align: "right",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
    {
      title: "Ad Group Name",
      dataIndex: "ad_group_name",
      key: "ad_group_name",
    },
    {
      title: "Ad Group ID",
      dataIndex: "ad_group_id",
      key: "ad_group_id",
    },
    {
      title: "Keyword Type",
      dataIndex: "keyword_type",
      key: "keyword_type",
    },
    {
      title: "Match Type",
      dataIndex: "match_type",
      key: "match_type",
    },
    {
      title: "Targeting",
      dataIndex: "targeting",
      key: "targeting",
    },
    {
      title: "Marketplace ID",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
    },

    // new columns
    {
      title: "SIP Targeting Type",
      dataIndex: "sip_targeting_type",
      key: "sip_targeting_type",
    },
    {
      title: "SIP Medium",
      dataIndex: "sip_medium",
      key: "sip_medium",
    },
    {
      title: "SIP Destination",
      dataIndex: "sip_destination",
      key: "sip_destination",
    },
    {
      title: "SIP Destination URL",
      dataIndex: "sip_destination_url",
      key: "sip_destination_url",
    },
    {
      title: "SIP Placement",
      dataIndex: "sip_placement",
      key: "sip_placement",
    },
    {
      title: "Top of Search Impression Share (%)",
      dataIndex: "top_of_search_impression_share",
      key: "top_of_search_impression_share",
      render: (e) => {
        return CommaAndFloat(e);
      },
    },
  ];

  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `advertising/sp-targeting?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&campaign_name=${data?.campaign_name || ""}&campaign_status=${
        data?.campaign_status || ""
      }&start_date=${data?.start_date || ""}&end_date=${data?.end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTableLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setLoading(false);
      setTotalPage(0);
      setTableLoading(false);
      ErrorModal(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...filters, page: page, pageSize: pageSize, ...sortFilters });
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters, page: page, pageSize: pageSize });
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Sponsored Products Targeting
          <InfoVideo
            data={locationData}
            title="SP Targeting"
            className={"ms-3"}
          />
          <Input
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            allowClear
            value={filters?.campaign_name}
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      campaign_name: e.target.value,
                    },
                    ...sortFilters,
                    page: page,
                    pageSize: pageSize,
                  });
                }
                return {
                  ...prev,
                  campaign_name: e.target.value,
                };
              });
            }}
            placeholder="Campaign Name"
            className="w-175px ms-3"
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            className="btn btn-sm ms-3 btn-search"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select
            placeholder="Campaign Status"
            className="w-150px ms-3"
            allowClear
            value={filters?.campaign_status}
            onChange={(e) => {
              const obj = {
                ...filters,
                campaign_status: e,
              };
              setFilters(obj);
              getList({
                ...obj,
                page: page,
                pageSize: pageSize,
                ...sortFilters,
              });
            }}
            options={[
              {
                label: "ENABLED",
                value: "ENABLED",
              },
              {
                label: "PAUSED",
                value: "PAUSED",
              },
            ]}
          />
        </div>
        <div className="card-toolbar">
          <DatePicker.RangePicker
            // allowClear
            presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              setFilters(obj);
              getList({ ...obj, ...sortFilters });
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          />
          <ExportFile
            title="SP Targeting"
            hideTooltip={filters?.start_date && filters?.end_date}
            api={`advertising/sp-targeting?start_date=${
              filters?.start_date || ""
            }&end_date=${filters?.end_date || ""}&is_export=1&field_name=${
              sortFilters?.field_name || ""
            }&sort_by=${sortFilters?.sort_by || ""}&campaign_name=${
              filters?.campaign_name || ""
            }&campaign_status=${filters?.campaign_status || ""}`}
          />
        </div>
      </div>
      <div className="card-body  pt-0">
        {loading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              loading={tableLoading}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              scroll={{ x: "max-content" }}
              columns={columns?.map((d) => ({
                ...d,
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              // columns={columns}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              pageSize: pageSize,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SpTargeting;
