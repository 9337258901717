import Icons from "../../../components/icons";
import { GetLinks } from "../../../config";

export default [
  {
    key: "/resources",
    icon: <i className="ki-outline ki-scroll fs-1 " />,
    label: GetLinks("/resources", "Resources"),
  },
];
