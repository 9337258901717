import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { message } from "antd";
import Pagination from "../../../../components/pagination";
import moment from "moment";
import { RenderTable } from "../../../../config";

const UserLogs = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const GetList = async (data) => {
    const response = await MakeApiCall(
      `seller-tracking/user-logs?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    GetList({ ...obj });
  };

  const columns = [
    {
      title: "ID",

      align: "center",
      render: (text, record, index) => (page - 1) * pageSize + 1 + index,
    },
    {
      title: "Seller Name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
      key: "seller_name",
    },

    {
      title: "Email",
      dataIndex: "email",
      filterIndex: "email",
      key: "email",
    },
    {
      title: "Event ID",
      dataIndex: "event_type_id",
      filterIndex: "event_type_id",
      key: "event_type_id",
    },
    {
      title: "Event Types",
      dataIndex: "event_name",
      filterIndex: "event_name",
      key: "event_name",
    },
    {
      title: "Date & Time (ET)",
      align: "left",
      filterIndex: "convert_created_at",
      render: (d) => {
        return (
          <div>
            {moment
              .utc(d?.convert_created_at, "MM/DD/YYYY hh:mmA")
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Logged In (ET)",
      dataIndex: "logged_in_date_time",
      filterIndex: "logged_in_date_time",
      key: "logged_in_date_time",
      render: (d) => {
        if (!moment(d, "YYYY-MM-DD hh:mm:ss").isValid()) {
          return;
        }
        return (
          <div>
            {moment
              .utc(d, "YYYY-MM-DD hh:mm:ss")
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Logged Out (ET)",
      dataIndex: "logged_out_date_time",
      filterIndex: "logged_out_date_time",
      key: "logged_out_date_time",
      render: (d) => {
        if (!moment(d, "YYYY-MM-DD hh:mm:ss").isValid()) {
          return;
        }
        return (
          <div>
            {moment
              .utc(d, "YYYY-MM-DD hh:mm:ss")
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
    {
      title: "Time Spent",
      dataIndex: "time_spent",
      filterIndex: "time_spent",
      key: "time_spent",

      render: (e) => {
        return <span>{e}</span>;
      },
    },

    {
      title: "Updated At (ET)",
      align: "left",
      filterIndex: "convert_updated_at",

      render: (d) => {
        return (
          <div>
            {moment
              .utc(d?.convert_updated_at, "MM/DD/YYYY hh:mmA")
              .tz("America/New_York")
              .format("MM/DD/YYYY hh:mm A")}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    GetList();
    return () => {};
  }, []);

  // Example data for the table
  const data = [
    {
      key: "1",
      id: "1",
      sellerId: "123",
      userId: "456",
      loggedIn: "2023-01-01 08:00:00",
      loggedOut: "2023-01-01 10:00:00",
      timeSpent: "2 hours",
      eventId: "789",
      eventTypes: "Login",
    },
    // Add more data as needed
  ];
  return (
    <div className="card">
      <div className="card-body pt-0">
        <Table
          fixed={true}
          sticky={{
            offsetHeader: "0px",
          }}
          columns={columns?.map((d) => ({
            ...d,
            ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
            render: (props, row, index) => RenderTable(props, row, index, d),
          }))}
          onChange={handleChange}
          loading={loading}
          dataSource={list}
          rowClassName={(record, index) => {
            return index % 2 === 0 ? "even-row" : "odd-row";
          }}
          bordered
          className="mt-5"
          pagination={false}
          scroll={{ x: "max-content" }}
          size="small"
        />
      </div>
      <div className="card-footer pt-0 pb-5">
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            GetList({
              page: 1,
              ...sortFilters,
              pageSize: e,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            GetList({
              page: e,
              ...sortFilters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default UserLogs;
