import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-table-row:hover .ant-table-cell {
    background: #c0ebff !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    // padding: 4px 16px !important;
  }

  .ant-table-thead .ant-table-cell {
    background: #f1f1f1 !important;
    color: #202020 !important;
  }
`;
