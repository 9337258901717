import {
  DatePicker,
  Empty,
  Modal,
  Spin,
  Tabs,
  Tag,
  Watermark,
  message,
} from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { GlobalContext } from "../../../../common-context";
import InfoVideo from "../../../../components/header-icons";
import { TableLoading } from "../../../../components/table-animation";
import PlayView from "../../../../components/video";
import { numberformat, updatePrevDates } from "../../../../config";
import CredentialsError from "../../../credential-page";
import { PremiumContent } from "../../premium-content";
import { Wrapper } from "../style";
import CardsView from "./cards";
import { GraphMetric } from "./financial";
import Config from "./lib/config";
import LineItemsSales from "./line-items-sales";
import Sales360Pie from "./sales-360-pie";
import TrafficTables from "./traffic-tables";
import Graph from "./graph";

dayjs.extend(quarterOfYear);
const Traffic360 = (props) => {
  const { pageTitle, menusList } = props;
  const [viewConfig, setViewConfig] = useState(false);
  const [visible, setVisible] = useState(false);
  const [filters, setFilters] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const [summaryDates, setSummaryDates] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [selectedRange, setSelectedRange] = useState("This Month");
  const [selectedCard, setSelectedCard] = useState([
    "unitsSold",
    "metric_chart_data",
    "Units Sold",
  ]);

  const [overviewLoading, setOverviewLoading] = useState(true);
  const [overviewFilter, setOverviewFilter] = useState(
    dayjs().subtract(1, "day").format("YYYY-MM-DD")
  );
  const [overviewData, setOverviewData] = useState({});

  const [tabelLoading, setTabelLoading] = useState(true);
  const [otherStatic, setOtherStatic] = useState({});
  const [convertedData, setConvertedData] = useState({
    traffic: [],
    paid_traffic: [],
    traffic_conversion_cr: [],
    paid_traffic_cr: [],
    total_traffic: {},
    total_paid_clicks: {},
    average_cr: {},
    average_paid_traffic_cr: {},
  });

  const [premiumTable, setPremiumTable] = useState({
    premium_traffic: [],
    premium_total_traffic: {},
    premium_traffic_conversion_cr: [],
    premium_traffic_conversion_ratio: [],
  });
  const [metricSettingList, setMetricSettingList] = useState([]);
  const [metricSettingLoading, setMetricSettingLoading] = useState(true);

  const [premiumTableLoading, setPremiumTableLoading] = useState(true);

  const [metricGraphLoading, setMetricGraphLoading] = useState(true);

  const [graphLoading, setGraphLoading] = useState(true);
  const [summaryType, setSummaryType] = useState("");
  const [graphData, setGraphData] = useState({});

  const [selectedRow, setSelectedRow] = useState({});

  const [getAllLineItemList, setGetAllLineItemList] = useState([]);
  const [lineItemListLoading, setLineItemListLoading] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState([]);

  const [selectedTabFilter, setSelectedTabFilter] = useState("1");

  const [filtersLine, setFiltersLine] = useState({
    start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [selectedRangeLine, setSelectedRangeLine] = useState("This Month");

  const getAllLineItemsList = async (data) => {
    setSelectedMetric(data?.metric_name);
    setSummaryType(data?.summary_type);
    setLineItemListLoading(true);
    try {
      const response = await MakeApiCall(
        `funnel/get-line-items?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${data?.end_date || filters?.end_date || ""}&metric_name=${
          typeof data?.metric_name === "string"
            ? data?.metric_name
            : data?.metric_name?.join(",")
        }${
          data?.summary_type ? `&summary_type=${data?.summary_type || ""}` : ""
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setGetAllLineItemList(response?.data || []);

        setLineItemListLoading(false);
      } else {
        setGetAllLineItemList([]);
        setLineItemListLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLineItemListLoading(false);

      message.destroy();
      message.error(error?.message);
    }
  };

  const [selectedGraph, setSelectedGraph] = useState([]);
  const borderColor = {
    1: "#E31715",
    2: "#FDC71E",
    3: "#00B55D",
  };
  const backgroundColor = {
    1: "#FFF6F6",
    2: "#FFFEF6",
    3: "#F6FFFA",
  };

  const getBorderColor = (value) => {
    if (value == 0 || value === "STAGNANT") {
      return 2;
    }
    if (value === "DOWN") {
      return 1;
    }
    if (value === "UP") {
      return 3;
    }
    return 3;
  };

  const signConvert = (value) => {
    return value.replace("$-", "-$");
  };
  function capitalizeEachWord(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const a = "100";
  const l = "#F1F1F2";
  const green = "#50cd89";
  const yellow = "#ffc803";
  const red = "#f1416c";
  const contextValue = useContext(GlobalContext);

  const getUpdatedPCA = async () => {
    const response = await MakeApiCall(
      `funnel/premium-content-status`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user_ = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user_));
      contextValue.updateCommonGlobalVal({
        user_: user_,
        credentialsStatus: obj,
      });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getUpdatedPCA();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const PCAUpdate = async (data, message_) => {
    const response = await MakeApiCall(
      `seller/pca-stage/${contextValue?.data?.user_?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();

      const obj = {
        sp: response?.data?.amazon_connected,
        lws: response?.data?.amazon_ads_connected,
      };
      const user = {
        ...JSON.parse(localStorage.getItem("user")),
        pca_connected: response?.data?.pca_connected,
        pca_current_stage: response?.data?.pca_current_stage,
        pca_close_status: response?.data?.pca_close_status,
      };
      localStorage.setItem("user", JSON.stringify(user));
      contextValue.updateCommonGlobalVal({
        user_: user,
        credentialsStatus: obj,
      });
      if (message_) {
        Modal.success({
          content: message_,
          centered: true,
        });
      }
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const [graphLabels, setGraphLabels] = useState({});

  const GraphAction = async (data) => {
    setGraphLoading(true);
    const response = await MakeApiCall(
      `funnel/get-metric-chart?start_date=${
        data?.start_date || filters?.start_date || ""
      }&end_date=${data?.end_date || filters?.end_date || ""}&selection_type=${
        data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
        selectedRange?.split(" ")?.join("_")?.toLowerCase()
      }&menu_item_id=4`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setGraphLabels(response?.data?.funnel_labels || {});
      setSelectedGraph(Object.values(response?.data?.funnel_labels || {}));
      const data = {
        ...response?.data,
        chartData: Object.entries(response?.data?.chartData || {})?.reduce(
          (acc, d, i) => ({
            ...acc,
            [d[0]]: Object.values(d?.[1] || {}),
          }),
          {}
        ),
      };
      setGraphData(data);
      setGraphLoading(false);
    } else {
      setGraphLoading(false);
      message.warning(response?.message);
    }
  };

  function convertData(one) {
    const outputObject = {};

    const two = Object?.keys(Object.values(one)?.[0])?.map((d) => {
      Object?.keys(one).forEach((key) => {
        if (d === "average_cr") {
        }
        const num = Math.round(one?.[key]?.[d]);

        outputObject[key] = num.toLocaleString("en-US", {
          minimumFractionDigits: 0,
        });
      });

      return {
        type: d,
        ...outputObject,
      };
    });

    return two;
  }

  const get_traffic_summary = {
    traffic: ["paid_clicks", "organic_clicks", "external_clicks"],
    paid_traffic: [
      "sponsored_products",
      "sponsored_brands",
      "sponsored_display",
      "sponsored_television",
    ],
    traffic_conversion_cr: [
      "paid_clicks_cr",
      "organic_clicks_cr",
      "external_clicks_cr",
    ],
    paid_traffic_cr: [
      "sponsored_products_cr",
      "sponsored_brands_cr",
      "sponsored_brands_video_cr",
      "sponsored_display_cr",
      "sponsored_television_cr",
    ],
    premium_traffic: [
      "top1000_keyword_traffic",
      "sp_keywords_traffic",
      "organic_keyword_traffic",
      "non_keyword_traffic",
    ],
    premium_traffic_conversion_cr: [
      "top1000_keyword_traffic_cr",
      "sp_keywords_traffic_cr",
      "organic_keyword_traffic_cr",
    ],
    premium_traffic_conversion_ratio: [
      "top1000_keyword_ratio",
      "organic_keyword_ratio",
      "non_keyword_ratio",
    ],
  };

  const [titles, setTitles] = useState({});
  const getList = async (data) => {
    setTabelLoading(true);
    GraphAction(data);
    setConvertedData({
      traffic: [],
      paid_traffic: [],
      traffic_conversion_cr: [],
      paid_traffic_cr: [],
      total_traffic: {},
      average_cr: {},
      average_paid_traffic_cr: {},
    });

    try {
      const response = await MakeApiCall(
        `funnel/get-summary?start_date=${
          data?.start_date || filters?.start_date || ""
        }&end_date=${
          data?.end_date || filters?.end_date || ""
        }&selection_type=${
          data?.selection_type?.split(" ")?.join("_")?.toLowerCase() ||
          selectedRange?.split(" ")?.join("_")?.toLowerCase()
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setTitles(response?.data?.funnel_labels || {});
        const { avg_cr, ...rest } = response?.data?.summary_data;
        const findTitle = {
          paid_clicks: "paid_traffic",
          organic_clicks: "organic_traffic",
          external_clicks: "external_traffic",
        };

        setConvertedData({
          traffic: convertData(response?.data?.summary_data)?.filter((item) =>
            get_traffic_summary?.traffic.includes(item.type)
          ),
          paid_traffic: convertData(response?.data?.summary_data)?.filter(
            (item) => get_traffic_summary?.paid_traffic.includes(item.type)
          ),

          traffic_conversion_cr: convertData(
            response?.data?.summary_data
          )?.filter((item) =>
            get_traffic_summary?.traffic_conversion_cr.includes(item.type)
          ),
          paid_traffic_cr: convertData(response?.data?.summary_data)?.filter(
            (item) => get_traffic_summary?.paid_traffic_cr.includes(item.type)
          ),

          total_traffic: convertData(response?.data?.summary_data)?.filter(
            (item) => ["total_clicks"].includes(item.type)
          )?.[0],
          total_paid_clicks: convertData(response?.data?.summary_data)?.filter(
            (item) => ["total_paid_clicks"].includes(item.type)
          )?.[0],
          average_cr: convertData(response?.data?.summary_data)?.filter(
            (item) => ["average_cr"].includes(item.type)
          )?.[0],
          average_paid_traffic_cr: convertData(
            response?.data?.summary_data
          )?.filter((item) =>
            ["average_paid_traffic_cr"].includes(item.type)
          )?.[0],
        });

        setTabelLoading(false);
      } else {
        setTabelLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setTabelLoading(false);

      setConvertedData({
        traffic: [],
        paid_traffic: [],
        traffic_conversion_cr: [],
        paid_traffic_cr: [],
        total_traffic: {},
        average_cr: {},
        average_paid_traffic_cr: {},
      });

      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    setTabelLoading(true);
    getList();

    return () => {};
  }, []);

  const optionsComman = {
    chart: {
      fontFamily: "Montserrat",
      type: "area",
      height: a,
      toolbar: { show: !1 },
    },
    legend: { show: !1 },
    dataLabels: { enabled: !1 },
    fill: { type: "solid", opacity: 0 },
    stroke: { curve: "smooth", show: !0, width: 2 },
    xaxis: {
      axisBorder: { show: !1 },
      axisTicks: { show: !1 },
      labels: { show: !1 },
      crosshairs: {
        position: "front",
        stroke: { width: 1, dashArray: 3 },
      },
      tooltip: {
        enabled: !0,
        formatter: void 0,
        offsetY: 0,
        style: { fontSize: "12px" },
      },
    },
    yaxis: { labels: { show: !1 } },
    states: {
      normal: { filter: { type: "none", value: 0 } },
      hover: { filter: { type: "none", value: 0 } },
      active: {
        allowMultipleDataPointsSelection: !1,
        filter: { type: "none", value: 0 },
      },
    },
    tooltip: {
      enabled: false,
      style: { fontSize: "12px" },
      x: {
        formatter: function (e) {
          return "Feb " + e;
        },
      },
      y: {
        formatter: function (e) {
          return "$" + e + "K";
        },
      },
    },

    grid: {
      borderColor: l,
      strokeDashArray: 4,
      padding: { top: 0, right: -20, bottom: -20, left: -20 },
      yaxis: { lines: { show: !0 } },
    },
    markers: {
      size: 2,
      colors: "#FFF",

      hover: {
        size: 3,
      },
    },
  };

  const cardList_ = [
    {
      label: "Paid Clicks Ratio",
      root: "paidPageViewsRatio",
      titleValue: "paidPageViewsRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "17",
    },
    {
      label: "Organic Clicks Ratio",
      root: "organicClicksRatio",
      titleValue: "organicClicksRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "18",
    },
    {
      label: "External Clicks Ratio",
      root: "externalClicksRatio",
      titleValue: "externalClicksRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "19",
    },
    {
      label: "SP Clicks Ratio",
      root: "sPClicksRatio",
      titleValue: "sPClicksRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "20",
    },
    {
      label: "SB Clicks Ratio",
      root: "sBClicksRatio",
      titleValue: "sBClicksRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "21",
    },

    {
      label: "SD Clicks Ratio",
      root: "sDClicksRatio",
      titleValue: "sDClicksRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "23",
    },
    {
      label: "STV Clicks Ratio",
      root: "sTVClicksRatio",
      titleValue: "sTVClicksRatio",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "24",
    },

    {
      label: "Average CR",
      root: "averageCR",
      titleValue: "averageCR",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "26",
    },
    {
      label: "Paid Clicks CR",
      root: "paidClicksCR",
      titleValue: "paidClicksCR",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "27",
    },
    {
      label: "External Clicks CR",
      root: "externalClicksCR",
      titleValue: "externalClicksCR",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "28",
    },
    {
      label: "Organic Clicks CR",
      root: "organicClicksCR",
      titleValue: "organicClicksCR",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "29",
    },
    {
      label: "USP",
      root: "uSP",
      titleValue: "uSP",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "36",
    },
    {
      label: "Click to sales",
      root: "clickToSales",
      titleValue: "clickToSales",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "37",
    },
    {
      label: "Browser Session Rate",
      root: "browserSessionRate",
      titleValue: "browserSessionRate",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "38",
    },
    {
      label: "Browser Page View Rate",
      root: "browserPageViewRate",
      titleValue: "browserPageViewRate",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "39",
    },
    {
      label: "Buy Box",
      root: "buyBox",
      titleValue: "buyBox",
      avg: "metric_average",
      min: "min_value",
      max: "max_value",
      sign: "%",
      chart: "metric_chart_data",
      id: "40",
    },
  ];

  const handleDateRangeChange = (value, dateString) => {
    const selectedPresetRange = presetRanges.filter((range) => {
      return (
        dayjs(range?.value?.[0]).format("YYYY-MMM-DD") === dateString?.[0] &&
        dayjs(range?.value?.[1]).format("YYYY-MMM-DD") === dateString?.[1]
      );
    });

    const date1 = new Date(dateString?.[0]);
    const date2 = new Date(dateString?.[1]);

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = date2 - date1;

    // Convert milliseconds to days
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    setSelectedRange(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    setSelectedRangeLine(
      selectedPresetRange?.length !== 0
        ? selectedPresetRange?.[0]?.label
        : "custom"
    );
    return selectedPresetRange?.length !== 0
      ? selectedPresetRange?.[0]?.label
      : "custom";
  };
  const updateChartOptions = (color) => {
    return {
      ...optionsComman,
      stroke: { ...optionsComman.stroke, colors: [color] },
      colors: [color],
      markers: {
        ...optionsComman.markers,
        strokeColors: color,
      },
      xaxis: {
        ...optionsComman?.xaxis,
        crosshairs: {
          ...optionsComman?.xaxis.crosshairs,
          stroke: {
            ...optionsComman?.xaxis.crosshairs.stroke,
            colors: [color],
          },
        },
      },
    };
  };

  const getOp = (value) => {
    switch (value) {
      case 0:
      case "STAGNANT":
        return updateChartOptions(yellow);
      case "DOWN":
        return updateChartOptions(red);
      case "UP":
        return updateChartOptions(green);
      default:
        return updateChartOptions(green); // or any default color
    }
  };

  const signFind = {
    salesData: "$",
    grossProfitData: "$",
    gpMarginData: "%",
    expensesData: "$",
    totalProfitData: "$",
    netMarginData: "%",
    refundData: "$",
  };
  const restart = true;

  const getArrowColor = (value) => {
    if (!value || value == 0 || value === "Flat") {
      return "rotateX(92deg)";
    }
    if (value === "FLAT") {
      return "rotateX(92deg)";
    }
    if (value === "Falling") {
      return "rotateX(180deg)";
    }
    if (value === "FALLING") {
      return "rotateX(180deg)";
    }
    if (value === "Rising") {
      return "rotateX(0deg)";
    }
    if (value === "RISING") {
      return "rotateX(0deg)";
    }
    return "rotateX(0deg)";
  };
  const getFooter = (value) => {
    if (value == 0 || value === "Flat") {
      return 2;
    }
    if (value === "FLAT") {
      return 2;
    }
    if (value === "Falling") {
      return 1;
    }
    if (value === "FALLING") {
      return 1;
    }
    if (value === "Rising") {
      return 3;
    }
    if (value === "RISING") {
      return 3;
    }
    return 3;
  };

  const graphDataFiltered =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.metric_value
    ) || [];
    const graphDataFilteredEma_value =
    overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
      (d) => d?.ema_value
    ) || [];
    
  const numberformat_ = (num) => {
    // Format number with no decimals
    return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
 

  const minGraph = Math.min(...([...graphDataFiltered, ...graphDataFilteredEma_value] || []));
  const maxGraph = Math.max(...([...graphDataFiltered, ...graphDataFilteredEma_value] || []));

  const revenu_status = {
    chart: {
      height: 355,

      type: "area",
      toolbar: { show: !1 },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    colors: [
      borderColor?.[
        getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
      ],
      "#000",
    ],
    markers: {
      size: 4,
      colors: "#FFF",
      strokeColors:
        borderColor?.[
          getBorderColor(overviewData?.[selectedCard?.root]?.ema_trend)
        ],
      hover: {
        size: 5,
      },
    },
    xaxis: {
      tickAmount: 7,
      offsetX: 10,
      labels: {
        rotateAlways: -45,
        style: {
          fontWeight: "bold",
        },
      },
    },
    dataLabels: { enabled: !1 },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [3, 3],
      dashArray: [0, 4],
      lineCap: "round",
    },

    labels:
      overviewData?.[selectedCard?.root]?.metric_chart_data?.map(
        (d) => d?.date || d?.weekNum || d?.weekDate || d?.chartDate
      ) || [],
    yaxis: [
      {
        seriesName: "",
        tickAmount: 7,
        axisTicks: {
          show: true,
        },
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
      {
        seriesName: "EMA",
        tickAmount: 7,
        opposite: true,
        min: minGraph == maxGraph ? 0 : minGraph,
        max: maxGraph === 0 ? 100 : maxGraph,
        axisTicks: {
          show: true,
        },

        title: {
          text: "Path",
          style: {
            fontWeight: 700,
            color: "#065ad8",
            fontSize: "1.15rem",
          },
        },
        labels: {
          formatter: function (_) {
            return (
              (selectedCard?.prefix || "") +
              numberformat_(_) +
              (selectedCard?.suffix || "")
            )?.replace(
              `${selectedCard?.prefix || ""}-`,
              `-${selectedCard?.prefix || ""}`
            );
          },
        },
      },
    ],
    grid: {
      strokeDashArray: 3,
      yaxis: { lines: { show: !1 } },
      xaxis: {
        lines: {
          show: true,
          offsetX: 0,
        },
      },
    },

    legend: { show: !0 },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
    tooltip: {
      marker: {
        show: true,
      },
      x: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const label = w?.globals?.categoryLabels?.[value - 1] || "";
          return `<strong>${label}</strong>`;
        },
      },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const prefix = selectedCard?.prefix || "";
          const suffix = selectedCard?.suffix || "";

          return signConvert(
            prefix +
              numberformat(parseFloat(parseFloat(value || 0).toFixed(2))) +
              suffix
          );
        },
      },
    },
  };

  const findYear = dayjs(filters?.start_date, "YYYY-MM-DD").format("YYYY");

  const findMonth = dayjs(filters?.start_date, "YYYY-MM-DD").format("MMM YY");
  const currentDate = dayjs(filters?.start_date, "YYYY-MM-DD");
  const lastMonth = currentDate.subtract(1, "month").format("MMM YY");
  const oneYearAgo = currentDate.subtract(1, "year").format("MMM YY");

  const findLastMonth = dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM YY"
  );
  const currentDate_ = dayjs(filters?.start_date, "YYYY-MM-DD");
  const findPrevMonth = currentDate_.subtract(1, "month").format("MMM YY");
  const oneYearAgo_ = currentDate_.subtract(1, "year").format("MMM YY");
  const previousYear = dayjs(filters?.start_date, "YYYY-MM-DD")
    .subtract(1, "year")
    .format("MMM YY");
  const selectedPeriod = `${dayjs(filters?.start_date, "YYYY-MM-DD").format(
    "MMM DD 'YY"
  )} - ${dayjs(filters?.end_date, "YYYY-MM-DD").format("MMM DD 'YY")}`;
  const columnsFilterTop = {
    This_Month: {
      [`${findMonth} Actual`]: "this_month",
      [`%`]: "avg_cr",
      [`${findMonth} Projected`]: "projected_month",
      // [`__`]: "projected_month_ratio",
      [lastMonth]: "last_month",
      // [`___`]: "last_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo]: "this_month_last_year",
      "YoY Variance": "tmly_variance",
      " Variance Pect": "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod} Page Views`]: "selected_range",
      [`%`]: "avg_cr",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      // [`__`]: "previous_period_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [`${findLastMonth} Page Views`]: "last_month",
      [`%`]: "avg_cr",
      [findPrevMonth]: "previous_month",
      // [`___`]: "previous_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo_]: "last_month_last_year",

      "YoY Variance": "lmly_variance",
      "Variance Pect": "lmly_variance_pect",
    },
    Yesterday: {
      [`Yesterday Page Views`]: "yesterday",
      [`%`]: "avg_cr",
      "Day Before Yesterday ": "day_before_yesterday",
      // [`__`]: "day_before_yesterday_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",

      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      [`This Week Actual Page Views`]: "this_week",
      [`%`]: "avg_cr",
      "This Week Projected ": "projected_week",
      // [`__`]: "projected_week_ratio",
      "Last Week": "last_week",
      // [`___`]: "last_week_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      "This Quarter Actual Page Views": "this_quarter",
      [`%`]: "avg_cr",
      "This Quarter Projected": "projected_quarter",
      // [`__`]: "projected_quarter_ratio",
      "Last Quarter": "last_quarter",
      // [`___`]: "last_quarter_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1 Page Views`]: "last_year_q1",
      [`%`]: "avg_cr",
      [`${findYear} Q2`]: "last_year_q2",
      // [`__`]: "last_year_q2_ratio",
      [`${findYear} Q3`]: "last_year_q3",
      // [`___`]: "last_year_q3_ratio",
      [`${findYear} Q4`]: "last_year_q4",
      // [`____`]: "last_year_q4_ratio",
      Total: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1 Page Views`]: "this_year_q1",
      [`%`]: "avg_cr",
      [`${findYear} Q2`]: "this_year_q2",
      // [`__`]: "this_year_q2_ratio",
      [`${findYear} Q3`]: "this_year_q3",
      // [`___`]: "this_year_q3_ratio",
      [`${findYear} Q4`]: "this_year_q4",
      // [`___`]: "this_year_q4_ratio",
      [`${findYear} Total `]: "this_year_total",
      [`${previousYear} Total`]: "last_year_total",
      [`This Year Projected `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };
  const columnsFilter = {
    This_Month: {
      [`${findMonth} Actual`]: "this_month",
      [`_`]: "this_month_ratio",
      [`${findMonth} Projected`]: "projected_month",
      [`__`]: "projected_month_ratio",
      [lastMonth]: "last_month",
      [`___`]: "last_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo]: "this_month_last_year",
      "YoY Variance": "tmly_variance",
      " Variance Pect": "tmly_variance_pect",
    },
    custom: {
      [`${selectedPeriod}`]: "selected_range",
      [`_`]: "selected_range_ratio",
      [`${moment(
        updatePrevDates({
          endDate: filters?.end_date,
          startDate: filters?.start_date,
        })
      ).format("MMM DD 'YY")} - ${dayjs(filters?.start_date, "YYYY-MM-DD")
        .subtract(1, "day")
        .format("MMM DD 'YY")}`]: "previous_period",
      [`__`]: "previous_period_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
    },
    Last_Month: {
      [findLastMonth]: "last_month",
      [`_`]: "last_month_ratio",
      [findPrevMonth]: "previous_month",
      [`___`]: "previous_month_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      [oneYearAgo_]: "last_month_last_year",

      "YoY Variance": "lmly_variance",
      "Variance Pect": "lmly_variance_pect",
    },
    Yesterday: {
      Yesterday: "yesterday",
      [`_`]: "yesterday_ratio",
      "Day Before Yesterday ": "day_before_yesterday",
      [`__`]: "day_before_yesterday_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Yesterday Last Year": "yesterday_last_year",

      "YoY Variance": "yly_variance",
      " Variance Pect": "yly_variance_pect",
    },
    This_Week: {
      "This Week Actual": "this_week",
      [`_`]: "this_week_ratio",
      "This Week Projected ": "projected_week",
      [`__`]: "projected_week_ratio",
      "Last Week": "last_week",
      [`___`]: "last_week_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "Last Year This Week": "last_year_this_week",
      "YoY Variance": "lytw_variance",
      " Variance Pect": "lytw_variance_pect",
    },
    This_Quarter: {
      "This Quarter Actual": "this_quarter",
      [`_`]: "this_quarter_ratio",
      "This Quarter Projected": "projected_quarter",
      [`__`]: "projected_quarter_ratio",
      "Last Quarter": "last_quarter",
      [`___`]: "last_quarter_ratio",
      Variance: "variance",
      "Variance Pect": "variance_pect",
      "This Quarter Last Year": "this_quarter_last_year",
      "YoY Variance": "tqly_variance",
      " Variance Pect": "tqly_variance_pect",
    },
    Last_Year: {
      [`${findYear} Q1`]: "last_year_q1",
      [`_`]: "last_year_q1_ratio",
      [`${findYear} Q2`]: "last_year_q2",
      [`__`]: "last_year_q2_ratio",
      [`${findYear} Q3`]: "last_year_q3",
      [`___`]: "last_year_q3_ratio",
      [`${findYear} Q4`]: "last_year_q4",
      [`____`]: "last_year_q4_ratio",
      Total: "last_year_total",
    },
    This_Year: {
      [`${findYear} Q1`]: "this_year_q1",
      [`_`]: "this_year_q1_ratio",
      [`${findYear} Q2`]: "this_year_q2",
      [`__`]: "this_year_q2_ratio",
      [`${findYear} Q3`]: "this_year_q3",
      [`___`]: "this_year_q3_ratio",
      [`${findYear} Q4`]: "this_year_q4",
      [`___`]: "this_year_q4_ratio",
      [`${findYear} Total `]: "this_year_total",
      [`${previousYear} Total`]: "last_year_total",
      [`This Year Projected `]: "projected_year",
      [`Variance`]: "variance",
      [`Variance Pect`]: "variance_pect",
    },
  };

  const errorIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2927 5.49996C12.7119 5.49996 12.945 5.98493 12.6831 6.31231L8.39011 11.6786C8.18995 11.9288 7.8094 11.9288 7.60924 11.6786L3.31622 6.31231C3.05431 5.98493 3.2874 5.49996 3.70665 5.49996L12.2927 5.49996Z"
        fill="#E31715"
      />
    </svg>
  );

  const successIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70633 11.5C3.28708 11.5 3.05399 11.015 3.31589 10.6877L7.60891 5.32138C7.80908 5.07117 8.18962 5.07117 8.38978 5.32138L12.6828 10.6877C12.9447 11.015 12.7116 11.5 12.2924 11.5L3.70633 11.5Z"
        fill="#00B55D"
      />
    </svg>
  );

  const getValueShow = (value) => {
    try {
      if (value == 0) {
        return <b style={{ color: "#000" }}>{value}%</b>;
      } else if (parseFloat(value?.split(",")?.join("")) < 0) {
        return (
          <b style={{ color: "red" }}>
            {errorIcon}
            {value}%
          </b>
        );
      }
      return (
        <b style={{ color: "green" }}>
          {successIcon}
          {value}%
        </b>
      );
    } catch (error) {}
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const icons = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={16}
      viewBox="0 0 15 16"
      fill="none"
      style={{
        position: "relative",
        top: "-4px",
        left: "0.5px",
      }}
    >
      <path
        d="M3.75 11.125L6.875 8L3.75 4.875M8.125 11.125L11.25 8L8.125 4.875"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const typeGraph = {
    average_cr: "line",
    sponsored_brands_cr: "line",
    sponsored_display_cr: "line",
    sponsored_products_cr: "line",
    sponsored_television_cr: "line",

    average_paid_traffic_cr: "bar",
    external_clicks_cr: "bar",
    organic_clicks_cr: "bar",
    paid_clicks_cr: "bar",

    external_clicks: "bar",
    organic_clicks: "bar",
    paid_clicks: "bar",
    sponsored_brands: "bar",
    sponsored_display: "bar",
    sponsored_products: "bar",
    sponsored_television: "bar",
    total_clicks: "bar",
    total_paid_clicks: "bar",
  };

  const colorList = [
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
  ];

  const findSign = {
    average_cr: "%",
    average_paid_traffic_cr: "",
    external_clicks: "",
    external_clicks_cr: "",
    organic_clicks: "",
    organic_clicks_cr: "",
    paid_clicks: "",
    paid_clicks_cr: "",
    sponsored_brands: "",
    sponsored_brands_cr: "%",
    sponsored_display: "",
    sponsored_display_cr: "%",
    sponsored_products: "",
    sponsored_products_cr: "%",
    sponsored_television: "",
    sponsored_television_cr: "%",
    total_clicks: "",
    total_paid_clicks: "",
  };

  const series = Object?.entries(graphData?.chartData || {})?.map(
    ([key, value], i) => {
      return {
        name: graphLabels?.[key],
        type: typeGraph?.[key],
        key: key,
        data: value?.map((d) => parseFloat(d)),
        color: colorList?.map((r) => r?.key)?.[i],
        yAxis: typeGraph?.[key] === "line" ? 1 : 0,
        sign: findSign?.[key],
        suffix: findSign?.[key] === "%" ? "%" : "",
        // tooltip: {
        // },
      };
    }
  );
  const [cardList, setCardList] = useState([]);

  const getMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting?menu_item_id=4`,
      "get",
      null,
      true
    );

    if (response?.status) {
      const cardList__ = Object.entries(data || {})?.reduce((acc, [d, v]) => {
        return [
          ...acc,
          {
            label: v?.metric_name,
            root: d,
            sign: v?.metric_unit || "",
            id: v?.metric_id,
          },
        ];
      }, []);

      if (response?.data.length === 0) {
        setCardList(cardList__.slice(0, 8));
      } else {
        const metricId = response?.data
          .filter(
            (d) =>
              parseInt(d?.status) === 1 &&
              parseInt(d?.master_metric_status) === 1
          )
          .map((d) => parseInt(d?.metric_id));
        const obj = cardList__.filter((item) => metricId.includes(item.id));

        setCardList(obj);

        setSelectedCard([obj?.[0]?.root, obj?.[0]?.label, obj?.[0]?.sign]);
        setOverviewLoading(false);
      }
      setMetricSettingList(response?.data);

      setMetricSettingLoading(false);
      setOverviewLoading(false);
    } else {
      setOverviewLoading(false);
      setMetricSettingLoading(false);
      message.warning(response?.message);
    }
  };
  const saveMetricSetting = async (data) => {
    setMetricSettingLoading(true);
    const response = await MakeApiCall(
      `metric-setting/save`,
      "post",
      data,
      true
    );

    if (response?.status) {
      getOverview();
      setViewConfig(false);
    } else {
      getOverview();

      message.warning(response?.message);
    }
  };
  const getOverview = async (data) => {
    setOverviewLoading(true);
    getMetricSetting();
    setOverviewLoading(true);
    const response = await MakeApiCall(
      `funnel/get-funnel-overview?filter_date=${
        data || overviewFilter || ""
      }&menu_item_id=4`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (Object.keys(response?.data || {})?.length === 0) {
        setOverviewData({});
        setCardList([]);
        setSelectedCard({});
        setOverviewLoading(false);
        setMetricGraphLoading(false);
        return;
      }

      const cardList__ = Object.entries(response?.data || {})?.reduce(
        (acc, [d, v]) => {
          return [
            ...acc,
            {
              label: v?.metric_name,
              root: d,
              id: v?.metric_id,
              prefix:
                v?.metric_unit === "Range$" || v?.metric_unit === "$"
                  ? "$"
                  : "",
              suffix:
                v?.metric_unit === "Range%" || v?.metric_unit === "%"
                  ? "%"
                  : "",
            },
          ];
        },
        []
      );

      setCardList(cardList__);
      setSelectedCard({
        ...cardList__?.[0],
      });
      setMetricGraphLoading(false);
      setOverviewData(response?.data);
      setOverviewLoading(false);
    } else {
      setOverviewData({});
      setCardList([]);
      setSelectedCard({});
      setOverviewLoading(false);
      setMetricGraphLoading(false);
      message.warning(response?.message);
    }
  };

  const showMetrix = () => {
    if (metricSettingList?.length === 0) {
      message.destroy();
      return message.warning("Config List is not available");
    }
    setViewConfig(true);
  };
  useEffect(() => {
    getOverview();

    return () => {};
  }, []);

  const ratioView = (key) => {
    return (
      key === "_" ||
      key === "__" ||
      key === "___" ||
      key === "____" ||
      key === "Avg CR"
    );
  };

  const getDates = (YYYY, MM) => {
    return {
      start_date: `${YYYY}-${MM}-01`,
      end_date: `${YYYY}-${MM}-08`,
    };
  };

  const getMonth = (YYYY, MM) => {
    // Get current date
    var today = new Date(`${YYYY}-${MM}-01`);

    // Get the first day of the month
    var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Get the last day of the month
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    // Format the dates as strings (YYYY-MM-DD)

    return {
      start_date: moment(firstDayOfMonth).format("YYYY-MM-DD"),
      end_date: moment(lastDayOfMonth).format("YYYY-MM-DD"),
    };
  };

  const { lws, sp } = contextValue?.data?.credentialsStatus;
  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "funnel-360")?.is_restricted === 1
  ) {
    return <CredentialsError type="traffic-360" />;
  }

  if (Object.keys(selectedRow || {})?.length !== 0) {
    return (
      <LineItemsSales
        close={() => setSelectedRow(false)}
        selectedRow={selectedRow}
        getAllLineItemList={getAllLineItemList}
        loading={lineItemListLoading}
        selectedMetric={selectedMetric}
        APIAll={getAllLineItemsList}
        setFiltersLine={setFiltersLine}
        filtersLine={filtersLine}
        selectedRangeLine={selectedRangeLine}
        setSelectedRangeLine={setSelectedRangeLine}
        endPoint={"funnel/get-line-items"}
        summaryType={summaryType}
      />
    );
  }

  return (
    <Wrapper id="kt_content_container" className="container-fluid mt-2">
      <div className="row">
        <div className="header-title-page">
          <div className="d-flex align-items-center title-text">
            <h2 className="mb-0">{pageTitle}</h2>{" "}
            <InfoVideo className={"ms-3"} />
          </div>
        </div>
      </div>
      <DatePicker
        value={dayjs(overviewFilter, "YYYY-MM-DD")}
        className="custom-date"
        style={{ width: "140px" }}
        format={"YYYY-MMM-DD"}
        // picker="week"
        disabledDate={(current) => {
          return current && current >= moment().startOf("day");
        }}
        allowClear={false}
        onChange={(e) => {
          getOverview(dayjs(e).format("YYYY-MM-DD"));
          setOverviewFilter(dayjs(e).format("YYYY-MM-DD"));
        }}
      />
      <div
        style={{
          justifyContent: viewConfig ? "flex-start" : "space-between",
        }}
        className="mb-6 top-bar-page-header mt-4"
      >
        <div className="page-header-info">
          <div className="discription-text">
            Funnel 360 Metrics and Trends for{" "}
            {dayjs(overviewFilter, "YYYY-MM-DD").format("MM/DD/YYYY")}
          </div>
        </div>

        <div
          style={{
            justifyContent: "flex-start",
            alignItems: "flex-start",
            gap: 20,
            display: "flex",
          }}
        >
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#00B55D",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#00B55D",
                fontSize: 14,

                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Growth
            </div>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#FDC71E",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#FDC71E",
                fontSize: 14,

                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Stagnant
            </div>
          </div>
          <div
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 6,
              display: "flex",
            }}
          >
            <div
              style={{
                width: 13,
                height: 13,
                background: "#E31715",
                borderRadius: 9999,
              }}
            />
            <div
              style={{
                color: "#E31715",
                fontSize: 14,

                fontWeight: "700",

                wordWrap: "break-word",
              }}
            >
              Decline
            </div>
          </div>
        </div>
      </div>

      <div
        className="row g-5 g-xl-5 Financial-cus-row"
        style={{
          zoom: "90%",
        }}
      >
        <div className=" col-xl-8 mb-5 mb-xl-5 mh-450px overflow-auto">
          <CardsView
            cardList={cardList}
            type="D"
            setSelectedCard={setSelectedCard}
            backgroundColor={backgroundColor}
            getBorderColor={getBorderColor}
            overviewData={overviewData}
            borderColor={borderColor}
            signConvert={signConvert}
            getOp={getOp}
            getFooter={getFooter}
            getArrowColor={getArrowColor}
            selectedCard={selectedCard}
            overviewLoading={overviewLoading}
            setCardList={setCardList}
            setMetricGraphLoading={setMetricGraphLoading}
          />
        </div>
        {GraphMetric(
          selectedCard,
          borderColor,
          getBorderColor,
          overviewData,
          signFind,
          restart,
          revenu_status,
          getArrowColor,
          getFooter,
          showMetrix,
          overviewLoading,
          metricGraphLoading
        )}
      </div>
      <div className="row g-5 g-xl-5 mb-5">
        <div className="col-xxl-6 mt-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header">
              <div className="card-title">Page Views</div>
            </div>

            <div className="card-body py-9">
              {tabelLoading ? (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Sales360Pie
                  sign={false}
                  idName="Sales_Overview_traffic_paid"
                  data={convertedData?.traffic?.map((d) => ({
                    category: d?.type
                      ?.split("_")
                      ?.join(" ")
                      .replace(/\b\w/g, (c) => c.toUpperCase()),
                    value: parseFloat(
                      d?.[
                        Object?.values(
                          columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                        )?.[0]
                      ]
                        ?.split(",")
                        ?.join("")
                    ),
                  }))}
                />
              )}
            </div>
          </div>
        </div>

        <div className="col-xl-6 mt-5">
          <div className="card card-flush h-xl-100">
            <div className="card-header">
              <div className="card-title">Paid Page Views</div>
            </div>
            <div className="card-body py-9">
              {tabelLoading ? (
                <div
                  style={{
                    height: 200,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Sales360Pie
                  sign={false}
                  idName="Paid_Sales"
                  data={convertedData?.paid_traffic?.map((d) => ({
                    category: d?.type
                      ?.split("_")
                      ?.join(" ")
                      .replace(/\b\w/g, (c) => c.toUpperCase()),
                    value: parseFloat(
                      d?.[
                        Object?.values(
                          columnsFilter?.[selectedRange?.split(" ")?.join("_")]
                        )?.[0]
                      ]
                        ?.split(",")
                        ?.join("")
                    ),
                  }))}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card card-flush h-xl-100">
          <div className="card-header">
            <div className="card-title">
              <b>Funnel 360</b>
            </div>
            <div className="card-toolbar">
              <Tag color="blue-inverse" className="me-2">
                {selectedRange === "custom" ? "Selected period" : selectedRange}
              </Tag>
              <DatePicker.RangePicker
                presets={presetRanges}
                size="middle"
                style={{
                  width: "280px",
                  border: "1px solid #D7D7D7",
                }}
                format={"YYYY-MMM-DD"}
                value={
                  filters?.start_date && filters?.end_date
                    ? [
                        dayjs(filters?.start_date, "YYYY-MM-DD"),
                        dayjs(filters?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                disabledDate={(current) => {
                  // Disable future dates
                  return current && current > moment().endOf("day");
                }}
                onChange={(e, _) => {
                  const obj = {
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };
                  getList({
                    ...obj,
                    selection_type: handleDateRangeChange(e, _)?.toLowerCase(),
                  });
                  setFilters(obj);
                  setFiltersLine(obj);
                }}
              />
            </div>
          </div>
          <div className="card-body pt-0">
            {tabelLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div className="table-responsive">
                <table className=" align-middle table-row-dashed fs-6 gy-3 dataTable no-footer table-fixed">
                  <thead>
                    <tr>
                      <th className="pe-3 py-5 fixed-column" />
                      {Object?.entries(
                        columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        return (
                          <th
                            key={i}
                            style={{
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                            className={` text-end  pe-3 py-3 ${key}`}
                          >
                            {key?.includes("Pect") ? " " : key}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="d-flex align-items-center fixed-column justify-content-start"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Page Views
                        </h4>
                        <InfoVideo videoShow={false} className={"ms-3"} />
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>

                    {convertedData?.traffic?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            // getAllLineItemsList({
                            //   metric_name: convertedData?.traffic?.map(
                            //     (r) => r.type
                            //   ),
                            // });

                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "source",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>{titles?.[d?.type]}</span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilterTop?.[
                            selectedRange?.split(" ")?.join("_")
                          ]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] === "NaN" || d?.[value] == 0
                                  ? "-"
                                  : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "-")
                                : signConvert(`${d?.[value]}`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}

                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          setSelectedRow({
                            type: "total_clicks",
                            columns: "paid",
                            filterType: "source",
                          });
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>{titles?.["total_clicks"]}</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {convertedData?.total_traffic?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  convertedData?.total_traffic?.[value] || "0"
                                )
                              : signConvert(
                                  `${convertedData?.total_traffic?.[value]}`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="d-flex align-items-center fixed-column justify-content-start"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Paid Page Views
                        </h4>
                        <InfoVideo videoShow={false} className={"ms-3"} />
                      </td>
                      <td colSpan={9}></td>
                    </tr>

                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>

                    {convertedData?.paid_traffic?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            // setSelectedRow({ type: d?.type, columns: "paid" });
                            getAllLineItemsList({
                              metric_name: convertedData?.paid_traffic?.map(
                                (r) => r.type
                              ),
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "source",
                            });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>{titles?.[d?.type]}</span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilterTop?.[
                            selectedRange?.split(" ")?.join("_")
                          ]
                        )?.map(([key, value], i) => {
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] == 0 ? "-" : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "-")
                                : signConvert(`${d?.[value]}`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          setSelectedRow({
                            type: "total_paid_clicks",
                            columns: "paid",
                            filterType: "source",
                          });
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>{titles?.["total_paid_clicks"]}</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {convertedData?.total_paid_clicks?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  convertedData?.total_paid_clicks?.[value] ||
                                    "0"
                                )
                              : signConvert(
                                  `${convertedData?.total_paid_clicks?.[value]}`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    {/* /////------/////// */}
                    <tr>
                      <td className="p-3" colSpan={9} />
                    </tr>

                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="d-flex align-items-center fixed-column justify-content-start"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          <b>Conversion Rate (CR)</b>
                        </h4>
                        <InfoVideo videoShow={false} className={"ms-3"} />
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    {convertedData?.traffic_conversion_cr?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name:
                                convertedData?.traffic_conversion_cr?.map(
                                  (r) => r.type
                                ),
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "source",
                            });
                            // setSelectedRow({ type: d?.type, columns: "sales" });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>{titles?.[d?.type]}</span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilterTop?.[
                            selectedRange?.split(" ")?.join("_")
                          ]
                        )?.map(([key, value], i) => {
                          if (key === "Avg CR")
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              ></td>
                            );
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] === "NaN" || d?.[value] == 0
                                  ? "-"
                                  : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] === "NaN" || d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "-")
                                : signConvert(`${d?.[value]}%`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                        onClick={() => {
                          setSelectedRow({
                            type: "average_cr",
                            columns: "paid",
                            filterType: "source",
                          });
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>{titles?.["average_cr"]}</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>

                      {Object?.entries(
                        columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (key === "Avg CR")
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "none" }}
                            ></td>
                          );
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {convertedData?.average_cr?.[value] === "NaN" ||
                            convertedData?.average_cr?.[value] == 0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  convertedData?.average_cr?.[value] || "0"
                                )
                              : signConvert(
                                  `${convertedData?.average_cr?.[value]}%`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        style={{ background: "transparent" }}
                        className="fixed-column"
                      >
                        <h4
                          style={{
                            marginBottom: "0px",
                            color: "#515151",
                            fontWeight: 500,
                          }}
                        >
                          Paid Page Views Conversion Rate
                        </h4>
                      </td>
                      <td colSpan={9}></td>
                    </tr>
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>

                    {convertedData?.paid_traffic_cr?.map((d, i) => (
                      <tr key={i}>
                        <td
                          onClick={() => {
                            getAllLineItemsList({
                              metric_name: convertedData?.paid_traffic_cr?.map(
                                (r) => r.type
                              ),
                            });
                            setSelectedRow({
                              type: d?.type,
                              columns: "paid",
                              filterType: "source",
                            });
                            // setSelectedRow({ type: d?.type, columns: "paid" });
                          }}
                          className="text-end text-hover-primary cursor-pointer d-flex justify-content-end fixed-column "
                          style={{ borderBottom: "1px solid #E5E5E5" }}
                        >
                          <div className="d-flex align-items-center">
                            <span>{titles?.[d?.type]} </span>
                            <div
                              className="bg-primary ms-2"
                              style={{
                                borderRadius: "100%",
                                postion: "relative",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              {icons}
                            </div>
                          </div>
                        </td>
                        {Object?.entries(
                          columnsFilterTop?.[
                            selectedRange?.split(" ")?.join("_")
                          ]
                        )?.map(([key, value], i) => {
                          if (key === "Avg CR")
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              ></td>
                            );
                          if (ratioView(key)) {
                            return (
                              <td
                                key={i}
                                className="text-end   pe-3"
                                style={{ borderBottom: "none" }}
                              >
                                {d?.[value] === "NaN" || d?.[value] == 0
                                  ? "-"
                                  : `${d?.[value] || 0}%`}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "1px solid #E5E5E5" }}
                            >
                              {d?.[value] === "NaN" || d?.[value] == 0
                                ? "-"
                                : key?.includes("Pect")
                                ? getValueShow(d?.[value] || "-")
                                : signConvert(`${d?.[value]}%`)}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr>
                      <td className="p-1" colSpan={9} />
                    </tr>
                    <tr>
                      <td
                        onClick={() => {
                          setSelectedRow({
                            type: "average_paid_traffic_cr",
                            columns: "paid",
                            filterType: "source",
                          });
                        }}
                        className="text-start fixed-column text-hover-primary cursor-pointer d-flex justify-content-start"
                        style={{
                          background: "#E4F3FF",
                          padding: "5px 10px 5px 10px",
                          color: "#015986",
                          fontSize: "14px",
                          fontWeight: 500,
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <span>{titles?.["average_paid_traffic_cr"]}</span>
                          <div
                            className="bg-primary ms-2"
                            style={{
                              borderRadius: "100%",
                              postion: "relative",
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            {icons}
                          </div>
                        </div>
                      </td>
                      {Object?.entries(
                        columnsFilterTop?.[selectedRange?.split(" ")?.join("_")]
                      )?.map(([key, value], i) => {
                        if (key === "Avg CR")
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{ borderBottom: "none" }}
                            ></td>
                          );
                        if (ratioView(key)) {
                          return (
                            <td
                              key={i}
                              className="text-end   pe-3"
                              style={{
                                background: "#E4F3FF",
                                padding: "5px 10px 5px 10px",
                                color: "#015986",
                                fontWeight: 500,
                              }}
                            ></td>
                          );
                        }
                        return (
                          <td
                            key={i}
                            className="text-end   pe-3"
                            style={{
                              background: "#E4F3FF",
                              padding: "5px 10px 5px 10px",
                              color: "#015986",
                              fontWeight: 500,
                            }}
                          >
                            {convertedData?.average_paid_traffic_cr?.[value] ==
                            0
                              ? "-"
                              : key?.includes("Pect")
                              ? getValueShow(
                                  convertedData?.average_paid_traffic_cr?.[
                                    value
                                  ] || "0"
                                )
                              : signConvert(
                                  `${convertedData?.average_paid_traffic_cr?.[value]}%`
                                )}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {Graph(
        series,
        graphLoading,
        selectedGraph,
        setSelectedGraph,
        graphData,
        graphLabels
      )}
      {PremiumContent({
        contextValue: contextValue,
        PCAUpdate: (e, _) => PCAUpdate(e, _),
      })}

      {contextValue?.data?.user_?.pca_connected !== 1 ? (
        <Watermark content="Sample">
          <img src={`/assets/media/funnel360.png`} style={{ width: "100%" }} />
        </Watermark>
      ) : (
        <>
          <Tabs
            activeKey={selectedTabFilter}
            onChange={(e) => {
              const Date = moment(summaryDates?.start_date, "YYYY-MM-DD");

              const { start_date, end_date } = {
                ...getMonth(Date?.format("YYYY"), Date?.format("MM")),
              };
              if (e === "2") {
                const { start_date, end_date } = {
                  ...getDates(Date?.format("YYYY"), Date?.format("MM")),
                };
                setSummaryDates({
                  start_date,
                  end_date,
                });
              } else {
                const Date = moment(summaryDates?.start_date, "YYYY-MM-DD");
                const { start_date, end_date } = {
                  ...getMonth(Date?.format("YYYY"), Date?.format("MM")),
                };
                setSummaryDates({
                  start_date,
                  end_date,
                });
              }
              setSelectedTabFilter(e);
            }}
            items={[
              {
                key: "1",
                label: "Month",
                children: null,
              },
              {
                key: "2",
                label: "Week",
                children: null,
              },
            ]}
          />
          <TrafficTables
            selectedTabFilter={selectedTabFilter}
            setSelectedRow={setSelectedRow}
            getValueShow={getValueShow}
            summaryDates={summaryDates}
            setSummaryDates={setSummaryDates}
            convertData={convertData}
            icons={icons}
            GetLineItems={(d) => {
              getAllLineItemsList({
                metric_name: d?.metric_name,
                summary_type: d?.summary_type,
                start_date: d?.start_date,
                end_date: d?.end_date,
              });
              setFiltersLine({
                start_date: d?.start_date,
                end_date: d?.end_date,
              });

              setSelectedRow({
                type: d?.type,
                columns: d?.columns,
                filterType: d?.filterType,
              });
            }}
          />
        </>
      )}

      {viewConfig && (
        <Config
          metricSettingLoading={metricSettingLoading}
          metricSettingList={metricSettingList}
          onClose={() => setViewConfig(false)}
          onSave={saveMetricSetting}
          open={viewConfig}
          data={[]}
        />
      )}
      <PlayView visible={visible} setVisible={setVisible} />
    </Wrapper>
  );
};

export default Traffic360;
