import Icons from "../../../components/icons";
import { GetLinks } from "../../../config";

export default [
  {
    key: "/request-support",
    icon: <i className="ki-outline ki-frame fs-1 " />,
    label: GetLinks("/request-support", "Request Support"),
  },
];
