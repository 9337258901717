import moment from "moment";
import { Link } from "react-router-dom";
export const ORIGIN = "https://dev-api.app-sip.com/"; // api.app-sip.com
export const ENDPOINT = `${ORIGIN}api/v1/`; // api.app-sip.com
export const imageURL = `https://dev-api.app-sip.com/images/user-images/`;
export const LOGINURL = localStorage.getItem("root") || "/seller/login";
export const GetLinks = (path, label) => {
  return (
    <Link
      id={Math.random()}
      to={{
        pathname: path,
      }}
      title={label}
    >
      {label}
    </Link>
  );
};

export const configModal = (title, message) => ({
  title: title || "",
  center: true,
  content: (
    <>
      <span>{message || ""} </span>
    </>
  ),
});
export const numberformat = (value) => {
  return parseFloat(value || 0).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
export const PropsFilter = (type) =>
  !type
    ? {}
    : {
        sorter: (a, b) => {
          return typeof a?.[type] === "string"
            ? (a?.[type] || "")?.localeCompare(b?.[type] || "")
            : parseFloat(a?.[type] || 0) - parseFloat(b?.[type] || 0);
        },
      };
export const NumberWithCommas = (n, sign) => {
  if (n == 0) return "-";
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return `${parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    }
    return `${sign || ""}${parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`?.replace(
      `${sign || ""}-`,
      `-${sign || ""}`
    );
  }
  return `${sign || ""}${num_ || 0}`?.replace(
    `${sign || ""}-`,
    `-${sign || ""}`
  );
};

function getDateDifference(startDateStr, endDateStr) {
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  // Calculate the difference in milliseconds
  const differenceInMs = endDate - startDate;

  // Convert milliseconds to days (1000 milliseconds/second, 60 seconds/minute, 60 minutes/hour, 24 hours/day)
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  return differenceInDays;
}

function subtractDays(dateStr, days) {
  // Parse the date string
  const date = new Date(dateStr);

  // Subtract the days
  date.setDate(date.getDate() - days - 1);

  // Format the date back to YYYY-MM-DD
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2); // Months are 0-indexed, add 1 to get the correct month
  const day = `0${date.getDate()}`.slice(-2);

  return `${year}-${month}-${day}`;
}
export const updatePrevDates = (picker) => {
  return subtractDays(
    picker?.startDate,
    getDateDifference(picker?.startDate, picker.endDate)
  );
};
export const config = {
  3: {
    title: "Dashboard",
    1: "info",
    2: "video",
  },
  4: {
    title: "Financial",
    1: "info",
    2: "video",
  },
  5: {
    title: "Sales-360",
    1: "info",
    2: "video",
  },
  6: {
    title: "Traffic-360",
    1: "info",
    2: "video",
  },
};
export const DeleteIcon = (
  <svg
    width={23}
    height={23}
    style={{
      position: "relative",
      top: "1px",
    }}
    className="text-danger"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.5 3h-5l-1 1H5v2h14V4h-3.5l-1-1Z" />
    <path d="M6 7h12v12c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V7Z" />
    <path d="M10 10h4v2h-4v-2Z" />
    <path d="M10 13h4v2h-4v-2Z" />
    <path d="M10 16h4v2h-4v-2Z" />
  </svg>
);

export const EditIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="mdi-table-edit"
    viewBox="0 0 24 24"
    version="1.1"
    x="0px"
    width={25}
    style={{
      position: "relative",
      top: "2px",
    }}
    y="0px"
    enableBackground="new 0 0 24 24"
    xmlSpace="preserve"
  >
    <path
      fill="#36bea6"
      d="M21.7,13.35L20.7,14.35L18.65,12.3L19.65,11.3C19.86,11.08 20.21,11.08 20.42,11.3L21.7,12.58C21.92,12.79 21.92,13.14 21.7,13.35M12,18.94L18.07,12.88L20.12,14.93L14.06,21H12V18.94M4,2H18A2,2 0 0,1 20,4V8.17L16.17,12H12V16.17L10.17,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,6V10H10V6H4M12,6V10H18V6H12M4,12V16H10V12H4Z"
    />
  </svg>
);
export const ViewIcon = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width={18}
    style={{
      position: "relative",
      top: "3px",
    }}
    viewBox="0 0 47 48.8"
    enableBackground="new 0 0 47 48.8"
    xmlSpace="preserve"
  >
    <path fill="#029ffb" d="M0,0v48.8h47V0H0z M41.7,44H5.3V13.2h36.4V44z" />
  </svg>
);
export const RenderTable = (props, row, index, d, width) =>
  typeof d?.render === "function" ? (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        {d?.render(props, row, index)}
      </div>
    </>
  ) : (
    <>
      <div
        className="d-flex"
        style={{
          flexDirection: d?.align === "right" ? "row-reverse" : "row",
          minWidth: d?.title?.length * (10 + (width || 0)),
        }}
      >
        <span>{props || "-"}</span>
      </div>
    </>
  );
export const ConvertParams = (values) => {
  return Object.entries(Object.entries(values))
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};
