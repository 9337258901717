import { Badge } from "antd";
import { GetLinks } from "../../../config";

export default [
  {
    key: "9",
    icon: <i className="ki-outline ki-gear fs-1 " />,
    label: (
      <>
        Settings <Badge className="ms-2" count={4} showZero color="#01c0c8" />
      </>
    ),

    children: [
      {
        key: "/setting/profile",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-profile-user fs-1" />
          </span>
        ),
        label: GetLinks("/setting/profile", "My Profile"),
      },
      {
        key: "/setting/seller-profile",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-user-tick fs-1" />
          </span>
        ),
        label: GetLinks("/setting/seller-profile", "Seller Profile"),
      },
      {
        key: "/setting/spapi-callback",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-fasten fs-1" />
          </span>
        ),
        label: GetLinks("/setting/spapi-callback", "Connections"),
      },
      {
        key: "/setting/system-parameters",
        icon: (
          <span className="menu-bullet">
            <i className="ki-outline ki-screen fs-1" />
          </span>
        ),
        label: GetLinks("/setting/system-parameters", "System Parameters"),
      },
    ],
  },
];
