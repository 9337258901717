import { Editor } from "@tinymce/tinymce-react";
import { message, Spin } from "antd";
import React, { Suspense, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { MakeApiCall } from "../../../../apis";

const Edit = ({ close, pageType, id, updateRow }) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [loading, setLoading] = useState(true);

  const getRecord = async () => {
    const response = await MakeApiCall(
      `seller-resource/${id}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSelectedRow(response?.data || {});
      setLoading(false);
    } else {
      setSelectedRow({});
      setLoading(false);
    }
  };

  useEffect(() => {
    getRecord();
    setLoading(true);
    return () => {};
  }, []);

  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      message.destroy();
      message.loading("Loading...", 0);

      updateRow({
        title: selectedRow?.title,
        description: editorRef.current.getContent(),
      });
    }
  };

  return (
    <Spin tip="Loading..." spinning={loading}>
      <div id="kt_content_container" className="">
        <div className="row g-2">
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="row g-9 mb-5 align-items-center">
                      <div className="col-md-3  fv-row text-end">
                        <label className=" fs-6 fw-bold mb-2">ID</label>
                      </div>
                      <div className="col-md-9  fv-row">
                        <input
                          type="text"
                          className="form-control"
                          value={selectedRow?.id}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row g-9 mb-5 align-items-center">
                      <div className="col-md-3  fv-row text-end">
                        <label className=" fs-6 fw-bold mb-2">Title</label>
                      </div>
                      <div className="col-md-9  fv-row">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            setSelectedRow({
                              ...selectedRow,
                              title: e.target.value,
                            });
                          }}
                          value={selectedRow?.title}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="row g-9 mb-5 align-items-start">
                      <div className="col-md-3  fv-row text-end">
                        <label className=" fs-6 fw-bold mb-2">
                          Description
                        </label>
                      </div>
                      <Suspense fallback={<div>Loading...</div>}>
                        <div className="col-md-9  fv-row">
                          <Editor
                            apiKey="jetrrue4zshkdux49yxq66vc2grzub4qndwetpz8opu8usu7"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue={selectedRow?.description}
                            init={{
                              selector: "textarea#form_description",
                              plugins:
                                "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                              toolbar_mode: "floating",
                              menubar:
                                "file edit view insert format tools table help",
                              toolbar:
                                "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
                              images_upload_url:
                                "https://members.app-sip.com/v1/api/image/upload_image",
                            }}
                          />
                        </div>
                      </Suspense>
                    </div>
                  </div>
                  <div className="col-md-9  ms-auto">
                    <button
                      onClick={close}
                      className="me-2 btn btn-light-primary fs-7 fw-bold px-7"
                    >
                      Back
                    </button>
                    <button
                      onClick={() => log()}
                      className="btn btn-primary fs-7 px-5"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Edit;
