import { Icon } from "@iconify/react";
import {
  Button,
  Form,
  Popconfirm,
  Select,
  Table,
  message,
  Switch,
  Checkbox,
} from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";

import {
  CaretDownOutlined,
  CaretRightOutlined,
  HolderOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { useHistory, useLocation } from "react-router-dom";
import {
  DeleteIcon,
  EditIcon,
  PropsFilter,
  RenderTable,
} from "../../../../config";
import CreateMenu from "./create-menu";
import { Wrapper } from "./style";

const { Option } = Select;

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const Menus = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [selectedIcons, setSelectedIcons] = useState("");
  const onFinish = (values) => {};
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});
  const [visible, setVisible] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [activeKey, setActiveKey] = useState("");

  const [menuList, setMenuList] = useState([]);
  const [menuLoading, setMenuLoading] = useState(true);

  const [createMenusList, setCreateMenusList] = useState({
    title: "",
    // sub_menu_title: [""],
  });

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const getParentMenuList = async () => {
    setMenuLoading(true);
    const response = await MakeApiCall(`parent-menu-items`, "get", null, true);

    if (response?.status) {
      setMenuList(
        Object?.entries(response?.data || {})?.map(([key, value]) => {
          return {
            label: value,
            value: key,
          };
        }) || []
      );

      setMenuLoading(false);
    } else {
      setMenuLoading(false);
      message.warning(response?.message);
    }
  };
  const getList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `menu-item?isAll=0&per-page=100&field_name=${
        data?.field_name || ""
      }&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(
        response?.data?.records?.map((d) => {
          const { children, ...rest } = d;
          return {
            ...rest,
            children_data: children,
          };
        }) || []
      );
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };
  const changeStatus = async (id, status) => {
    const response = await MakeApiCall(
      `menu-item/${id}`,
      "put",
      {
        status: status === 1 ? 0 : 1,
      },
      true
    );

    if (response?.status) {
      getList();
      message.success(response?.message);
    } else {
      message.warning(response?.message);
    }
  };
  const changeis_restricted = async (id, status) => {
    const response = await MakeApiCall(`menu-item/${id}`, "put", status, true);

    if (response?.status) {
      getList();
      message.success(response?.message);
    } else {
      message.warning(response?.message);
    }
  };
  const addMenu = async () => {
    if (!createMenusList?.title) return;

    const response = await MakeApiCall(
      `menu-item`,
      "post",
      createMenusList?.parent
        ? createMenusList
        : { ...createMenusList, parent: 0 },
      true
    );

    if (response?.status) {
      if (!createMenusList?.parent) {
        getParentMenuList();
      }
      setCreateMenusList({
        title: null,
        parent: null,
      });
      setActiveKey("");
      getList();
      message.destroy();

      message.success(response?.message);
    } else {
      message.destroy();

      message.warning(response?.data?.title?.[0] || response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getList();
    setMenuLoading(true);
    getParentMenuList();
    return () => {};
  }, []);

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: (e) => {
        // if (!e?.children) return;

        return <DragHandle />;
      },
    },
    {
      title: "Order",
      width: 100,
      filterIndex: "viewIndex",
      render: (_, __, i) => {
        return <span>{_?.viewIndex}</span>;
      },
    },
    {
      title: "Menu Name",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      filterIndex: "icon",
      render: (e) => {
        if (!e) return <span>-</span>;
        return (
          <span>
            <Icon
              icon={e}
              width="30"
              height="30"
              style={{ cursor: "pointer", color: "black" }}
            />
          </span>
        );
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      filterIndex: "url",
      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Action",
      align: "center",
      key: "action",
      render: (_, record) => (
        <span>
          <a onClick={() => handleEdit(record)} className=" me-2">
            {EditIcon}
          </a>
          <Popconfirm
            title="Delete the menu"
            description="Are you sure to delete this Menu?"
            okText="Yes"
            onConfirm={() => handleDelete(record.id)}
            placement="left"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      filterIndex: "status",
      render: (e, row) => {
        return (
          <Switch
            checked={e === 1}
            onChange={(checked) => changeStatus(row?.id, e)}
          />
        );
      },
    },
    {
      title: "Restricted",
      dataIndex: "is_restricted",
      filterIndex: "is_restricted",
      render: (e, row) => {
        return (
          <Switch
            checked={e === 1}
            onChange={(checked) =>
              changeis_restricted(row?.id, {
                title: row?.title,
                icon: row?.icon,
                url: row?.url,
                status: row?.status,
                is_restricted: e == 1 ? 0 : 1,
                children: row?.children_data?.map((d) => ({
                  id: d?.id,
                  title: d?.title,
                  icon: d?.icon,
                  url: d?.url,
                  status: d?.status,
                  is_restricted: e == 1 ? 0 : 1,
                })),
              })
            }
          />
        );
      },
    },
  ];
  const columnsSub = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: (e) => {
        // if (!e?.children) return;

        return <DragHandle />;
      },
    },
    {
      title: "Order",
      width: 100,
      filterIndex: "child_sort_order",
      render: (_, __, i) => {
        return <span>{_?.child_sort_order}</span>;
      },
    },
    {
      title: "Menu Name",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      filterIndex: "icon",
      render: (e) => {
        if (!e) return <span>-</span>;
        return (
          <span>
            <Icon
              icon={e}
              width="30"
              height="30"
              style={{ cursor: "pointer", color: "black" }}
            />
          </span>
        );
      },
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      filterIndex: "url",
      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Action",
      align: "center",
      key: "action",
      render: (_, record) => (
        <span>
          <Popconfirm
            title="Delete the menu"
            description="Are you sure to delete this Menu?"
            okText="Yes"
            onConfirm={() => handleDelete(record.id)}
            placement="left"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      filterIndex: "status",
      render: (e, row) => {
        return (
          <Switch
            checked={row?.parentStatus === 0 ? 0 : e === 1}
            disabled={row?.parentStatus === 0}
            onChange={(checked) => changeStatus(row?.id, e)}
          />
        );
      },
    },
    {
      title: "Restricted",
      dataIndex: "is_restricted",
      filterIndex: "is_restricted",
      render: (e, row) => {
        return (
          <Switch
            checked={e === 1}
            disabled={row?.parentIs_restricted === 0}
            onChange={(checked) =>
              changeis_restricted(row?.id, { is_restricted: e === 1 ? 0 : 1 })
            }
          />
        );
      },
    },
  ];

  // Function to handle the Add button
  const handleAdd = () => {
    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=add`
    );
    // setSelectedRow({});

    // setVisible(true);
  };
  // Functions to handle edit and delete actions
  const handleEdit = (row) => {
    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=edit&id=${row?.id}`
    );
  };

  const deleteRow = async (id) => {
    const response = await MakeApiCall(`menu-item/${id}`, "delete", null, true);

    if (response?.status) {
      message.destroy();
      getList();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const saveSortOrder = async (data) => {
    const response = await MakeApiCall(
      `menu-item/save-sort-order`,
      "post",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const handleDelete = (id) => {
    message.loading("Loading...", 0);

    // Implement your delete logic here
    deleteRow(id);
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setList((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id
        );
        saveSortOrder(
          arrayMove(prevState, activeIndex, overIndex)?.map((d, i) => ({
            id: d?.id,
            sort_order: i + 1,
          }))
        );

        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };
  const onDragEndChild = ({ active, over }, row) => {
    if (active.id !== over?.id) {
      setList((prevState) => {
        return prevState?.map((s) => {
          if (s?.id === row?.id) {
            const activeIndex = s?.children_data.findIndex(
              (record) => record.id === active?.id
            );
            const overIndex = s?.children_data.findIndex(
              (record) => record.id === over?.id
            );
            saveSortOrder(
              arrayMove(row?.children_data, activeIndex, overIndex)?.map(
                (d, i) => ({ id: d?.id, child_sort_order: i + 1 })
              )
            );

            return {
              ...s,
              children_data: arrayMove(
                row?.children_data,
                activeIndex,
                overIndex
              )?.map((d, i) => ({ ...d, child_sort_order: i + 1 })),
            };
          }
          return s;
        });
      });
    }
  };

  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);
    urlObj.searchParams.delete("viewType");
    urlObj.searchParams.delete("id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  if (pageType) {
    return (
      <Wrapper id="kt_content_container" className="custom-ui">
        <CreateMenu
          pageType={pageType}
          id={selectedID}
          // selectedRow={selectedRow}
          getListAPI={() => {
            setLoading(true);
            getList();
          }}
          // setSelectedRow={setSelectedRow}
          close={() => {
            history.push(updatedUrl);
          }}
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper id="kt_content_container" className="custom-ui">
      <div className="row g-2">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch">
            <div className=" card-header">
              <div className="card-title">Menus</div>
              <div className="card-toolbar">
                <a onClick={handleAdd} className="add-btn ">
                  <svg
                    width={16}
                    height={16}
                    className="me-2"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                  </svg>
                  Add
                </a>
              </div>
            </div>
            <div className="card-body pt-0 pb-3">
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={list.map((i) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    rowKey="id"
                    fixed={true}
                    sticky={{
                      offsetHeader: "0px",
                    }}
                    components={{
                      body: {
                        row: Row,
                      },
                    }}
                    dataSource={list?.map((d, i) => ({
                      ...d,
                      viewIndex: i + 1,
                    }))}
                    // onChange={handleChange}
                    columns={columns?.map((d) => ({
                      ...d,
                      ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d),
                    }))}
                    loading={loading}
                    pagination={false}
                    rowClassName={(record, index) => {
                      return index % 2 === 0 ? "even-row" : "odd-row";
                    }}
                    bordered
                    expandable={{
                      expandedRowRender: (record) => (
                        <DndContext
                          modifiers={[restrictToVerticalAxis]}
                          onDragEnd={(e) => onDragEndChild(e, record)}
                        >
                          <SortableContext
                            items={record?.children_data.map((i) => i.id)}
                            strategy={verticalListSortingStrategy}
                          >
                            <Table
                              rowKey="id"
                              components={{
                                body: {
                                  row: Row,
                                },
                              }}
                              dataSource={record?.children_data
                                ?.map((t) => ({
                                  ...t,
                                  parentStatus: record?.status,
                                  parentIs_restricted: record?.is_restricted,
                                }))
                                ?.sort(
                                  (a, b) =>
                                    a.child_sort_order - b.child_sort_order
                                )}
                              columns={columnsSub?.map((d) => ({
                                ...d,
                                ...(d?.filterIndex
                                  ? PropsFilter(d?.filterIndex)
                                  : ""),
                              }))}
                              loading={loading}
                              pagination={false}
                              rowClassName={(record, index) => {
                                return index % 2 === 0 ? "even-row" : "odd-row";
                              }}
                              bordered
                              size="small"
                              scroll={{ x: "max-content" }}
                            />
                          </SortableContext>
                        </DndContext>
                        // <Table
                        //   dataSource={record?.children_data}
                        //   columns={columnsSub}
                        //   loading={loading}
                        //   pagination={false}
                        //   bordered
                        //   size="small"
                        // />
                      ),
                      expandIcon: (props) => {
                        if (
                          props?.record.children_data?.length === 0 ||
                          !props?.record.children_data
                        )
                          return;
                        if (props.expanded) {
                          return (
                            <Button
                              type="link"
                              icon={<CaretDownOutlined />}
                              onClick={(e) => props.onExpand(props.record, e)}
                            />
                          );
                        } else {
                          return (
                            <Button
                              type="link"
                              icon={<CaretRightOutlined />}
                              onClick={(e) => props.onExpand(props.record, e)}
                            />
                          );
                        }
                      },
                      rowExpandable: (record) =>
                        record.children_data?.length !== 0 ||
                        !record.children_data,
                    }}
                    size="small"
                    scroll={{ x: "max-content" }}
                  />
                </SortableContext>
              </DndContext>
              {/* <Table
                dataSource={list}
                onChange={handleChange}
                columns={columns?.map((d) => ({
                  ...d,
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                }))}
                loading={loading}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                scroll={{ x: "max-content" }}
              /> */}
            </div>
            <div className="d-none card-footer pt-0 pb-5">
              <Pagination
                loading={loading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setLoading(true);
                  setPageSize(e);
                  setPage(1);
                  getList({
                    page: 1,
                    pageSize: e,
                    ...sortFilters,
                  });
                }}
                onPageNo={(e) => {
                  setLoading(true);
                  setPage(e);
                  getList({
                    page: e,
                    ...sortFilters,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Menus;
