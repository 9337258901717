import styled from "styled-components";

export const Wrapper = styled.div`
  .row-card {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #1f97d378;
    box-shadow: rgba(149, 157, 165, 0.1) 0px 8px 24px;
  }
`;
