import { Button, Form, Input, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { configModal } from "../../../../config";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const [parameterGroupList, setParameterGroupList] = useState([]);
  const [parameterGroupLoading, setParameterGroupLoading] = useState(true);

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      form.setFieldsValue({
        parameter_group_id: selectedRow?.parameter_group_id,
        param_name: selectedRow?.param_name,
        param_type: selectedRow?.param_type,
        default_value: selectedRow?.default_value,
        default_upper_value: selectedRow?.default_upper_value,
        default_lower_value: selectedRow?.default_lower_value,
      });
    }
  }, [selectedRow]);

  const UpdateAction = async (data) => {
    const response = await MakeApiCall(
      `seller-parameter/${selectedRow?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();
      modal
        .success(configModal("Seller Parameter", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      modal.error(configModal("Seller Parameter", response?.message));

      message.destroy();
    }
  };
  const AddUpdate = async (data) => {
    const response = await MakeApiCall(`seller-parameter`, "post", data, true);

    if (response?.status) {
      message.destroy();

      modal
        .success(configModal("Seller Parameter", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      message.destroy();
      modal.error(configModal("Seller Parameter", response?.message));
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values, "values");

        message.destroy();
        message.loading("Loading...", 0);

        if (modalType === "Add") {
          AddUpdate(values);
        } else {
          UpdateAction(values);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    onClose();
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const GetParameterGroupList = async () => {
    setParameterGroupLoading(true);
    const response = await MakeApiCall(
      `parameter-group?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setParameterGroupList(response?.data?.records || []);
      setParameterGroupLoading(false);
    } else {
      message.destroy();
      setParameterGroupLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    GetParameterGroupList();

    return () => {};
  }, []);

  return (
    <Modal
      title={`${modalType} Seller Parameter`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="seller-parameter"
        // initialValues={{
        //   parameter_group_id: "",
        //   param_name: "",
        //   param_type: "",
        //   default_value: "",
        //   default_upper_value: "",
        //   default_lower_value: "",
        // }}
        // onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Parameter Group Id"
          name="parameter_group_id"
          rules={[
            {
              required: true,
              message: "Please input your Parameter Group Id!",
            },
          ]}
        >
          <Select
            options={parameterGroupList?.map((d) => ({
              label: d?.group_name,
              value: d?.id,
            }))}
            loading={parameterGroupLoading}
          />
        </Form.Item>

        <Form.Item
          label="Parameter Name"
          name="param_name"
          rules={[
            { required: true, message: "Please input your Parameter Name!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Parameter Type"
          name="param_type"
          rules={[
            { required: true, message: "Please input your Parameter Type!" },
          ]}
        >
          <Select
            options={[
              {
                label: "Text",
                value: "Text",
              },
              {
                label: "Range%",
                value: "Range%",
              },
              {
                label: "Range$",
                value: "Range$",
              },
              {
                label: "RangeInt",
                value: "RangeInt",
              },
              {
                label: "Single%",
                value: "Single%",
              },
              {
                label: "Single$",
                value: "Single$",
              },
              {
                label: "SingleInt",
                value: "SingleInt",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label="Default Value"
          name="default_value"
          dependencies={["param_type"]}
          rules={[
            {
              required: true,
              message: "Please input your Default Value!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("param_type") === "Text") {
                  return Promise.resolve();
                }
                let regex = /^(?:\d+|\d+\.\d+)$/;
                if (!regex.test(value)) {
                  return Promise.reject(
                    new Error("Invalid Value, please input a number!")
                  );
                }
              },
            }),
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Default Upper Value"
          name="default_upper_value"
          rules={[
            {
              pattern: /^(?:\d+|\d+\.\d+)$/,
              message: "Please enter a valid number!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Default Lower Value"
          name="default_lower_value"
          rules={[
            {
              pattern: /^(?:\d+|\d+\.\d+)$/,
              message: "Please enter a valid number!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item> */}
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default AddData;
