import styled from "styled-components";

export const Wrapper = styled.div`
  .yellow {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #eec800 !important;
      border: none !important;
    }
  }
  .blue {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #0051d4 !important;
      border: none !important;
    }
  }
  .purpal {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #ae35b0 !important;
      border: none !important;
    }
  }
  .Expenses {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #009a9d !important;
      border: none !important;
    }
  }
  .Profit {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #009de8 !important;
      border: none !important;
    }
  }
  .net {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #7438ba !important;
      border: none !important;
    }
  }
  .Refunds {
    .ant-checkbox-checked,
    .ant-checkbox-inner {
      background-color: #f97303 !important;
      border: none !important;
    }
  }
`;
