import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-tabs-tab {
    background-color: #fff !important;
    border-radius: 0.475rem !important;
    color: var(--bs-nav-link-color);
    font-size: 0.95rem !important;
  }
  .ant-tabs-tab-active {
    background-color: var(--bs-primary) !important;
    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
  .action-btn {
    display: flex;
    align-items: center;
    .delete-btn {
      background: #f36d4f;
      padding: 4px 8px;
      color: #fff;
      font-weight: 700;
      border-radius: 5px;
      i {
        font-size: 18px;
        color: #fff;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }
    .reset-btn {
      margin-left: 10px;
      background: #045c9e;
      padding: 4px 8px;
      color: #fff;
      font-weight: 700;
      border-radius: 5px;
      i {
        font-size: 18px;
        color: #fff;
        margin-right: 8px;
        position: relative;
        top: 2px;
      }
    }
  }
`;
