import { Drawer } from "antd";
import React from "react";

const Config = (props) => {
  const { onClose, open } = props;
  return (
    <Drawer
      title="Choose Metrics"
      placement="right"
      onClose={onClose}
      closeIcon={
        <i
          onClick={() => onClose()}
          className="ki-outline ki-cross-square fs-2"
        />
      }
      footer={
        <div className="card-footer  py-3 justify-content-between d-flex">
          {/*begin::Dismiss button*/}
          {/* <button class="btn btn-light-danger" data-kt-drawer-dismiss="true">Close</button> */}
          <div>
            <button className="btn btn-light-primary fs-7">Reset</button>
          </div>
          <div>
            <button className="btn btn-light fs-7 me-2">Make Default</button>
            <button className="btn btn-primary fs-7">Apply</button>
          </div>
          {/*end::Dismiss button*/}
        </div>
      }
      open={open}
    >
      <div className="card-body hover-scroll-overlay-y ">
        {/* <div class="fs-7">Optimize the Pathfinder by setting priority on the metrics that you want to showcase first.</div> */}
        <div className>
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Unit Sold
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Sales
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              CR
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              USP
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Traffic
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              BB Rate
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Keyword
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Keyword Rank
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Impression Share
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Cart Add Rate
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Purchase Rate
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              TACOS
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              ACOS
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Ad Impression Share
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              Ad Click Thru Rate
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              CTR
            </label>
          </div>
          <div className="separator separator-dashed my-3" />
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              defaultValue={1}
              id="flexCheckDefault"
            />
            <label
              className="form-check-label fw-bold text-dark fs-6"
              htmlFor="flexCheckDefault"
            >
              ROAS
            </label>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default Config;
