import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Alert, Checkbox, DatePicker, Tag, message } from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import Chart from "react-apexcharts";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Link } from "react-router-dom";
import Icons from "../../../components/icons";
import { MakeApiCall } from "../../../apis";
dayjs.extend(quarterOfYear);
const AdminDashboard = () => {
  const [list, setList] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const SellerStatus = [
    {
      title: "Active Sellers",
      key: "active_sellers",
      bgColor: "#cee4f1",
      color: "#62b5e7",
      icon: (
        <i
          style={{ color: "#62b5e7" }}
          className="ki-outline ki-chart-simple-2 fs-2x ms-n1"
        />
      ),
    },
    {
      title: "Premium Sellers",
      key: "premium_sellers",
      bgColor: "#fff2c1",
      color: "#4b442a",
      icon: (
        <i
          style={{ color: "#4b442a" }}
          className="ki-outline ki-dollar fs-2x ms-n1"
        />
      ),
    },
    {
      title: "Deactivated Sellers",
      key: "deactivated_sellers",
      bgColor: "#ffd9d8",
      color: "#cd6c6c",
      icon: (
        <i
          style={{ color: "#cd6c6c" }}
          className="ki-outline ki-profile-circle fs-2x ms-n1"
        />
      ),
    },
  ];
  const CustomerJourney = [
    {
      title: "Incomplete Registrations",
      key: "test",
      bgColor: "#ffd9d8",
      color: "#cd6c6c",
      icon: (
        <i
          style={{ color: "#cd6c6c" }}
          className="ki-outline ki-notepad-edit fs-2x ms-n1"
        />
      ),
    },
    {
      title: "New Registrations",
      key: "premium_sellers",
      bgColor: "#cee4f1",
      color: "#62b5e7",
      icon: (
        <i
          style={{ color: "#62b5e7" }}
          className="ki-outline ki-notepad-edit fs-2x ms-n1"
        />
      ),
    },
    {
      title: "Connected Sellers",
      key: "deactivated_sellers",
      bgColor: "#cee4f1",
      color: "#62b5e7",
      icon: (
        <i
          style={{ color: "#62b5e7" }}
          className="ki-outline ki-profile-circle fs-2x ms-n1"
        />
      ),
    },
    {
      title: "New PCA Sellers",
      key: "deactivated_sellers",
      bgColor: "#fff2c1",
      color: "#4b442a",
      icon: (
        <i
          style={{ color: "#4b442a" }}
          className="ki-outline ki-profile-circle fs-2x ms-n1"
        />
      ),
    },
    {
      title: "PCA Sellers",
      key: "deactivated_sellers",
      bgColor: "#fff2c1",
      color: "#4b442a",
      icon: (
        <i
          style={{ color: "#4b442a" }}
          className="ki-outline ki-profile-circle fs-2x ms-n1"
        />
      ),
    },
  ];
  const DisconnectedCustomers = [
    {
      title: "Sellercentral Disconnections",
      key: "test",
      bgColor: "#ffd9d8",
      color: "#cd6c6c",
      icon: (
        <i
          style={{ color: "#cd6c6c" }}
          className="ki-outline ki-electricity fs-2x ms-n1"
        />
      ),
    },
    {
      title: "Advertising API Disconnections",
      key: "premium_sellers",
      bgColor: "#ffd9d8",
      color: "#cd6c6c",
      icon: (
        <i
          style={{ color: "#cd6c6c" }}
          className="ki-outline ki-electricity fs-2x ms-n1"
        />
      ),
    },
    {
      title: "PCA Disconnections",
      key: "deactivated_sellers",
      bgColor: "#ffd9d8",
      color: "#cd6c6c",
      icon: (
        <i
          style={{ color: "#cd6c6c" }}
          className="ki-outline ki-electricity fs-2x ms-n1"
        />
      ),
    },
  ];

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });
  const state = {
    series: [
      {
        name: "Session",
        data: [30, 90, 40, 60, 30, 50, 70], // Replace with your data
      },
    ],
    options: {
      chart: {
        height: 300,
        type: "line",
        toolbar: { show: !1 },
        dropShadow: {
          enabled: !0,
          top: 12,
          left: 0,
          bottom: 0,
          right: 0,
          blur: 2,
          color: "rgba(132, 145, 183, 0.4)",
          opacity: 0.45,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      colors: ["#e31714"],
      markers: {
        size: 4,
        colors: "#FFF",
        strokeColors: "#e31714",
        hover: {
          size: 7,
        },
      },
      xaxis: {
        categories: [
          "Jan-28",
          "Feb-04",
          "Feb-11",
          "Feb-18",
          "Feb-25",
          "Mar-03",
          "Mar-10",
          "Mar-13",
        ], // Replace with your categories
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          formatter: (val) => {
            return `$${val}`;
          },
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return `$${val}`;
          },
        },
      },
    },
  };
  const options = {
    chart: {
      type: "pie",
    },
    title: {
      text: "Password Reset Rate",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        innerSize: "60%",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          distance: 50,
        },
        showInLegend: true,
      },
    },
  };

  const options_ = {
    chart: {
      zoomType: "xy",
    },
    title: {
      text: "",
    },
    xAxis: [
      {
        categories: [
          "Mar-01",
          "Mar-02",
          "Mar-03",
          "Mar-04",
          "Mar-05",
          "Mar-06",
          "Mar-07",
          "Mar-08",
          "Mar-09",
          "Mar-10",
          "Mar-11",
          "Mar-12",
          "Mar-13",
          "Mar-14",
          "Mar-15",
          "Mar-16",
        ],
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis for columns
        labels: {
          format: "{value}",
        },
        title: {
          text: "",
        },
      },
      {
        // Secondary yAxis for spline
        title: {
          text: "",
        },
        labels: {
          format: "{value}",
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    series: [
      {
        name: "Login Frequency",
        type: "column",
        yAxis: 1,
        data: [
          49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
          95.6, 54.4, 29.9, 71.5, 106.4, 129.2,
        ], // Sample data
        tooltip: {
          valueSuffix: "",
        },
        color: "#EEC800",
      },
      {
        name: "Active Login",

        color: "#0051D4",

        type: "column",
        yAxis: 1,
        data: [
          7.0, 6.9, 9.5, 14.5, 18.2, 21.5, 25.2, 26.5, 23.3, 18.3, 13.9, 9.6,
          7.0, 6.9, 9.5, 14.5,
        ], // Sample data
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        name: "Password Resets",
        color: "#AE35B0",
        type: "column",
        yAxis: 1,
        visible: true,
        data: [
          49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
          95.6, 54.4, 29.9, 71.5, 106.4, 129.2,
        ],
      },
      {
        name: "Failed Logins",
        color: "#009A9D",
        type: "column",
        yAxis: 1,
        visible: true,
        data: [
          29.9, 91.5, 46.4, 129.2, 144.0, 166.0, 95.6, 118.5, 206.4, 134.1,
          75.6, 64.4, 49.9, 51.5, 96.4, 109.2,
        ],
      },
      {
        name: "Active Login",
        visible: true,
        color: "#009DE8",
        type: "column",
        yAxis: 1,
        data: [
          15.0, 80.5, 126.4, 119.2, 134.0, 186.0, 105.6, 158.5, 226.4, 174.1,
          105.6, 84.4, 59.9, 81.5, 116.4, 139.2,
        ],
      },
      {
        name: "Premium Logins",
        color: "#7438BA",
        type: "column",
        yAxis: 1,
        visible: true,
        data: [
          65.0, 70.5, 96.4, 129.2, 114.0, 156.0, 125.6, 108.5, 196.4, 164.1,
          85.6, 74.4, 39.9, 61.5, 86.4, 119.2,
        ],
      },
      {
        name: "Disconnected Logins",
        visible: true,
        color: "#7438BA",
        type: "column",
        yAxis: 1,
        data: [
          55.0, 60.5, 106.4, 109.2, 124.0, 146.0, 115.6, 98.5, 186.4, 154.1,
          65.6, 44.4, 19.9, 41.5, 76.4, 99.2,
        ],
      },
      {
        name: "Active Time",
        color: "#F97303",
        visible: true,
        yAxis: 0,
        type: "spline",
        data: [
          40.9, 61.5, 116.4, 139.2, 154.0, 186.0, 145.6, 178.5, 256.4, 204.1,
          125.6, 104.4, 79.9, 91.5, 136.4, 159.2,
        ],
      },
      {
        name: "Premium Time",
        color: "#EEC800",
        yAxis: 0,
        type: "spline",
        visible: true,
        data: [
          45.0, 65.5, 121.4, 149.2, 164.0, 196.0, 155.6, 188.5, 266.4, 214.1,
          135.6, 114.4, 89.9, 101.5, 146.4, 169.2,
        ],
      },
      {
        name: "Disconnected Time",
        visible: true,
        yAxis: 0,
        type: "spline",
        color: "#009A9D",
        data: [
          35.0, 55.5, 111.4, 129.2, 144.0, 176.0, 135.6, 168.5, 246.4, 194.1,
          115.6, 94.4, 69.9, 81.5, 126.4, 149.2,
        ],
      },
    ],
    legend: {
      enabled: false, // This line hides the legend
    },
    // Add more series for each data set you have
  };
  const colorList = [
    {
      key: "#EEC800",
      value: "yellow",
    },
    {
      key: "#0051D4",
      value: "blue",
    },
    {
      key: "#AE35B0",
      value: "purpal",
    },
    {
      key: "#009A9D",
      value: "Expenses",
    },
    {
      key: "#009DE8",
      value: "Profit",
    },

    {
      key: "#7438BA",
      value: "net",
    },
    {
      key: "#F97303",
      value: "Refunds",
    },
  ];

  const GetAlerts = async (data) => {
    const response = await MakeApiCall(
      `admin-alert?is_deleted=0&page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    GetAlerts();
    return () => {};
  }, []);

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      {list?.length !== 0 && (
        <Alert
          message="Attention!"
          className="mb-3"
          description={
            <>
              You have items that need attention.{" "}
              <Link to="/logs/admin-alerts">Click here</Link> for details
            </>
          }
          type="warning"
          showIcon
          closable
          icon={<Icons type="triangle-alert" />}
        />
      )}

      <div id="kt_app_toolbar" className="app-toolbar mb-2 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Seller Status
            </h1>
          </div>
        </div>
      </div>

      <div className="row g-4">
        {SellerStatus?.map((d, i) => (
          <div key={i} className="col-auto min-w-250px">
            <div
              className="card"
              style={{ background: d?.bgColor, borderRadius: "10px" }}
            >
              <div className="card-body py-4">
                {d?.icon}
                <div>
                  <div className=" text-gray-900 mt-3 mb-2">{d?.title}</div>
                  <div className="text-gray-900 fw-semibold fs-2 ">500</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div id="kt_app_toolbar" className="app-toolbar  pt-5 mb-2 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Customer Journey
            </h1>
          </div>
        </div>
      </div>
      <div className="row g-4">
        {CustomerJourney?.map((d, i) => (
          <div key={i} className="col-auto min-w-250px">
            <div
              className="card"
              style={{ background: d?.bgColor, borderRadius: "10px" }}
            >
              <div className="card-body py-4">
                {d?.icon}
                <div>
                  <div className=" text-gray-900 mt-3 mb-2">{d?.title}</div>
                  <div className="text-gray-900 fw-semibold fs-2 ">500</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div id="kt_app_toolbar" className="app-toolbar  pt-5 mb-2 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Disconnected Customers
            </h1>
          </div>
        </div>
      </div>
      <div className="row g-4">
        {DisconnectedCustomers?.map((d, i) => (
          <div key={i} className="col-auto min-w-250px">
            <div
              className="card"
              style={{ background: d?.bgColor, borderRadius: "10px" }}
            >
              <div className="card-body py-4">
                {d?.icon}
                <div>
                  <div className=" text-gray-900 mt-3 mb-2">{d?.title}</div>
                  <div className="text-gray-900 fw-semibold fs-2 ">500</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div id="kt_app_toolbar" className="app-toolbar  pt-5 mb-2 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Seller Activity
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3">
            <Tag color="blue-inverse">This Month</Tag>
            <DatePicker.RangePicker
              className="min-w-250px"
              presets={presetRanges}
              format={"YYYY-MMM-DD"}
            />
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-xxl-5 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Average Session Duration</div>
            </div>
            <div className="card-body">
              <Chart
                options={state.options}
                series={state.series}
                type="line"
                height={300}
              />
            </div>
          </div>
        </div>
        <div className="col-xxl-7 col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <div className="card-title">Login Frequency</div>
            </div>
            <div className="card-body">
              <Chart
                options={{
                  ...state.options,
                  colors: ["#38b6ff"],
                  plotOptions: {
                    bar: {
                      borderRadius: 4, // Set the border radius here
                      dataLabels: {
                        position: "top", // Position of data labels
                      },
                    },
                  },
                }}
                series={state.series}
                type="bar"
                height={300}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="kt_app_toolbar" className="app-toolbar  pt-5 mb-2 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Login Performance
            </h1>
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-xxl-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  series: [
                    {
                      name: "Resets",
                      colorByPoint: true,
                      data: [
                        {
                          name: "Resets",
                          y: 23.1, // Your reset percentage
                          color: "#004aad", // Adjust color as needed
                        },
                        {
                          name: "No Resets",
                          y: 76.9, // Remaining percentage
                          color: "#72a9e0", // Adjust color as needed
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  title: {
                    text: "Failed Login Attempt Rate",
                  },
                  series: [
                    {
                      name: "Resets",
                      colorByPoint: true,
                      data: [
                        {
                          name: "Failed Logins",
                          y: 76.9,

                          color: "#e4211e", // Adjust color as needed
                        },
                        {
                          name: "Successful Logins",
                          y: 23.1,
                          color: "#3e632f", // Adjust color as needed
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="kt_app_toolbar" className="app-toolbar  pt-5 mb-2 ">
        <div
          id="kt_app_toolbar_container"
          className="app-container d-flex flex-stack"
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              User Engagement
            </h1>
          </div>
        </div>
      </div>
      <div className="row g-4">
        <div className="col-xxl-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  title: {
                    text: "By Login",
                  },
                  series: [
                    {
                      name: "Resets",
                      colorByPoint: true,
                      data: [
                        {
                          name: "Premium Sellers",
                          y: 17.9,
                          color: "#c88f52",
                        },
                        {
                          name: "Active Sellers",
                          y: 59.7,
                          color: "#3e632f",
                        },
                        {
                          name: "Disconnected Sellers",
                          y: 22.4,
                          color: "#9c0000",
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xxl-6 col-md-12">
          <div className="card">
            <div className="card-body">
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  ...options,
                  title: {
                    text: "By Duration",
                  },
                  series: [
                    {
                      name: "Resets",
                      colorByPoint: true,
                      data: [
                        {
                          name: "Premium Sellers",
                          y: 9.3,
                          color: "#c88f52",
                        },
                        {
                          name: "Active Sellers",
                          y: 37.4,
                          color: "#3e632f",
                        },
                        {
                          name: "Disconnected Sellers",
                          y: 53.3,
                          color: "#9c0000",
                        },
                      ],
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex">
            <div
              className="min-w-220px"
              style={{ borderRight: "1px dashed #dbdfe9" }}
            >
              <div className="mt-5 d-grid">
                <Checkbox.Group style={{ width: "220px" }}>
                  {options_?.series?.map((d, i) => (
                    <Checkbox
                      className={
                        colorList?.find((f) => f?.key === d?.color)?.value
                      }
                      checked
                    >
                      <div
                        className="apexcharts-tooltip-series-group apexcharts-active"
                        style={{ order: 1, display: "flex" }}
                        key={i}
                      >
                        <div
                          className="apexcharts-tooltip-text"
                          style={{
                            fontFamily: "'Montserrat' sans-serif",
                            fontSize: 12,
                          }}
                        >
                          <div className="apexcharts-tooltip-y-group">
                            <span
                              style={{
                                textTransform: "capitalize",
                                // fontWeight: 900,
                                color: "#656565",
                              }}
                              className="apexcharts-tooltip-text-y-label"
                            >
                              {d?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
            </div>
            <div style={{ width: "calc(100vw - 270px)" }}>
              <HighchartsReact highcharts={Highcharts} options={options_} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminDashboard;
