import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Empty, Table, Tag, message } from "antd";
import moment from "moment";
import "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import { GlobalContext } from "../../../../common-context";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import CredentialsError from "../../../credential-page";
import { Wrapper } from "./style";
import { RenderTable } from "../../../../config";

const DataScheduler = ({ menusList, pageTitle }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const getList = async (data) => {
    const response = await MakeApiCall(
      `user-scheduler?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records);
      setPageSize(response?.data?.pagination?.page_size);
      setTotalPage(response?.data?.pagination?.totalCount);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);
  const contextValue = useContext(GlobalContext);
  useEffect(() => {
    if (lws === 0 || sp === 0) return;
    getList();
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      ellipsis: true,
      align: "left",
      render: (item) => {
        return <span>{(page - 1) * pageSize + 1 + item.key}</span>;
      },
    },
    {
      title: "Created At (ET)",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000))
                .tz("America/New_York")
                .format("MMM DD, YYYY")}
            </span>
          </div>
        );
      },
    },
    {
      title: "Event Process Type",
      dataIndex: "event_process_type",
      key: "event_process_type",
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
    },
    {
      title: "Days of Inception",
      dataIndex: "previous_days",
      key: "previous_days",
    },
    {
      title: "Daily Frequency",
      dataIndex: "daily_frequency",
      key: "daily_frequency",
    },
    {
      title: "Daily Update Period",
      dataIndex: "update_daily_days",
      key: "update_daily_days",
    },
    {
      title: "Previous Run",
      dataIndex: "previous_status",
      key: "previous_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Current day status",
      dataIndex: "frequency_status",
      key: "frequency_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Scheduled Run",
      dataIndex: "frequency_status",
      key: "frequency_status",
      render: (item) => {
        return <span></span>;
      },
    },

    {
      title: "Daily log",
      dataIndex: "update_daily_status",
      key: "update_daily_status",
      render: (item) => {
        return (
          <span>
            <Tag
              color={`${item === "PENDING" ? "warning" : "success"}`}
              icon={
                item === "PENDING" ? (
                  <ClockCircleOutlined />
                ) : item === "DONE" ? (
                  <CheckCircleOutlined />
                ) : (
                  <CloseCircleOutlined />
                )
              }
            >
              {item || "-"}
            </Tag>
          </span>
        );
      },
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
    },
    {
      title: "Updated At (ET)",
      render: (item) => {
        return (
          <div>
            <span>
              {moment(new Date(item?.created_at * 1000))
                .tz("America/New_York")
                .format("MMM DD, YYYY hh:mm A")}
            </span>
          </div>
        );
      },
    },
  ];

  const { lws, sp } = contextValue?.data?.credentialsStatus;

  if (
    (lws !== 1 || sp !== 1) &&
    menusList?.find((d) => d?.url === "data-scheduler")?.is_restricted === 1
  ) {
    return <CredentialsError type="central-log" />;
  }

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid pt-5"
    >
      <>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header ">
                <h3 className="card-title">{pageTitle}</h3>
                <div className="card-toolbar gap-2"> </div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body pt-0 pb-0">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : list?.length === 0 ? (
                  <Empty />
                ) : (
                  <div className="">
                    <Table
                      columns={columns?.map((d) => ({
                        ...d,
                        render: (props, row, index) =>
                          RenderTable(props, row, index, d),
                      }))}
                      fixed={true}
                      sticky={{
                        offsetHeader: "0px",
                      }}
                      dataSource={list?.map((d, i) => ({ ...d, key: i }))}
                      rowKey="id"
                      scroll={{
                        x: "max-content",
                      }}
                      loading={loading}
                      pagination={false}
                      rowClassName={(record, index) => {
                        return index % 2 === 0 ? "even-row" : "odd-row";
                      }}
                      bordered
                      size="small"
                    />
                  </div>
                )}
              </div>
              <div className="card-footer pt-0 pb-5">
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={(e) => {
                    setLoading(true);
                    setPageSize(e);
                    setPage(1);
                    getList({
                      page: 1,
                      pageSize: e,
                    });
                  }}
                  onPageNo={(e) => {
                    setLoading(true);
                    setPage(e);
                    getList({
                      page: e,
                    });
                  }}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </>
    </Wrapper>
  );
};

export default DataScheduler;
