import { message, Modal, Popconfirm, Table } from "antd";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../../apis";
import { GlobalContext } from "../../../../common-context";
import InfoVideo from "../../../../components/header-icons";
import { TableLoading } from "../../../../components/table-animation";
import { configModal, DeleteIcon, ORIGIN } from "../../../../config";

const SpApi = (props) => {
  const { loading, sPList, locationData, getList, getListCheck, pageTitle } =
    props;
  const [modal, contextHolder] = Modal.useModal();
  const location = useLocation();
  const contextValue = useContext(GlobalContext);
  const { credentials, user_, credentialsStatus } = contextValue?.data;

  const messageView = async (title, message) => {
    const confirmed = await modal.warning(configModal(title, message));
  };

  const DeleteConnection = async (data) => {
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user-credentials/${data?.id}`,
      "delete",
      null,
      true
    );
    if (response?.status) {
      const user_ = {
        ...JSON.parse(localStorage.getItem("user")),
        [data?.credential_type === "SP-API"
          ? "amazon_connected"
          : "amazon_ads_connected"]: 2,
      };

      localStorage.setItem("user", JSON.stringify(user_));

      window.location.reload();

      message.destroy();
      await modal.success(configModal("Success", response?.message));
    } else {
      messageView("Error", response?.message);
      // message.warning(response?.message);
    }
  };

  useEffect(() => {
    message.destroy();
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(credentials?.advertising_client_id);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);

    return () => {};
  }, []);

  const columns = [
    {
      title: "Connection Type",

      width: "50px",
      render: (e) => {
        return (
          <span>
            {e?.credential_type === "SP-API"
              ? "SellerCentral"
              : e?.credential_type || "-"}
          </span>
        );
      },
    },
    {
      title: "Region",
      dataIndex: "credential_details",
      key: "credential_details",
      width: "120px",
      render: (e) => {
        const credential_details = JSON.parse(e);
        return <span>{credential_details?.region || "-"}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "75px",
      render: (e) => {
        return (
          <div className={e === 1 ? "Connected" : "Disconnect"}>
            <div className="dot">
              <div className="dot-i"></div>
            </div>
            {e === 1 ? "Connected" : "Disconnect"}
          </div>
        );
      },
    },
    {
      title: "Action",

      width: "75px",
      render: (e, row) => {
        return (
          <div className="action-btn">
            <Popconfirm
              title="Delete the Connection"
              placement="left"
              description="Are you sure to delete this Connection?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                DeleteConnection(row);
              }}
            >
              <div className="cursor-pointer delete-btn text-light">
                {DeleteIcon}
                Delete Connection
              </div>
            </Popconfirm>
            <Popconfirm
              title="Reset the Connection"
              placement="left"
              description="Are you sure to reset this Connection?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => {
                try {
                  if (e?.credential_type !== "SP-API") {
                    localStorage.setItem("reset", true);
                    // message.destroy();
                    // message.warning("API Working on");
                    let options = {};
                    options.scope = "advertising::campaign_management";
                    options.response_type = "code";
                    window.amazon.Login.authorize(
                      options,
                      `${
                        credentials?.advertising_return_url || ""
                      }?marketplace_id=ATVPDKIKX0DER&seller_name=${
                        user_?.seller_name || ""
                      }`
                    );
                    return;
                  }
                  localStorage.setItem("reset", true);
                  window?.open(
                    `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${
                      credentials?.lwa_application_id
                    }&state=${user_?.email}!!${user_?.name.replace(
                      /\s/g,
                      ""
                    )}!!ATVPDKIKX0DER&redirect_uri=${`${contextValue?.data?.credentials?.lwa_return_url}`}&version=beta`
                  );
                } catch (error) {}
              }}
            >
              <div className="cursor-pointer reset-btn">
                <i className="ki-duotone ki-arrows-circle">
                  <span className="path1" />
                  <span className="path2" />
                </i>
                Reset Connection
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div
      className="row gy-5 g-xl-5 mt-1"
      data-select2-id="select2-data-10-18hq"
    >
      <div className="col-xxl-12" data-select2-id="select2-data-9-e17g">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          <div className="card-header   align-items-center justify-content-start d-flex">
            <h3 className="card-title align-items-center d-flex">
              <span className="card-label fw-bolder text-dark">
                {pageTitle}
              </span>
            </h3>
            <InfoVideo data={locationData} title="Connections" />
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2">
            {/*begin::Table container*/}
            {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div className="table-responsive">
                <Table
                  dataSource={sPList}
                  scroll={{ x: "max-content" }}
                  columns={columns}
                  pagination={false}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                />
              </div>
            )}
          </div>
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>
      {/*end::Col*/}
      {contextHolder}
    </div>
  );
};

export default SpApi;
