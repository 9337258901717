import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Select,
  Table,
  theme,
  message,
  Empty,
} from "antd";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { MakeApiCall } from "../../../../apis";
import InfoVideo from "../../../../components/header-icons";
import Pagination from "../../../../components/pagination";
import { TableLoading } from "../../../../components/table-animation";
import ExportFile from "./export-file";
import { ENDPOINT, RenderTable } from "../../../../config";
import {
  DeleteCModal,
  ErrorModal,
  OnOkDeleteModal,
  SuccessModal,
} from "../../../../components/upload-modals";
import DatePickerView from "./date-picker-view";
dayjs.extend(quarterOfYear);
const SearchCatalogPerformance = ({ locationData }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { useToken } = theme;
  const [file, setFile] = useState({
    fileName: null,
    file: null,
  });
  const fileInputRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });
  const [messageView, setMessageView] = useState({
    type: null,
    message: "",
  });

  const [filters, setFilters] = useState({
    campaign_name: null,
    campaign_status: "asin_wise",
    start_date: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  });
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };
  const CommaAndFloat = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  const Comma = (e) => {
    return (e || 0).toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const columns = [
    { title: "Year", dataIndex: "select_year", key: "select_year" },
    { title: "Week Number", dataIndex: "week_number", key: "week_number" },
    { title: "Month Number", dataIndex: "month_number", key: "month_number" },
    { title: "ASIN Title", dataIndex: "title", key: "title" },
    { title: "ASIN", dataIndex: "asin", key: "asin" },
    { title: "Category", dataIndex: "category", key: "category" },
    {
      title: "Impressions",
      render: (e) => {
        return Comma(e || 0);
      },
      dataIndex: "impressions",
      align: "right",
      key: "impressions",
    },
    {
      title: "Rating (Median)",
      dataIndex: "impressions_rating_median",
      align: "right",
      key: "impressions_rating_median",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Price (Median) ($)",
      dataIndex: "impressions_price_median",
      align: "right",
      key: "impressions_price_median",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Same Day Shipping Speed",
      dataIndex: "impressions_same_day_shipping_speed",
      key: "impressions_same_day_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "1D Shipping Speed",
      dataIndex: "impressions_1d_shipping_speed",
      align: "right",
      key: "impressions_1d_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "2D Shipping Speed",
      dataIndex: "impressions_2d_shipping_speed",
      align: "right",
      key: "impressions_2d_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Clicks",
      dataIndex: "clicks",
      align: "right",
      key: "clicks",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Click Rate (CTR) (%)",
      dataIndex: "clicks_rate_ctr",
      align: "right",
      key: "clicks_rate_ctr",
      render: (e) => {
        return <span>{Comma(e || 0)}</span>;
      },
    },
    {
      title: "Price (Median) ($)",
      dataIndex: "clicks_price_median",
      align: "right",
      key: "clicks_price_median",
      render: (e) => {
        return <span>{CommaAndFloat(e || 0)}</span>;
      },
    },
    {
      title: "Same Day Shipping Speed",
      align: "right",
      dataIndex: "clicks_same_day_shipping_speed",
      key: "clicks_same_day_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "1D Shipping Speed",
      dataIndex: "clicks_1d_shipping_speed",
      key: "clicks_1d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "2D Shipping Speed",
      dataIndex: "clicks_2d_shipping_speed",
      key: "clicks_2d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Cart Adds",
      dataIndex: "cart_adds",
      align: "right",
      render: (e) => {
        return Comma(e || 0);
      },
      key: "cart_adds",
    },
    {
      title: "Price (Median) ($)",
      align: "right",
      dataIndex: "cart_adds_price_median",
      key: "cart_adds_price_median",
      render: (e) => {
        return <span>{CommaAndFloat(e || 0)}</span>;
      },
    },
    {
      title: "Same Day Shipping Speed",
      dataIndex: "cart_adds_same_day_shipping_speed",
      key: "cart_adds_same_day_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "1D Shipping Speed",
      dataIndex: "cart_adds_1d_shipping_speed",
      align: "right",
      key: "cart_adds_1d_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "2D Shipping Speed",
      dataIndex: "cart_adds_2d_shipping_speed",
      key: "cart_adds_2d_shipping_speed",
      align: "right",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Purchases",
      dataIndex: "purchases",
      align: "right",
      render: (e) => {
        return Comma(e || 0);
      },
      key: "purchases",
    },
    {
      title: "Search Traffic Sales",
      dataIndex: "purchases_search_traffic_sales",
      align: "right",
      key: "purchases_search_traffic_sales",
      render: (e) => {
        return <span>{CommaAndFloat(e || 0)}</span>;
      },
    },
    {
      title: "Conversion Rate (%)",
      dataIndex: "purchases_rating_median",
      align: "right",
      key: "purchases_rating_median",
      render: (e) => {
        return <span>{CommaAndFloat(e || 0)}</span>;
      },
    },
    {
      title: "Rating (Median)",
      align: "right",
      dataIndex: "purchases_rating_median",
      key: "purchases_rating_median",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Price (Median) ($)",
      dataIndex: "purchases_price_median",
      align: "right",
      key: "purchases_price_median",
      render: (e) => {
        return <span>{CommaAndFloat(e || 0)}</span>;
      },
    },
    {
      title: "Same Day Shipping Speed",
      dataIndex: "purchases_same_day_shipping_speed",
      align: "right",
      key: "purchases_same_day_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "1D Shipping Speed",
      dataIndex: "purchases_1d_shipping_speed",
      align: "right",
      key: "purchases_1d_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "2D Shipping Speed",
      dataIndex: "purchases_2d_shipping_speed",
      align: "right",
      key: "purchases_2d_shipping_speed",
      render: (e) => {
        return Comma(e || 0);
      },
    },
    {
      title: "Reporting Date",
      dataIndex: "reporting_date",
      key: "reporting_date",
    },
  ];
  const getList = async (data) => {
    setTableLoading(true);
    const response = await MakeApiCall(
      `scp?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${
        data?.sort_by || ""
      }&category=${data?.campaign_name || ""}&scp_type=${
        data?.campaign_status || ""
      }&start_date=${data?.start_date || filters?.start_date || ""}&end_date=${
        data?.end_date || filters?.end_date || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setList(response?.data?.records || []);
      setTableLoading(false);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
    } else {
      setLoading(false);
      setTotalPage(0);
      setTableLoading(false);
      ErrorModal(response?.message);
    }
  };
  const ImportSQP = async (data) => {
    const getToken = () => localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${getToken()}`);

    var formdata = new FormData();
    formdata.append("scp_type", data?.scp_type);
    formdata.append(
      "scp_data_file",
      data?.scp_data_file,
      data?.scp_data_file?.name
    );

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(ENDPOINT + "scp/import", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        message.destroy();
        if (result?.status === true) {
          fileInputRef.current.value = null;
          setFile({
            fileName: null,
            file: null,
          });
          setFile({
            fileName: null,
            file: null,
          });
          setPage(1);
          setLoading(true);
          getList({ page: 1, ...sortFilters, ...filters });

          SuccessModal(result?.message);
          setOpenDropdown(false);
        } else {
          fileInputRef.current.value = null;
          setFile({
            fileName: null,
            file: null,
          });
          ErrorModal(result?.message);
        }
      })
      .catch((error) => {
        message.destroy();
        fileInputRef.current.value = null;
        setFile({
          fileName: null,
          file: null,
        });
        ErrorModal(error?.message);
      });
  };
  const DeleteSCP = async (data) => {
    try {
      const response = await MakeApiCall(
        `scp/delete-all`,
        "post",
        {
          scp_ids: data?.map((d) => d?.id),
        },
        true
      );
      if (response?.status === true) {
        setSelectedRowKeys([]);

        OnOkDeleteModal(response?.message || "");
        setLoading(true);

        getList({ ...sortFilters, ...filters });
      } else if (response?.status === false) {
        ErrorModal(response?.message || "");
      }
    } catch (error) {}
  };
  useEffect(() => {
    // setLoading(true);
    getList({ ...filters, page: page, pageSize: pageSize, ...sortFilters });
    return () => {};
  }, []);

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.columnKey : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj, ...filters, page: page, pageSize: pageSize });
  };

  const presetRanges_ = [
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    { label: "This Week", value: [dayjs().startOf("week"), dayjs()] },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Quarter",
      value: [dayjs().startOf("quarter"), dayjs().endOf("quarter")],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
  ];
  const presetRanges = presetRanges_.map((range) => {
    const start = range.value[0];
    let end = range.value[1];

    // Check if the end date is in the future
    if (end.isAfter(dayjs())) {
      end = dayjs(); // Replace with the current date
    }

    return {
      ...range,
      value: [start, end],
    };
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRows);
    },
    selectedRowKeys: selectedRowKeys?.map((d) => d?.id), // This binds the selected keys with the component state
  };

  return (
    <div className="card custom-ui">
      <div className="card-header">
        <div className="card-title">
          Search Catalog Performance
          <InfoVideo
            data={locationData}
            title="SB Targeting"
            className={"ms-3"}
          />
          <Input
            onPressEnter={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            value={filters?.campaign_name}
            allowClear
            onChange={(e) => {
              setFilters((prev) => {
                if (!e.target.value && prev) {
                  getList({
                    ...{
                      ...prev,
                      campaign_name: e.target.value,
                    },
                    ...sortFilters,
                    page: page,
                    pageSize: pageSize,
                  });
                }
                return {
                  ...prev,
                  campaign_name: e.target.value,
                };
              });
            }}
            placeholder="Search Category / ASIN"
            className="w-200px ms-3"
          />
          <button
            onClick={() => {
              getList({
                ...filters,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
            }}
            className="btn btn-sm ms-3 btn-search me-3"
            style={{ padding: "5px 15px" }}
          >
            <svg
              width={15}
              height={15}
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m21 21-2.243-2.247-2.243-2.247" />
              <path d="M19 10.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0Z" />
            </svg>
          </button>
          <Select placeholder="Select Brand" className="w-200px ms-3" />
        </div>
        <div className="card-toolbar">
          <DatePickerView
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.start_date)?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.end_date)?.format("YYYY-MM-DD"),
              };
              getList({
                ...obj,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
              setFilters(obj);
            }}
            value={[filters?.start_date, filters?.end_date]}
          />
          {/* <DatePicker.RangePicker
            className="ant_common_input"
            id="argo_date_picker"
            presets={presetRanges__}
            allowClear={false}
            style={{ width: "290px" }}
            dropdownClassName={"createDateRangePicker"}
            showWeek
            calendars={1}
            format={"MMM DD, YYYY"}
            // showTime
            value={[all_filter?.start_date, all_filter?.end_date]}
            disabledDate={(current) => {
              // Disable dates before January 1, 2023 and after the current date
              return (
                current && (current < dayjs("2023-01-01") || current > dayjs())
              );
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            ref={rangePickerRef}
            onCalendarChange={(event, value) => {
              const filterKey = handleDateRangeChange(value, event);
              if (filterKey === "custom") {
                const filters_ = all_filter;
                filters_.start_date = dayjs(event?.[0]).startOf("week");
                filters_.end_date = dayjs(event?.[0]).endOf("week");
                set_all_filters({ ...filters_ });
                setIsOpenCal(false);
              } else {
                console.log(event?.[0], event?.[1], "event?.[0]");
                const filters_ = all_filter;
                filters_.start_date = event?.[0];
                filters_.end_date = event?.[1];
                set_all_filters({ ...filters_ });
              }
              if (rangePickerRef.current) {
                rangePickerRef.current.blur();
              }
            }}
            open={isOpenCal}
            onOpenChange={(e) => {
              setIsOpenCal(e);
            }}
          /> */}
          {/* <DatePicker.RangePicker
            allowClear
            // presets={presetRanges}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: dayjs(e?.[0])?.format("YYYY-MM-DD"),
                end_date: dayjs(e?.[1])?.format("YYYY-MM-DD"),
              };
              getList({
                ...obj,
                ...sortFilters,
                page: page,
                pageSize: pageSize,
              });
              setFilters(obj);
            }}
            className="w-250px me-3"
            disabledDate={(current) => {
              return current && current > moment().endOf("day");
            }}
          /> */}
          <ExportFile
            title="Search Catalog Preformance"
            hideTooltip={filters?.start_date && filters?.end_date}
            api={`scp?field_name=${sortFilters?.field_name || ""}&sort_by=${
              sortFilters?.sort_by || ""
            }&category=${filters?.campaign_name || ""}&scp_type=${
              filters?.campaign_status || ""
            }&start_date=${filters?.start_date || ""}&end_date=${
              filters?.end_date || ""
            }&is_export=1`}
          />
          <Dropdown
            menu={{
              items: [],
            }}
            trigger={["click"]}
            className="me-5"
            open={openDropdown}
            onOpenChange={(e) => {
              setOpenDropdown(e);
              if (!e) {
                fileInputRef.current.value = null;
                setFile({
                  fileName: null,
                  file: null,
                });
              }
            }}
            placement="bottomRight"
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu, {
                  style: menuStyle,
                })}

                <div className="px-7 py-5">
                  <div
                    className="mb-10 position-relative"
                    style={{
                      border: "1px solid #c9c9c9",
                      padding: "8px 30px 8px 8px",
                      borderRadius: "10px",
                    }}
                  >
                    <input
                      // className="form-control form-control-sm form-control-solid"
                      // style={{
                      //   backgroundColor: "#b8e7ff",
                      // }}
                      placeholder
                      type="file"
                      accept="text/csv"
                      value={file?.fileName}
                      ref={fileInputRef}
                      onChange={(e) => {
                        setFile({
                          fileName: e.target.value,
                          file: e.target.files?.[0],
                        });
                      }}
                      id="formFile"
                    />
                    <i
                      onClick={() => {
                        fileInputRef.current.value = null;
                        setFile({
                          fileName: null,
                          file: null,
                        });
                      }}
                      className="ki-outline ki-cross-square fs-1 "
                      style={{
                        position: "absolute",
                        right: "4px",
                        top: "11px",
                        bottom: "0px",
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-end">
                    <Button
                      type="primary"
                      // className="btn btn-sm btn-dark"
                      onClick={() => {
                        const obj = {
                          scp_data_file: file?.file,
                          scp_type: filters.campaign_status,
                        };

                        ImportSQP(obj);
                      }}
                    >
                      Upload
                    </Button>
                  </div>
                  {/*end::Actions*/}
                </div>
              </div>
            )}
          >
            <Button onClick={(e) => e.preventDefault()} type="dashed">
              Upload
            </Button>
          </Dropdown>

          <Button
            onClick={() => {
              DeleteCModal(null, () => DeleteSCP(selectedRowKeys));
            }}
            danger
            className="ms-5"
            type="dashed"
          >
            Delete {selectedRowKeys?.length === list?.length ? "All" : ""}
          </Button>
        </div>
      </div>
      <div className="card-body  pt-0">
        {tableLoading ? (
          <TableLoading
            id="test-table"
            columns={[1, 2, 3, 4, 5]}
            checkBoxVal={true}
            actions={[1, 2]}
          />
        ) : list?.length === 0 ? (
          <Empty />
        ) : (
          <div className="">
            <Table
              dataSource={list}
              loading={tableLoading}
              fixed={true}
              sticky={{
                offsetHeader: "0px",
              }}
              rowSelection={rowSelection}
              scroll={{ x: "max-content" }}
              columns={columns?.map((d) => ({
                ...d,
                ...PropsFilter(d?.dataIndex),
                render: (props, row, index) =>
                  RenderTable(props, row, index, d),
              }))}
              pagination={false}
              onChange={handleChange}
              rowClassName={(record, index) => {
                return index % 2 === 0 ? "even-row" : "odd-row";
              }}
              bordered
              rowKey={"id"}
              size="small"
            />
          </div>
        )}
        <Pagination
          loading={loading}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={(e) => {
            setLoading(true);
            setPageSize(e);
            setPage(1);
            getList({
              page: 1,
              pageSize: e,
              ...sortFilters,
              ...filters,
            });
          }}
          onPageNo={(e) => {
            setLoading(true);
            setPage(e);
            getList({
              page: e,
              pageSize: pageSize,
              ...sortFilters,
              ...filters,
            });
          }}
        />
      </div>
    </div>
  );
};

export default SearchCatalogPerformance;
