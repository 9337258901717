import React, { useContext, useEffect } from "react";
import { Wrapper } from "./style";
import { Modal, Watermark, message } from "antd";
import { GlobalContext } from "../../common-context";
import { ORIGIN } from "../../config";

const CredentialsError = ({ type, show = false }) => {
  const contextValue = useContext(GlobalContext);
  const { credentials, user_ } = contextValue?.data;

  const [modal, contextHolder] = Modal.useModal();
  useEffect(() => {
    message.destroy();
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(credentials?.advertising_client_id);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);

    return () => {};
  }, []);

  const config = {
    title: "Connections",
    content: (
      <>
        <div>
          It appears that you have not yet connected your Sellercentral account
          to SIP. Please connect to Sellercentral before connecting to your
          Advertising account.
        </div>
      </>
    ),
  };

  const openModal = async () => {
    const confirmed = await modal.confirm(config);
    if (confirmed) {
      return window?.open(
        `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${
          credentials?.lwa_application_id
        }&state=${user_?.email}!!${user_?.name.replace(
          /\s/g,
          ""
        )}!!ATVPDKIKX0DER&redirect_uri=${`${contextValue?.data?.credentials?.lwa_return_url}`}&version=beta`
      );
    }
  };

  const All = (
    <div
      className="card mb-10"
      style={{
        backgroundColor: "#F3F9FA",
        border: "1px solid #e5e5e5 !important",
        boxShadow: "none !important",
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-md-5">
            <h3 className="mb-4">
              Connect Your Seller Account and Unblock SIP's Potential
            </h3>
            <p className="fs-6 " style={{ lineHeight: "1.7" }}>
              <b>Welcome to a transformational journey! </b> By seamlessly
              connecting your Amazon seller account to SIP, you're opening the
              gateway to unparalleled growth and Optimization. Imagine
              harnessing the power of predictive analytics, real-time
              insights,and targeted recommendations tailored to your unique
              business needs. With SIP, you'll not only stramline your
              operations but also future-proof your Amazon success.
            </p>
            <div className="mt-9 d-flex">
              <div className="text-center">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1024px-Amazon.com-Logo.svg.png"
                  alt
                  className="h-25px mb-3"
                />
                <button
                  onClick={() => {
                    window?.open(
                      `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${
                        credentials?.lwa_application_id
                      }&state=${user_?.email}!!${user_?.name.replace(
                        /\s/g,
                        ""
                      )}!!ATVPDKIKX0DER&redirect_uri=${`${contextValue?.data?.credentials?.lwa_return_url}`}&version=beta`
                    );
                  }}
                  className="btn btn-success fs-7 px-4"
                >
                  Connect to <b>Amazon</b>
                </button>
              </div>
              <div className="text-center ms-5">
                <img
                  src="https://static.wixstatic.com/media/e61c50_8be22736399d4eb8b11d650054c4472e~mv2.webp"
                  className="h-25px mb-3"
                  alt
                />
                <button
                  onClick={() => {
                    if (contextValue?.data?.credentialsStatus?.sp !== 1) {
                      openModal();
                      return;
                    }
                    let options = {};
                    options.scope = "advertising::campaign_management";
                    options.response_type = "code";
                    window.amazon.Login.authorize(
                      options,
                      `${
                        credentials?.advertising_return_url || ""
                      }?marketplace_id=ATVPDKIKX0DER&seller_name=${
                        user_?.seller_name || ""
                      }`
                    );
                  }}
                  className="btn btn-success fs-7 px-4"
                >
                  Connect to <b>Amazon</b>
                </button>
              </div>
              <div className=" text-center ms-5">
                <img src="/assets/media/sip.png" className="h-75px" alt />
              </div>
            </div>
          </div>
          <div className="col-md-6 ms-auto">
            <img
              src="/assets/media/amzsellercentral.png"
              className="img-fluid"
              style={{
                maxHeight: "230px",
              }}
              alt
            />
          </div>
        </div>
      </div>
    </div>
  );
  const LWS = (
    <div
      className="card mb-10"
      style={{
        backgroundColor: "#F3F9FA",
        border: "1px solid #e5e5e5 !important",
        boxShadow: "none !important",
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-md-5">
            <h3 className="mb-4 mt-5">
              Thank You For Connecting Your Sellercentral Account To SIP.
            </h3>
            <p className="fs-6 " style={{ lineHeight: "1.7" }}>
              <b>We need you to take one more step.</b> By connecting your
              Amazon Advertising account, you will witness the power of
              predictive analytics, real-time insights, and targeted
              recommendations tailored to your unique business needs.
            </p>
            <div className="mt-9 d-flex align-items-end">
              <div className="text-center w-25">
                <img
                  src="https://static.wixstatic.com/media/e61c50_8be22736399d4eb8b11d650054c4472e~mv2.webp"
                  alt
                  className="h-25px mb-3"
                />
                <button
                  onClick={() => {
                    let options = {};
                    options.scope = "advertising::campaign_management";
                    options.response_type = "code";
                    window.amazon.Login.authorize(
                      options,
                      `${
                        credentials?.advertising_return_url || ""
                      }?marketplace_id=ATVPDKIKX0DER&seller_name=${
                        user_?.seller_name || ""
                      }`
                    );
                  }}
                  className="btn btn-success fs-7 px-4"
                >
                  Connect to <b>Amazon</b>
                </button>
              </div>
              <div className="text-center ms-5 w-25"></div>
              <div className=" text-center ms-5 w-25">
                <img src="/assets/media/sip.png" className="h-75px" alt />
              </div>
            </div>
          </div>
          <div className="col-md-6 ms-auto">
            <img
              src="/assets/media/amzsellercentral.png"
              className="img-fluid"
              style={{
                maxHeight: "230px",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
  const SP = (
    <div
      className="card mb-10"
      style={{
        backgroundColor: "#FFF7D8",
        border: "1px solid #e5e5e5 !important",
        boxShadow: "none !important",
      }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-md-6 ">
            <h3 className="mb-4 mt-5">
              Thank you for connecting your Amazon Advertising account to SIP.
            </h3>
            <p className="fs-6 " style={{ lineHeight: "1.7" }}>
              <b>We need you to take one more step. </b> By connecting your
              Amazon Sellercentral account, you will witness the power of
              predictive analytics, real-time insights, 7 and targeted
              recommendations tailored to your unique business needs
            </p>
            <div className="mt-md-15 d-flex">
              <div className="text-center d-grid">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1024px-Amazon.com-Logo.svg.png"
                  alt
                  className="h-25px mb-3"
                />
                <button
                  onClick={() => {
                    window?.open(
                      `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${
                        credentials?.lwa_application_id
                      }&state=${user_?.email}!!${user_?.name.replace(
                        /\s/g,
                        ""
                      )}!!ATVPDKIKX0DER&redirect_uri=${`${contextValue?.data?.credentials?.lwa_return_url}`}&version=beta`
                    );
                  }}
                  className="btn btn-success fs-7 px-4"
                >
                  Connect to <b>Amazon</b>
                </button>
              </div>
              <div className="text-center ms-5 w-25"></div>
              <div className=" text-center ms-5 w-25">
                <img src="/assets/media/sip.png" className="h-75px" alt />
              </div>
            </div>
          </div>
          <div className="col-md-6 ms-auto">
            <img
              src="/assets/media/amzsellercentral.png"
              className="img-fluid"
              style={{
                maxHeight: "230px",
              }}
              alt
            />
          </div>
        </div>
      </div>
    </div>
  );
  const modulesView = {
    all: All,
    SP: SP,
    LWS: LWS,
  };

  return (
    <Wrapper className="px-10">
      {show && (
        <>
          {
            modulesView?.[
              contextValue?.data?.credentialsStatus?.sp !== 1 &&
              contextValue?.data?.credentialsStatus?.lws !== 1
                ? "all"
                : contextValue?.data?.credentialsStatus?.lws === 1
                ? "SP"
                : "LWS"
            ]
          }
        </>
      )}
      {!show && (
        <>
          {(user_?.amazon_connected !== 1 ||
            user_?.amazon_ads_connected !== 1) && (
            <>
              <div className="row bdr mb-5">
                <div className="col-md-12 text-center cus-arrow">
                  <div className="d-flex justify-content-center align-items-center">
                    <i className="ki-outline ki-double-down fs-3x text-gray-700" />
                    <h4 className="text-gray-700 fw-semibold">
                      View Sample Data Below
                    </h4>
                    <i className="ki-outline ki-double-down fs-3x text-gray-700" />
                  </div>
                </div>
              </div>
              <Watermark content="Sample">
                <img
                  src={`/assets/media/${type}.png`}
                  style={{ width: "100%" }}
                />
              </Watermark>
            </>
          )}
        </>
      )}
      <div id="amazon-root" />
      {contextHolder}
    </Wrapper>
  );
};

export default CredentialsError;
