import { Badge } from "antd";
import { GetLinks } from "../../../config";

export default [
  {
    key: "15",
    icon: (
      <i className="ki-duotone ki-focus fs-1">
        <span className="path1" />
        <span className="path2" />
      </i>
    ),

    label: (
      <>
        Logs <Badge className="ms-2" count={2} showZero color="#01c0c8" />
      </>
    ),
    children: [
      {
        key: "/logs/seller-activity",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-slider-horizontal fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </i>
          </span>
        ),
        label: GetLinks("/logs/seller-activity", "Seller Activity"),
      },
      {
        key: "/logs/admin-alerts",
        icon: (
          <span className="menu-bullet">
            <i className="ki-duotone ki-code fs-1">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
            </i>
          </span>
        ),
        label: GetLinks("/logs/admin-alerts", "Admin Alerts"),
      },
    ],
  },
  // {
  //   key: "/seller-activity",
  //   icon: (
  // <i className="ki-duotone ki-focus fs-1">
  //   <span className="path1" />
  //   <span className="path2" />
  // </i>
  //   ),
  //   label: GetLinks("/seller-activity", "Seller Activity"),
  // },
];
