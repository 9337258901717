import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";

import { Select, Table, message } from "antd";
import { MakeApiCall } from "../../../apis";
import { RenderTable } from "../../../config";
import Pagination from "../../../components/pagination";

const MappingFinanceEvents = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [unList, setUnList] = useState([]);
  const [unLoading, setUnLoading] = useState(true);

  const [ignoreList, setIgnoreList] = useState([]);
  const [ignoreLoading, setIgnoreLoading] = useState(true);

  const [lineItemList, setLineItemList] = useState([]);
  const [lineItemLoading, setlLineItemLoading] = useState(true);

  const [lineItemsList, setLineItemsList] = useState({});

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [totalPage_ignore, setTotalPage_ignore] = useState(0);
  const [pageSize_ignore, setPageSize_ignore] = useState(20);
  const [page_ignore, setPage_ignore] = useState(1);

  const [totalPage_, setTotalPage_] = useState(0);
  const [pageSize_, setPageSize_] = useState(20);
  const [page_, setPage_] = useState(1);

  const GetIgnoreList = async (data) => {
    const response = await MakeApiCall(
      `finance-mapping/mapped-fees?field_name=line_items&sort_by=asc&is_ignore=1&page=${
        data?.page || page_ignore || 1
      }&per-page=${data?.pageSize || pageSize_ignore}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      console.log(response, "response");

      setTotalPage_ignore(response?.data?.pagination?.totalCount || 0);
      setIgnoreList(response?.data?.records || []);
      setIgnoreLoading(false);
    } else {
      message.warning(response?.message);
      setIgnoreLoading(false);
      setIgnoreList([]);
    }
  };
  const GetList = async (data) => {
    const response = await MakeApiCall(
      `finance-mapping/mapped-fees?is_mapped=1&is_ignore=0&page=${
        data?.page || page_ || 1
      }&per-page=${data?.pageSize || pageSize_}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage_(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  const GetUnList = async (data) => {
    const response = await MakeApiCall(
      `finance-mapping/un-mapped-fees?is_mapped=0&is_ignore=0&page=${
        data?.page || page || 1
      }&per-page=${data?.pageSize || pageSize}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setUnList(response?.data?.records || []);
      setUnLoading(false);
    } else {
      message.warning(response?.message);
      setUnLoading(false);
      setUnList([]);
    }
  };

  const UpdateMapping = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/update/${data?.id}`,
      "put",
      {
        line_items: data?.line_items,
      },
      true
    );
    if (response?.status) {
      setLoading(true);
      GetList({
        page: page_,
        pageSize: pageSize_,
      });
      setUnLoading(true);

      GetUnList({
        page: page,
        pageSize: pageSize,
      });
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const UpdateIsMapping = async (data, body) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/${Object.hasOwn(body, "is_ignore") ? 'ignore' :  'update'}/${data?.id}`,
      "put",
      body,
      true
    );
    if (response?.status) {
      if (Object.hasOwn(body, "is_mapped")) {
        setUnLoading(true);
        GetUnList({
          page: page,
          pageSize: pageSize,
        });
        setLoading(true);

        GetList({
          page: page_,
          pageSize: pageSize_,
        });
      } else if (Object.hasOwn(body, "is_ignore")) {
        setUnLoading(true);
        GetUnList({
          page: page,
          pageSize: pageSize,
        });
        setIgnoreLoading(true);
        GetIgnoreList({
          page: 1,
          pageSize: pageSize_ignore,
        });
      }
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const FinanceMappingSyncFees = async (data, body) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `finance-mapping/sync-fees/${data?.id}`,
      "put",
      body,
      true
    );
    if (response?.status) {
    
        setUnLoading(true);
        GetUnList({
          page: page,
          pageSize: pageSize,
        });
        setLoading(true);
      GetList({
        page: page_,
        pageSize: pageSize_,
      });
      
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const GetFinanceMapping = async () => {
    const response = await MakeApiCall(
      "finance-mapping/get-line-items",
      "get",
      null,
      true
    );
    if (response?.status) {
      setLineItemList(
        Object.entries(response?.data || {})?.map(([key, value]) => ({
          label: value,
          value: key,
        }))
      );

      setLineItemsList(response?.data);
      setlLineItemLoading(false);
    } else {
      message.warning(response?.message);
      setlLineItemLoading(false);
      setUnList([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    GetList({
      page: 1,
      pageSize: pageSize_,
    });

    setUnLoading(true);
    GetUnList({
      page: 1,
      pageSize: pageSize,
    });

    setlLineItemLoading(true);
    GetFinanceMapping();

    setIgnoreLoading(true);
    GetIgnoreList({
      page: 1,
      pageSize: pageSize_ignore,
    });
    return () => {};
  }, []);
  const PropsFilter = (type) => {
    return {
      sorter: (a, b) => {
        return typeof a?.[type] === "string"
          ? (a?.[type] || "")?.localeCompare(b?.[type] || "")
          : parseFloat(a?.[type] || 0) - parseFloat(b?.[type] || 0);
      },
    };
  };

  const columns_ = [
    {
      title: "Type",
      dataIndex: "finance_type",
      filterIndex: "finance_type",

      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "New Line Items",
      dataIndex: "fees_name",
      filterIndex: "fees_name",

      render: (e, row) => {
        if (e) {
          return <span>{e}</span>;
        }
        if (row?.finance_column_name) {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {row?.finance_column_name?.split("_")?.join(" ")?.toLowerCase()}
            </span>
          );
        }
        return <span>-</span>;
      },
    },

    {
      title: "Seller Name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      filterIndex: "marketplace",
    },
    {
      title: "Our Line Items",
      dataIndex: "ourLineItems",
      filterIndex: "ourLineItems",
      render: (_, record) => (
        <Select
          loading={lineItemLoading}
          allowClear
          options={lineItemList}
          placeholder="Select Our Line Items"
          value={record?.line_items || null}
          onChange={(e) => {
            setUnList((d) => {
              return d?.map((r) => {
                 
                if (r?.finance_type === record?.finance_type && r?.fees_name === record?.fees_name) {
                  return { ...r, line_items: e ? e : 'empty' };
                }
                return { ...r };
              });
            });
          }}
          style={{ width: 200 }}
        />
      ),
    },
    {
      title: "Action",

      render: (_, record) => {
        const disabled = record?.line_items || record?.line_items === 'empty' ? false : true;
        const disabled_ = record?.line_items  ? false : true;
        return (
          <div className="d-flex">
            <button
              disabled={disabled}
              onClick={() => {
                UpdateMapping(record);
              }}
              className="add-btn"
              style={{
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? "not-allowed" : "pointer",
              }}
            >
              save
            </button>

            <button
              disabled={disabled_}
              onClick={() => {
                FinanceMappingSyncFees(record, {
                  is_mapped: 1,
                });
              }}
              className="btn btn-sm btn-search ms-2"
              style={{
                height: "35px",
                paddingTop: "7px",
                opacity: disabled_ ? 0.5 : 1,
                cursor: disabled_ ? "not-allowed" : "pointer",
              }}
            >
              <svg
                width={16}
                height={16}
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="m3.009 12.403-1.839 1.85L0 13.098l3.135-3.105 1.14.015 3.135 3.18-1.14 1.14-1.75-1.77a7.5 7.5 0 0 0 14.1 2.974l1.219.896a9 9 0 0 1-16.83-4.025Zm16.485-.699L17.64 9.828l-1.14 1.14 3.135 3.165 1.14.015 3.135-3.105-1.125-1.14-1.791 1.77a9 9 0 0 0-16.665-4.38l1.215.89a7.5 7.5 0 0 1 13.95 3.52v.001Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                UpdateIsMapping(record, {
                  is_ignore: 1,
                });
              }}
              className="btn btn-sm btn-secondary ms-2"
              style={{ height: "35px", paddingTop: "7px" }}
            >
              Ignore
            </button>
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      filterIndex: "key",
      render: (e) => {
        return <span>{e}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "finance_type",
      filterIndex: "finance_type",

      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Amazon Fee",
      dataIndex: "fees_name",
      filterIndex: "fees_name",
      render: (e, row) => {
        if (e) {
          return <span>{e}</span>;
        }
        if (row?.finance_column_name) {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {row?.finance_column_name?.split("_")?.join(" ")?.toLowerCase()}
            </span>
          );
        }
        return <span>-</span>;
      },
    },

    {
      title: "Seller Name",
      dataIndex: "seller_name",
      filterIndex: "seller_name",

      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Our Line Item",
      dataIndex: "line_items",
      filterIndex: "line_items",

      render: (e) => {
        return lineItemsList?.[e] || "-";
      },
    },
  ];

  return (
    <Wrapper
      id="kt_content_container"
      className="custom-ui container-fluid mt-5"
    >
      <div
        className="row g-5 g-xl-7 mb-5 mb-xl-10"
        data-select2-id="select2-data-22-izld"
      >
        {/*begin::Col*/}
        <div className="col-xl-12" data-select2-id="select2-data-21-pqt3">
          <div className="card" data-select2-id="select2-data-20-jyf3">
            <div className="card-header">
              <div className="card-title">Finance Fees</div>
            </div>
            <div
              className="card-body pt-0"
              data-select2-id="select2-data-19-nize"
            >
              <div>
                <Table
                  dataSource={unList}
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  columns={columns_?.map((d) => ({
                    ...d,
                    ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d),
                  }))}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  pagination={false}
                  loading={unLoading}
                  bordered
                  size="small"
                  scroll={{ x: "max-content" }}
                />
                <Pagination
                  loading={unLoading}
                  pageSize={pageSize}
                  page={page}
                  totalPage={totalPage}
                  onPerPage={(e) => {
                    setUnLoading(true);
                    setPageSize(e);
                    setPage(1);
                    GetUnList({
                      page: 1,
                      pageSize: e,
                    });
                  }}
                  onPageNo={(e) => {
                    setUnLoading(true);
                    setPage(e);
                    GetUnList({
                      page: e,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header">
              <div className="card-title">Pending mapping</div>
            </div>
            <div
              className="card-body pt-0"
              data-select2-id="select2-data-19-nize"
            >
              <div>
                <Table
                  dataSource={list?.map((d, i) => ({ ...d, key: i + 1 }))}
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  columns={columns?.map((d) => ({
                    ...d,
                    ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                    render: (props, row, index) =>
                      RenderTable(props, row, index, d),
                  }))?.filter((d) => d?.title !== 'Seller Name')}
                  loading={loading}
                  pagination={false}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  scroll={{ x: "max-content" }}
                />
                <Pagination
                  loading={loading}
                  pageSize={pageSize_}
                  page={page_}
                  totalPage={totalPage_}
                  onPerPage={(e) => {
                    setLoading(true);
                    setPageSize_(e);
                    setPage_(1);
                    GetList({
                      page: 1,
                      pageSize: e,
                    });
                  }}
                  onPageNo={(e) => {
                    setLoading(true);
                    setPage_(e);
                    GetList({
                      page: e,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-header">
              <div className="card-title">Ignored</div>
            </div>
            <div
              className="card-body pt-0"
              data-select2-id="select2-data-19-nize"
            >
              <div>
                <Table
                  dataSource={ignoreList?.map((d, i) => ({ ...d, key: i + 1 }))}
                  fixed={true}
                  sticky={{
                    offsetHeader: "0px",
                  }}
                  columns={[
                    ...columns?.map((d) => ({
                      ...d,
                      ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                      render: (props, row, index) =>
                        RenderTable(props, row, index, d),
                    })),
                    {
                      title: "Action",

                      render: (_, record) => (
                        <div className="d-flex">
                          <button
                            onClick={() => {
                              UpdateIsMapping(record, {
                                is_ignore: 0,
                              });
                            }}
                            className="btn btn-sm btn-secondary ms-2"
                            style={{ height: "35px", paddingTop: "7px" }}
                          >
                            Restore
                          </button>
                        </div>
                      ),
                    },
                  ]}
                  loading={ignoreLoading}
                  pagination={false}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  scroll={{ x: "max-content" }}
                />
                <Pagination
                  loading={ignoreLoading}
                  pageSize={pageSize_ignore}
                  page={page_ignore}
                  totalPage={totalPage_ignore}
                  onPerPage={(e) => {
                    setLoading(true);
                    setPageSize_ignore(e);
                    setPage_ignore(1);
                    GetList({
                      page: 1,
                      pageSize: e,
                    });
                  }}
                  onPageNo={(e) => {
                    setLoading(true);
                    setPage_ignore(e);
                    GetList({
                      page: e,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MappingFinanceEvents;
