import axios from "axios";
import { ENDPOINT, LOGINURL } from "../config";

const getToken = () => localStorage.getItem("token");
// Common function for making API calls
export const MakeApiCall = async (
  url,
  method = "get",
  data = null,
  withBearer = false,
  headers_ = {}
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      ...headers_,
    };

    if (withBearer) {
      if (getToken()) {
        headers["Authorization"] = `Bearer ${getToken()}`;
      }
    }
    let url_ = ENDPOINT + url;
    const response = await axios({
      method,
      url: url_,
      data: data || null,
      headers,
      redirect: "follow",
    });

    return response.data;
  } catch (error) {
    if (error?.response?.status === 401) {
      const LOGINURL_ = LOGINURL;
      localStorage.clear();
      return window.location.assign(LOGINURL_);
    }
    // Handle errors here (e.g., log them or throw a custom error)
    throw { message: error?.message };
  }
};
