import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Tabs, Table, Input, message } from "antd";
import { EditIcon } from "../../../../config";
import { MakeApiCall } from "../../../../apis";

const { TabPane } = Tabs;

const AdDefinitions = () => {
  const [editRow, setEditRow] = useState({});

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const adDefinationData = async (data) => {
    const response = await MakeApiCall(`ad-definition`, "get", null, true);
    if (response?.status) {
      setList(response?.data?.records || []);

      setLoading(false);
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };

  const UpdateadDefinationData = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `ad-definition/${data?.id}`,
      "put",
      {
        mapped_ad_type: data?.mapped_ad_type,
      },
      true
    );
    if (response?.status) {
      adDefinationData();
      setEditRow("");
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();

      message.warning(response?.message);
    }
  };

  const SyncUpdateadDefinationData = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `ad-definition/${id}`,
      "put",
      {
        is_sync: 1,
      },
      true
    );
    if (response?.status) {
      adDefinationData();
      setEditRow("");
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();

      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    adDefinationData();
    return () => {};
  }, []);

  const updateMappedAdType = (adProduct, adType, id, newMappedAdType) => {
    setList((currentData) => {
      return {
        ...currentData,
        [adProduct]: {
          ...currentData[adProduct],
          [adType]: currentData[adProduct][adType].map((item) => {
            if (item.id === id) {
              return { ...item, mapped_ad_type: newMappedAdType, isAPI: false };
            }
            return item;
          }),
        },
      };
    });
  };

  const columns = [
    {
      title: "DB Name",
      dataIndex: "ad_type_value",
      key: "ad_type_value",
      width: 300,
    },
    {
      title: "New Name",
      width: 300,
      render: (d) => {
        if (d?.is_sync === 0) {
          return (
            <Input
              className="w-150px me-3"
              placeholder="Enter New Name"
              value={d?.mapped_ad_type}
              onChange={(e) => {
                updateMappedAdType(
                  d?.ad_product,
                  d?.ad_type,
                  d?.id,
                  e.target.value
                );

                setEditRow({
                  ...editRow,
                  mapped_ad_type: e.target.value,
                });
              }}
            />
          );
          // return (
          //   <div className="d-flex align-items-center justify-content-start">
          //     <Input
          //       className="w-150px me-3"
          //       placeholder="Enter New Name"
          //       value={editRow?.mapped_ad_type}
          //       onChange={(e) => {
          //         setEditRow({
          //           ...editRow,
          //           mapped_ad_type: e.target.value,
          //         });
          //       }}
          //     />
          //     <button
          //       onClick={() => {
          //         UpdateadDefinationData(editRow);
          //       }}
          //       className="add-btn"
          //     >
          //       Save
          //     </button>
          //     <button
          //       onClick={() => {
          //         setEditRow("");
          //       }}
          //       style={{ padding: "7px" }}
          //       className="btn  btn-sm ms-3 btn-red"
          //     >
          //       Close
          //     </button>
          //   </div>
          // );
        }
        return (
          <div>
            <span className="me-3">{d?.mapped_ad_type}</span>
            {/* {d?.mapped_ad_type && d?.is_sync === 0 && (
              <svg
                onClick={() => {
                  SyncUpdateadDefinationData(d?.id);
                }}
                width={18}
                height={18}
                fill="#000"
                className="me-3 cursor-pointer"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="m3.009 12.403-1.839 1.85L0 13.098l3.135-3.105 1.14.015 3.135 3.18-1.14 1.14-1.75-1.77a7.5 7.5 0 0 0 14.1 2.974l1.219.896a9 9 0 0 1-16.83-4.025Zm16.485-.699L17.64 9.828l-1.14 1.14 3.135 3.165 1.14.015 3.135-3.105-1.125-1.14-1.791 1.77a9 9 0 0 0-16.665-4.38l1.215.89a7.5 7.5 0 0 1 13.95 3.52v.001Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {d?.is_sync === 0 && (
              <span className=" cursor-pointer" onClick={() => setEditRow(d)}>
                {EditIcon}
              </span>
            )} */}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 350,
      render: (d) => {
        // if (!d?.mapped_ad_type || !d?.isAPI) {
        //   return (
        //     <div className="d-flex align-items-center justify-content-start">
        //       <button
        //         onClick={() => {
        //           UpdateadDefinationData(editRow);
        //         }}
        //         className="add-btn"
        //       >
        //         Save
        //       </button>
        //       <button
        //         onClick={() => {
        //           setEditRow("");
        //         }}
        //         style={{ padding: "7px" }}
        //         className="btn  btn-sm ms-3 btn-red"
        //       >
        //         Close
        //       </button>
        //     </div>
        //   );
        // }
        return (
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start gap-3">
              {d?.is_sync === 0 && (
                <>
                  <button
                    onClick={() => {
                      UpdateadDefinationData(d);
                    }}
                    className="add-btn"
                  >
                    Save
                  </button>
                </>
              )}
              {d?.mapped_ad_type && d?.is_sync === 0 && (
                <svg
                  onClick={() => {
                    SyncUpdateadDefinationData(d?.id);
                  }}
                  width={18}
                  height={18}
                  fill="#000"
                  className="me-3 cursor-pointer"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="m3.009 12.403-1.839 1.85L0 13.098l3.135-3.105 1.14.015 3.135 3.18-1.14 1.14-1.75-1.77a7.5 7.5 0 0 0 14.1 2.974l1.219.896a9 9 0 0 1-16.83-4.025Zm16.485-.699L17.64 9.828l-1.14 1.14 3.135 3.165 1.14.015 3.135-3.105-1.125-1.14-1.791 1.77a9 9 0 0 0-16.665-4.38l1.215.89a7.5 7.5 0 0 1 13.95 3.52v.001Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
              {/* {d?.is_sync === 0 && (
                <span className=" cursor-pointer" onClick={() => setEditRow(d)}>
                  {EditIcon}
                </span>
              )} */}
            </div>
          </div>
        );
      },
    },
  ];

  const MarketingTabs = (props) => {
    return (
      <div className="card">
        <div className="card-body pt-2">
          <Tabs defaultActiveKey="1">
            {Object.entries(props || {})?.map(([key, value], i) => (
              <TabPane
                tab={
                  <span style={{ textTransform: "capitalize" }}>
                    {key?.replace("_", " ")?.toLocaleLowerCase()}
                  </span>
                }
                className="pt-5"
                key={i?.toString()}
              >
                <Table
                  scroll={{ x: "max-content" }}
                  pagination={false}
                  rowClassName={(record, index) => {
                    return index % 2 === 0 ? "even-row" : "odd-row";
                  }}
                  bordered
                  size="small"
                  columns={columns}
                  loading={loading}
                  dataSource={value}
                />
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    );
  };

  return (
    <Wrapper id="kt_content_container" className="custom-ui container-fluid">
      <div className="row g-2 mt-2">
        <div className="col-xxl-12">
          <Tabs defaultActiveKey="1" type="card">
            {Object.entries(list || {})?.map(([key, value], i) => (
              <TabPane
                tab={
                  <span style={{ textTransform: "capitalize" }}>
                    {key?.replace("_", " ")?.toLocaleLowerCase()}
                  </span>
                }
                key={i?.toString()}
              >
                {MarketingTabs(value)}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdDefinitions;
