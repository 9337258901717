import { Tooltip, message } from "antd";
import React from "react";
import { MakeApiCall } from "../../../../apis";
import * as XLSX from "xlsx";
import { ErrorModal } from "../../../../components/upload-modals";

const ExportFile = ({ api, title, hideTooltip, style }) => {
  // api

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const downloadExcel = (data) => {
    const wb = XLSX.utils.book_new();
    message.destroy();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    const excelBinaryString = XLSX.write(wb, {
      bookType: "xlsx",
      type: "binary",
    });

    const blob = new Blob([s2ab(excelBinaryString)], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${title}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.destroy();
  };

  const ExportAction = async () => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(api, "get", null, true);

      if (response?.status) {
        if (response?.data?.records?.length === 0)
          return ErrorModal("No Data Found");
        downloadExcel(response?.data?.records);
      } else {
        ErrorModal(response?.message);
      }
    } catch (error) {
      message.destroy();
      ErrorModal();
    }
  };
  const ExportBTN = (
    <div
      className="me-5"
      style={{
        background: "#00B660",
        borderRadius: 6,
        padding: "6px 15px",
        cursor: "pointer",
        ...style,
      }}
      onClick={() => {
        ExportAction();
      }}
    >
      <div
        style={{
          color: "#FAFAFB",
          fontSize: 13,

          fontWeight: 500,
          letterSpacing: 0.1,
          wordWrap: "break-word",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill="none"
          className="me-3"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 18.9656L13.3892 18.6653C13.8265 18.328 14.4544 18.409 14.7918 18.8463C15.1291 19.2836 15.0481 19.9115 14.6108 20.2489L12.6277 21.7787C12.5675 21.8274 12.5025 21.8682 12.4342 21.901C12.3808 21.928 12.309 21.9545 12.234 21.9725C12.1375 21.9951 12.0448 22.0033 11.953 21.9989C11.812 21.9924 11.6786 21.9567 11.5588 21.8976C11.4931 21.8654 11.4305 21.8257 11.3723 21.7787L9.38919 20.2489C8.9519 19.9115 8.87088 19.2836 9.20823 18.8463C9.54557 18.409 10.1735 18.328 10.6108 18.6653L11 18.9656V8.45711C11 7.90482 11.4477 7.45711 12 7.45711C12.5523 7.45711 13 7.90482 13 8.45711V18.9656ZM12.5 2C15.0609 2 17.2376 3.76105 17.8369 6.17236C20.2436 6.77356 22 8.95407 22 11.518C22 14.3327 19.8828 16.6868 17.1101 16.9939C16.5612 17.0547 16.0669 16.659 16.0061 16.1101C15.9453 15.5612 16.341 15.0669 16.8899 15.0061C18.6514 14.8109 20 13.3114 20 11.518C20 9.71741 18.6408 8.21401 16.8706 8.02783C16.4009 7.97843 16.0298 7.60718 15.9806 7.13748C15.7947 5.36183 14.2947 4 12.5 4C10.7037 4 9.20292 5.36415 9.01891 7.14171C8.96154 7.69596 8.46222 8.0964 7.90874 8.03205C7.77394 8.01638 7.63757 8.00847 7.5 8.00847C5.56748 8.00847 4 9.57926 4 11.518C4 13.3114 5.34862 14.8109 7.11011 15.0061C7.65903 15.0669 8.05473 15.5612 7.99392 16.1101C7.93311 16.659 7.43882 17.0547 6.88989 16.9939C4.11715 16.6868 2 14.3327 2 11.518C2 8.57497 4.30383 6.17018 7.20435 6.01629C7.85706 3.68561 9.99449 2 12.5 2Z"
            fill="white"
          />
        </svg>
        Export
      </div>
    </div>
  );

  return ExportBTN;
};

export default ExportFile;
