import React from "react";
import { Wrapper } from "./style";

const LoadingRoot = ({ title = "Checking credentials, please wait" }) => {
  return (
    <Wrapper className="loader">
      <>
        <label>{title}</label>
        <div className="loading" />
      </>
    </Wrapper>
  );
};

export default LoadingRoot;
