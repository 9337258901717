import { Popconfirm, Table, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../../apis";
import Pagination from "../../../../components/pagination";
import { DeleteIcon, EditIcon, RenderTable } from "../../../../config";
import AddCaralog from "./lib/add";
import { Wrapper } from "./style";

const CantentCatelog = () => {
  const history = useHistory();
  const location = useLocation();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState({});
  const [visible, setVisible] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: "title",
    sort_by: "asc",
  });

  const getList = async (data) => {
    try {
      const response = await MakeApiCall(
        `content-catalog?page=${data?.page || page || 1}&per-page=${
          data?.pageSize || pageSize
        }&isAll=0&field_name=${data?.field_name || ""}&sort_by=${
          data?.sort_by || ""
        }`,
        "get",
        null,
        true
      );

      if (response?.status) {
        setList(response?.data?.records || []);
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setLoading(false);
      } else {
        setTotalPage(0);
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setLoading(true);
    getList({ ...sortFilters });
    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",
      align: "center",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <span>
          <a onClick={() => handleEdit(record)} className=" me-2">
            {EditIcon}
          </a>
          <Popconfirm
            title="Delete the catalog"
            description="Are you sure to delete this catalog?"
            okText="Yes"
            onConfirm={() => handleDelete(record.id)}
            placement="left"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      filterIndex: "type",
      align: "center",
      render: (e) => {
        return (
          <span color="cyan">
            {e === 1 ? (
              <span>
                <svg
                  style={{ width: "15px" }}
                  aria-hidden="true"
                  viewBox="0 0 512 512"
                  className=" fas fa-info-circle"
                >
                  <path
                    fill="#6d6d6d"
                    d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                  />
                </svg>
              </span>
            ) : (
              <span>
                <svg
                  width={22}
                  height={22}
                  fill="#3c71b8"
                  className="cursor-pointer"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 7c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-3.333L22 17V7l-4 3.333V7Z" />
                </svg>
              </span>
            )}
          </span>
        );
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filterIndex: "title",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Info Heading",
      dataIndex: "info_heading",
      key: "info_heading",
      filterIndex: "info_heading",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Info",
      dataIndex: "info_text",
      filterIndex: "info_text",
      key: "info_text",
      align: "center",
      render: (e) => {
        return (
          <Tooltip title={e} placement="bottom">
            <span>
              <svg
                style={{ width: "15px", cursor: "pointer" }}
                aria-hidden="true"
                viewBox="0 0 512 512"
                className=" fas fa-info-circle"
              >
                <path
                  fill="#6d6d6d"
                  d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"
                />
              </svg>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: "Font Style",
      dataIndex: "font_style",
      filterIndex: "font_style",
      key: "font_style",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Font Size",
      dataIndex: "font_size",
      key: "font_size",
      filterIndex: "font_size",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Font Weight",
      dataIndex: "font_weight",
      key: "font_weight",
      filterIndex: "font_weight",
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Video URL",
      dataIndex: "video_url",
      key: "video_url",
      filterIndex: "video_url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text ? "Video" : "-"}
        </a>
      ),
    },
    {
      title: "Showcase URL",
      dataIndex: "showcase_url",
      key: "showcase_url",
      filterIndex: "showcase_url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text ? "Showcase" : "-"}
        </a>
      ),
    },
    {
      title: "Created (ET)",
      dataIndex: "created_at",
      key: "created_at",
      filterIndex: "created_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY");
      },
    },
    {
      title: "Last Updated (ET)",
      dataIndex: "updated_at",
      key: "updated_at",
      filterIndex: "updated_at",
      render: (e) => {
        return moment(new Date(parseInt(e) * 1000))
          .tz("America/New_York")
          .format("MM/DD/YYYY");
      },
    },
    // {
    //   title: "Created by",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Modified By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
  ];
  // Function to handle the Add button
  const handleAdd = () => {
    // setVisible(true);

    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=add`
    );
  };
  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };
  const PropsFilter = (type) => ({
    defaultSortOrder:
      type === sortFilters?.field_name
        ? DefaultSortType?.[sortFilters?.sort_by]
        : [],
    sorter: () => {},
  });
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };
  // Functions to handle edit and delete actions
  const handleEdit = (row) => {
    // setSelectedRow(row);
    // handleAdd();

    history.push(
      `${location?.pathname}${location?.search}${
        location?.search ? "&" : "?"
      }viewType=edit&id=${row?.id}`
    );
    // Implement your edit logic here
  };

  const deleteRow = async (id) => {
    const response = await MakeApiCall(
      `content-catalog/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      setList((pre) => pre.filter((d) => d?.id !== id));
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const handleDelete = (id) => {
    message.loading("Loading...", 0);

    // Implement your delete logic here
    deleteRow(id);
  };

  const pageType = new URLSearchParams(location.search)?.get("viewType");
  const selectedID = new URLSearchParams(location.search)?.get("id");

  const updateUrl = (url) => {
    const urlObj = new URL("http://example.com" + url);
    urlObj.searchParams.delete("viewType");
    urlObj.searchParams.delete("id");
    return urlObj.pathname + urlObj.search;
  };

  const updatedUrl = updateUrl(`${location?.pathname}${location?.search}`);

  if (pageType) {
    return (
      <AddCaralog
        pageType={pageType}
        id={selectedID}
        setList={() => setList({ ...sortFilters })}
        // selectedRow={selectedRow}
        submit={() => {
          setLoading(true);
          setPage(1);
          getList({
            page: 1,
          });
        }}
        setVisible={() => {
          history.push(updatedUrl);
        }}
      />
    );
  }

  return (
    <Wrapper id="kt_content_container" className="custom-ui ">
      <div className="row g-2">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch">
            <div className="card-header">
              <div className="card-title">Content Catalog</div>
              <div className="card-toolbar">
                <a onClick={handleAdd} className="add-btn ">
                  <svg
                    width={16}
                    height={16}
                    className="me-2"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
                  </svg>
                  Add
                </a>
              </div>
            </div>
            <div className="card-body pt-0 pb-0">
              <Table
                dataSource={list}
                fixed={true}
                sticky={{
                  offsetHeader: "0px",
                }}
                onChange={handleChange}
                columns={columns?.map((d) => ({
                  ...d,
                  ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
                  render: (props, row, index) =>
                    RenderTable(props, row, index, d),
                }))}
                loading={loading}
                pagination={false}
                rowClassName={(record, index) => {
                  return index % 2 === 0 ? "even-row" : "odd-row";
                }}
                bordered
                size="small"
                scroll={{ x: "max-content" }}
              />
            </div>
            <div className="card-footer pt-0 pb-5">
              <Pagination
                loading={loading}
                pageSize={pageSize}
                page={page}
                totalPage={totalPage}
                onPerPage={(e) => {
                  setLoading(true);
                  setPageSize(e);
                  setPage(1);
                  getList({
                    page: 1,
                    pageSize: e,
                  });
                }}
                onPageNo={(e) => {
                  setLoading(true);
                  setPage(e);
                  getList({
                    page: e,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CantentCatelog;
