import React, { useEffect, useState } from "react";
import { Table, Button, message, Popconfirm } from "antd";
import { Wrapper } from "./style";
import { DeleteIcon, EditIcon, RenderTable } from "../../../config";
import AddData from "./lib";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";

export default function ParameterGroups() {
  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(1);

  const [sortFilters, setSortFilters] = useState({
    field_name: null,
    sort_by: null,
  });

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [addModal, setAddModal] = useState(false);
  const [modalType, setModalType] = useState("Add");

  const [selectedRow, setSelectedRow] = useState({});

  const sortType = {
    descend: "desc",
    ascend: "asc",
  };
  const DefaultSortType = {
    desc: "descend",
    asc: "ascend",
  };

  const deleteAction = async (id) => {
    const response = await MakeApiCall(
      `parameter-group/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setLoading(true);
      setPage(1);
      getList({ page: 1 });
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const PropsFilter = (type) => {
    return {
      defaultSortOrder:
        type === sortFilters?.field_name
          ? DefaultSortType?.[sortFilters?.sort_by]
          : [],
      sorter: () => {},
    };
  };
  const handleChange = (_, __, sorter) => {
    const obj = {
      field_name: sortType?.[sorter?.order] ? sorter?.column?.filterIndex : "",
      sort_by: sortType?.[sorter?.order] || "",
    };
    setSortFilters(obj);
    getList({ ...obj });
  };

  const getList = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `parameter-group?page=${data?.page || page || 1}&per-page=${
        data?.pageSize || pageSize
      }&field_name=${data?.field_name || ""}&sort_by=${data?.sort_by || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records);
      setLoading(false);
    } else {
      setTotalPage(0);
      setLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getList({
      ...sortFilters,
    });
    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Parameter Groups Name",
      dataIndex: "group_name",
      filterIndex: "group_name",
      key: "group_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterIndex: "status",
      render: (e) => {
        return e == "1" ? "Active" : "Inactive";
      },
    },
    {
      title: "Action",

      render: (_, record) => (
        <div className="d-flex">
          <a
            onClick={() => {
              setAddModal(true);
              setModalType("Update");
              setSelectedRow(_);
            }}
            className=" me-2"
          >
            {EditIcon}
          </a>

          <Popconfirm
            title="Delete this Parameter Groups"
            description={`Are you sure to delete this ${_?.group_name}?`}
            onConfirm={() => {
              message.destroy();
              message.loading("Loading...", 0);
              deleteAction(_?.id);
            }}
            placement="left"
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" className=" me-2">
              {DeleteIcon}
            </a>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <Wrapper
      className="custom-ui px-5 mt-5"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div className="card">
        <div className="card-header">
          <div className="card-title">Parameter Groups</div>
          <div className="card-toolbar">
            <a
              onClick={() => {
                setAddModal(true);
                setModalType("Add");
              }}
              className="add-btn "
            >
              <svg
                width={16}
                height={16}
                className="me-2"
                fill="#ffffff"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15 2.016H9v6.987H2v6h7v6.987h6v-6.987h7v-6h-7V2.016Z" />
              </svg>
              Add Parameter Groups
            </a>
          </div>
        </div>
        <div className="card-body pt-0">
          <Table
            onChange={handleChange}
            fixed={true}
            sticky={{
              offsetHeader: "0px",
            }}
            pagination={false}
            rowClassName={(record, index) => {
              return index % 2 === 0 ? "even-row" : "odd-row";
            }}
            bordered
            size="small"
            dataSource={list}
            columns={columns?.map((d) => ({
              ...d,
              ...(d?.filterIndex ? PropsFilter(d?.filterIndex) : ""),
              render: (props, row, index) => RenderTable(props, row, index, d),
            }))}
          />
        </div>
        <div className="card-footer pt-0 pb-5">
          <Pagination
            loading={loading}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={(e) => {
              setLoading(true);
              setPageSize(e);
              setPage(1);
              getList({
                page: 1,
                pageSize: e,
                ...sortFilters,
              });
            }}
            onPageNo={(e) => {
              setLoading(true);
              setPage(e);
              getList({
                page: e,
                ...sortFilters,
              });
            }}
          />
        </div>
      </div>
      {addModal && (
        <AddData
          visible={addModal}
          selectedRow={selectedRow}
          modalType={modalType}
          onClose={() => {
            setSelectedRow({});
            setAddModal(false);
          }}
          callAPI={() => {
            setPage(1);
            getList({ page: 1 });
          }}
        />
      )}
    </Wrapper>
  );
}
