import styled from "styled-components";

export const Wrapper = styled.div`
  table {
    thead {
      tr {
        th {
          background: #f2f2f2;
          padding: 5px;
        }
      }
    }
    tbody {
      tr {
        td {
          color: #000;
          font-size: 14px;

          font-weight: 400;
        }
      }
    }
  }
  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  .table-fixed th.fixed-column,
  tr > td.fixed-column {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #f2f2f2;
    padding-right: 10px;
  }
  .odd-row-new {
    background: #ebebeb;
  }
  .even-row-new {
    background: #fff;
  }
  .table-container {
    position: relative;
  }
  table {
    width: auto;
  }
  th,
  td {
    padding: 5px 15px;

    white-space: nowrap;
  }
  ._,
  .__,
  .___,
  .____ {
    color: #00000000;
  }
  .box-content {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px;
  }
  .performance_part {
    background: #fff;
    padding: 1px 20px;
    margin-top: 15px;
    border-radius: 7px;
    .card {
      border: 1px solid #80808033;
    }
  }
`;
