import { Button, Form, Input, Modal, Select, message } from "antd";
import React, { useState } from "react";
import { MakeApiCall } from "../../../../../apis";
import { useEffect } from "react";
import { configModal } from "../../../../../config";

const AddData = ({ visible, onClose, modalType, callAPI, selectedRow }) => {
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  const [metricGroupList, setMetricGroupList] = useState([]);
  const [metricGroupLoading, setMetricGroupLoading] = useState(true);

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      form.setFieldsValue({
        metric_name: selectedRow?.metric_name,
        metric_group_id: selectedRow?.metric_group_id,
        metric_type: selectedRow?.metric_type,
        metric_unit: selectedRow?.metric_unit,
        description: selectedRow?.description,
      });
    }
  }, [selectedRow]);

  const UpdateAction = async (data) => {
    const response = await MakeApiCall(
      `metric/${selectedRow?.id}`,
      "put",
      data,
      true
    );

    if (response?.status) {
      message.destroy();

      modal
        .success(configModal("Update Metric", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      message.destroy();
      await modal.warning(configModal("Update Metric", response?.message));
    }
  };
  const AddUpdate = async (data) => {
    const response = await MakeApiCall(`metric`, "post", data, true);

    if (response?.status) {
      message.destroy();

      modal
        .success(configModal("Create Metric", response?.message))
        .then(() => {
          callAPI();
          onClose();
        });
    } else {
      message.destroy();
      modal.warning(configModal("Create Metric", response?.message));
    }
  };

  const getGroupList = async () => {
    setMetricGroupLoading(true);
    const response = await MakeApiCall(
      `metric-group?isAll=1`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setMetricGroupList(response?.data?.records || []);
      setMetricGroupLoading(false);
    } else {
      setMetricGroupList([]);
      setMetricGroupLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    getGroupList();
    return () => {};
  }, []);
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        message.destroy();
        message.loading("Loading...", 0);

        if (modalType === "Add") {
          AddUpdate(values);
        } else {
          UpdateAction(values);
        }
      })
      .catch((error) => {});
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      title={`${modalType} Metric`}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Metric Group"
          name="metric_group_id"
          rules={[{ required: true, message: "Please Enter Metric Group" }]}
        >
          <Select
            placeholder="Enter Metric Group"
            loading={metricGroupLoading}
            style={{ width: "100%" }}
            options={metricGroupList?.map((d) => ({
              label: d?.group_name,
              value: d?.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          label="Metric Name"
          name="metric_name"
          rules={[{ required: true, message: "Please Enter Metric Name" }]}
        >
          <Input placeholder="Enter Metric Name" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input placeholder="Enter Description" />
        </Form.Item>
        <Form.Item
          label="Format"
          name="metric_unit"
          rules={[{ required: true, message: "Please Select Format" }]}
        >
          <Select placeholder="Select Type" style={{ width: "100%" }}>
            <Select.Option value="$">$</Select.Option>
            <Select.Option value="%">%</Select.Option>
            <Select.Option value="int">int</Select.Option>
            <Select.Option value="2">2</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Type"
          name="metric_type"
          rules={[{ required: true, message: "Please Select Type" }]}
        >
          <Select placeholder="Select Type" style={{ width: "100%" }}>
            <Select.Option value="Company">Company</Select.Option>
            <Select.Option value="Product">Product</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      {contextHolder}
    </Modal>
  );
};

export default AddData;
